import {firebaseApp, auth} from './init';
import { fbError } from './error-messages';
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    signOut, 
    sendEmailVerification,
    updateEmail,
    updatePassword,
    deleteUser,
    sendPasswordResetEmail,
} from 'firebase/auth';
import {
    getFirestore,
    collection,
    doc,
    setDoc,
    getDoc
} from 'firebase/firestore';
import isValidEmail from 'is-valid-email';

const db = getFirestore(firebaseApp);
const usersCol = collection(db, 'users');

export const createUser = (email, password) => {
    
    return new Promise((resolve, reject) => {
        
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            
            //create user in users collection
            setDoc(doc(db, 'users', userCredential.user.uid), {})
            .then(() => {
                sendEmailVerification(userCredential.user);
                resolve(userCredential.user);
            })
            
        })
        .catch((error) => {
            resolve({error: fbError(error.code)});  
        })
    })
    
};

export const sendUserEmailVerification = () => {
    
    sendEmailVerification(global._currentUser);
    
};

export const getUser = () => {
    
    return new Promise((resolve, reject) => {
        
        auth.onAuthStateChanged(user => {
            global._currentUser = user;
            resolve(user)
        }, err => {
            global._currentUser = null;
            reject(null)
        })
        
    })
    
};

export const signInUser = (email, password, f7router) => {
    
    return new Promise((resolve, reject) => {
        
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            resolve(userCredential.user);
        })
        .catch((error) => {
            resolve({error: fbError(error.code)});  
        }); 
            
    })
    
};

export const updateUserEmail = (newEmail) => {
    
    return new Promise((resolve, reject) => {
        
        if(!isValidEmail(newEmail))
            resolve({error: 'Email is not valid!'}); 
        
        updateEmail(auth.currentUser, newEmail)
        .then(() => {
            resolve({message: "Email Updated!"});
        })
        .catch((error) => {
            resolve({error: fbError(error.code)}); 
        }); 
            
    })
    
};

export const updateUserPassword = (newPassword) => {
    
    return new Promise((resolve, reject) => {
                        
        updatePassword(auth.currentUser, newPassword)
        .then(() => {
            resolve({message: "Password Updated!"});
        })
        .catch((error) => {
            resolve({error: fbError(error.code)}); 
        }); 
            
    })
    
};

export const updateUserMeta = (data) => {
    
    return new Promise((resolve, reject) => {
        
        setDoc(doc(db, usersCol.path, auth.currentUser.uid), data, {merge: true})                
        .then(() => {
            resolve({message: "Updated"});
        })
        .catch((error) => {
            resolve({error: fbError(error.code)}); 
        }); 
            
    })
     
};

export const getUserMeta = async () => {
    
    return new Promise((resolve, reject) => {
        
        getDoc(doc(db, usersCol.path, auth.currentUser.uid))              
        .then((docSnap) => {
            resolve(docSnap.data());
        })
        .catch((error) => {
            resolve({error: fbError(error.code)}); 
        }); 
            
    })
    
     
};

export const deleteUserAccount = async () => {
    
    return new Promise((resolve, reject) => {
        
        deleteUser(auth.currentUser)          
        .then(() => {
            resolve(signOut(auth));
        })
        .catch((error) => {
            resolve({error: fbError(error.code)}); 
        }); 
            
    })
     
};

export const sendUserPasswordReset = async (email='') => {
    
    return new Promise((resolve, reject) => {
        
        sendPasswordResetEmail(auth, email)          
        .then(() => {
            resolve({message: "Password reset email sent!"});
        })
        .catch((error) => {
            resolve({error: fbError(error.code)}); 
        }); 
            
    })
     
};

export const logout = () => {
    
    return signOut(auth);
      
};