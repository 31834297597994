import React from 'react';

const Slogan = () =>  {
    
    return (
        <div id="slogan">
            <svg viewBox="0 0 989 78" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path d="M55.3,26.8 C54.3,32.6 51.4666667,37.4166667 46.8,41.25 C42.1333333,45.0833333 35.7666667,47 27.7,47 L14.3,47 L9.1,76.1 L-1.13686838e-13,76.1 L12.4,6.4 L34.9,6.4 C41.8333333,6.4 47.0333333,7.88333333 50.5,10.85 C53.9666667,13.8166667 55.7,17.7666667 55.7,22.7 C55.7,23.5666667 55.5666667,24.9333333 55.3,26.8 Z M29,39.8 C34,39.8 37.9,38.65 40.7,36.35 C43.5,34.05 45.2666667,30.8666667 46,26.8 C46.2,25.6 46.3,24.5 46.3,23.5 C46.3,16.9 42.0666667,13.6 33.6,13.6 L20.2,13.6 L15.6,39.8 L29,39.8 Z" id="Shape"></path>
                    <polygon id="Path" points="88.1 2.1 75 76.1 65.9 76.1 79 2.1"></polygon>
                    <path d="M99.9,48.5 C100.9,42.9 102.916667,37.9833333 105.95,33.75 C108.983333,29.5166667 112.683333,26.2333333 117.05,23.9 C121.416667,21.5666667 126.066667,20.4 131,20.4 C135.8,20.4 139.766667,21.4333333 142.9,23.5 C146.033333,25.5666667 148.2,28.1666667 149.4,31.3 L151.2,21.3 L160.4,21.3 L150.7,76.1 L141.5,76.1 L143.4,65.9 C141,69.1 137.833333,71.75 133.9,73.85 C129.966667,75.95 125.633333,77 120.9,77 C116.633333,77 112.866667,76.0833333 109.6,74.25 C106.333333,72.4166667 103.8,69.8 102,66.4 C100.2,63 99.3,59.0666667 99.3,54.6 C99.3,52.6 99.5,50.5666667 99.9,48.5 Z M146.4,48.6 C146.666667,47.2 146.8,45.7 146.8,44.1 C146.8,39.2333333 145.35,35.3833333 142.45,32.55 C139.55,29.7166667 135.866667,28.3 131.4,28.3 C128,28.3 124.75,29.1 121.65,30.7 C118.55,32.3 115.9,34.6333333 113.7,37.7 C111.5,40.7666667 110.033333,44.3666667 109.3,48.5 C109.033333,49.9 108.9,51.4333333 108.9,53.1 C108.9,58.0333333 110.333333,61.9166667 113.2,64.75 C116.066667,67.5833333 119.733333,69 124.2,69 C127.6,69 130.85,68.1833333 133.95,66.55 C137.05,64.9166667 139.716667,62.55 141.95,59.45 C144.183333,56.35 145.666667,52.7333333 146.4,48.6 Z" id="Shape"></path>
                    <path d="M210.7,20.3 C216.366667,20.3 220.9,21.8166667 224.3,24.85 C227.7,27.8833333 229.4,32.3 229.4,38.1 C229.4,40.2333333 229.233333,42.1333333 228.9,43.8 L223.2,76.1 L214.2,76.1 L219.7,45.1 C219.966667,43.7 220.1,42.2333333 220.1,40.7 C220.1,36.6333333 218.983333,33.5333333 216.75,31.4 C214.516667,29.2666667 211.4,28.2 207.4,28.2 C202.6,28.2 198.516667,29.7 195.15,32.7 C191.783333,35.7 189.6,40.0666667 188.6,45.8 L188.6,45.6 L183.2,76.1 L174.1,76.1 L183.8,21.3 L192.9,21.3 L191.5,29.2 C193.766667,26.3333333 196.6,24.1333333 200,22.6 C203.4,21.0666667 206.966667,20.3 210.7,20.3 Z" id="Path"></path>
                    <path d="M248.9,76.7 C247.366667,76.7 246.116667,76.2333333 245.15,75.3 C244.183333,74.3666667 243.7,73.1333333 243.7,71.6 C243.7,69.6 244.433333,67.8833333 245.9,66.45 C247.366667,65.0166667 249.1,64.3 251.1,64.3 C252.633333,64.3 253.866667,64.7666667 254.8,65.7 C255.733333,66.6333333 256.2,67.8666667 256.2,69.4 C256.2,71.4 255.466667,73.1166667 254,74.55 C252.533333,75.9833333 250.833333,76.7 248.9,76.7 Z" id="Path"></path>
                    <polygon id="Path" points="368.1 6.4 366.8 13.5 347.8 13.5 336.8 76.1 327.7 76.1 338.7 13.5 319.7 13.5 321 6.4"></polygon>
                    <path d="M392,31.7 C394.2,28.1 396.966667,25.3 400.3,23.3 C403.633333,21.3 407.433333,20.3 411.7,20.3 L410,29.7 L407.6,29.7 C402.866667,29.7 398.916667,30.9666667 395.75,33.5 C392.583333,36.0333333 390.466667,40.3 389.4,46.3 L384.1,76.1 L375,76.1 L384.7,21.3 L393.8,21.3 L392,31.7 Z" id="Path"></path>
                    <path d="M421.7,48.5 C422.7,42.9 424.716667,37.9833333 427.75,33.75 C430.783333,29.5166667 434.483333,26.2333333 438.85,23.9 C443.216667,21.5666667 447.866667,20.4 452.8,20.4 C457.6,20.4 461.566667,21.4333333 464.7,23.5 C467.833333,25.5666667 470,28.1666667 471.2,31.3 L473,21.3 L482.2,21.3 L472.5,76.1 L463.3,76.1 L465.2,65.9 C462.8,69.1 459.633333,71.75 455.7,73.85 C451.766667,75.95 447.433333,77 442.7,77 C438.433333,77 434.666667,76.0833333 431.4,74.25 C428.133333,72.4166667 425.6,69.8 423.8,66.4 C422,63 421.1,59.0666667 421.1,54.6 C421.1,52.6 421.3,50.5666667 421.7,48.5 Z M468.2,48.6 C468.466667,47.2 468.6,45.7 468.6,44.1 C468.6,39.2333333 467.15,35.3833333 464.25,32.55 C461.35,29.7166667 457.666667,28.3 453.2,28.3 C449.8,28.3 446.55,29.1 443.45,30.7 C440.35,32.3 437.7,34.6333333 435.5,37.7 C433.3,40.7666667 431.833333,44.3666667 431.1,48.5 C430.833333,49.9 430.7,51.4333333 430.7,53.1 C430.7,58.0333333 432.133333,61.9166667 435,64.75 C437.866667,67.5833333 441.533333,69 446,69 C449.4,69 452.65,68.1833333 455.75,66.55 C458.85,64.9166667 461.516667,62.55 463.75,59.45 C465.983333,56.35 467.466667,52.7333333 468.2,48.6 Z" id="Shape"></path>
                    <path d="M511.8,12.4 C510.333333,12.4 509.116667,11.9166667 508.15,10.95 C507.183333,9.98333333 506.7,8.76666667 506.7,7.3 C506.7,5.3 507.433333,3.58333333 508.9,2.15 C510.366667,0.716666667 512.066667,0 514,0 C515.533333,0 516.766667,0.466666667 517.7,1.4 C518.633333,2.33333333 519.1,3.56666667 519.1,5.1 C519.1,7.1 518.366667,8.81666667 516.9,10.25 C515.433333,11.6833333 513.733333,12.4 511.8,12.4 Z M514.7,21.3 L505,76.1 L495.9,76.1 L505.6,21.3 L514.7,21.3 Z" id="Shape"></path>
                    <path d="M565.1,20.3 C570.766667,20.3 575.3,21.8166667 578.7,24.85 C582.1,27.8833333 583.8,32.3 583.8,38.1 C583.8,40.2333333 583.633333,42.1333333 583.3,43.8 L577.6,76.1 L568.6,76.1 L574.1,45.1 C574.366667,43.7 574.5,42.2333333 574.5,40.7 C574.5,36.6333333 573.383333,33.5333333 571.15,31.4 C568.916667,29.2666667 565.8,28.2 561.8,28.2 C557,28.2 552.916667,29.7 549.55,32.7 C546.183333,35.7 544,40.0666667 543,45.8 L543,45.6 L537.6,76.1 L528.5,76.1 L538.2,21.3 L547.3,21.3 L545.9,29.2 C548.166667,26.3333333 551,24.1333333 554.4,22.6 C557.8,21.0666667 561.366667,20.3 565.1,20.3 Z" id="Path"></path>
                    <path d="M603.3,76.7 C601.766667,76.7 600.516667,76.2333333 599.55,75.3 C598.583333,74.3666667 598.1,73.1333333 598.1,71.6 C598.1,69.6 598.833333,67.8833333 600.3,66.45 C601.766667,65.0166667 603.5,64.3 605.5,64.3 C607.033333,64.3 608.266667,64.7666667 609.2,65.7 C610.133333,66.6333333 610.6,67.8666667 610.6,69.4 C610.6,71.4 609.866667,73.1166667 608.4,74.55 C606.933333,75.9833333 605.233333,76.7 603.3,76.7 Z" id="Path"></path>
                    <polygon id="Path" points="722.5 6.4 721.2 13.5 702.2 13.5 691.2 76.1 682.1 76.1 693.1 13.5 674.1 13.5 675.4 6.4"></polygon>
                    <path d="M746.4,31.7 C748.6,28.1 751.366667,25.3 754.7,23.3 C758.033333,21.3 761.833333,20.3 766.1,20.3 L764.4,29.7 L762,29.7 C757.266667,29.7 753.316667,30.9666667 750.15,33.5 C746.983333,36.0333333 744.866667,40.3 743.8,46.3 L738.5,76.1 L729.4,76.1 L739.1,21.3 L748.2,21.3 L746.4,31.7 Z" id="Path"></path>
                    <path d="M776.1,48.5 C777.1,42.9 779.116667,37.9833333 782.15,33.75 C785.183333,29.5166667 788.883333,26.2333333 793.25,23.9 C797.616667,21.5666667 802.266667,20.4 807.2,20.4 C812,20.4 815.966667,21.4333333 819.1,23.5 C822.233333,25.5666667 824.4,28.1666667 825.6,31.3 L827.4,21.3 L836.6,21.3 L826.9,76.1 L817.7,76.1 L819.6,65.9 C817.2,69.1 814.033333,71.75 810.1,73.85 C806.166667,75.95 801.833333,77 797.1,77 C792.833333,77 789.066667,76.0833333 785.8,74.25 C782.533333,72.4166667 780,69.8 778.2,66.4 C776.4,63 775.5,59.0666667 775.5,54.6 C775.5,52.6 775.7,50.5666667 776.1,48.5 Z M822.6,48.6 C822.866667,47.2 823,45.7 823,44.1 C823,39.2333333 821.55,35.3833333 818.65,32.55 C815.75,29.7166667 812.066667,28.3 807.6,28.3 C804.2,28.3 800.95,29.1 797.85,30.7 C794.75,32.3 792.1,34.6333333 789.9,37.7 C787.7,40.7666667 786.233333,44.3666667 785.5,48.5 C785.233333,49.9 785.1,51.4333333 785.1,53.1 C785.1,58.0333333 786.533333,61.9166667 789.4,64.75 C792.266667,67.5833333 795.933333,69 800.4,69 C803.8,69 807.05,68.1833333 810.15,66.55 C813.25,64.9166667 815.916667,62.55 818.15,59.45 C820.383333,56.35 821.866667,52.7333333 822.6,48.6 Z" id="Shape"></path>
                    <path d="M851.7,48.6 C852.7,42.9333333 854.716667,37.9833333 857.75,33.75 C860.783333,29.5166667 864.533333,26.2333333 869,23.9 C873.466667,21.5666667 878.3,20.4 883.5,20.4 C890.233333,20.4 895.483333,22.0333333 899.25,25.3 C903.016667,28.5666667 905.1,33.1 905.5,38.9 L895.7,38.9 C895.366667,35.5666667 894.016667,32.9333333 891.65,31 C889.283333,29.0666667 886.1,28.1 882.1,28.1 C876.9,28.1 872.383333,29.8833333 868.55,33.45 C864.716667,37.0166667 862.233333,42.0666667 861.1,48.6 C860.766667,50.8 860.6,52.6333333 860.6,54.1 C860.6,58.9666667 861.9,62.7166667 864.5,65.35 C867.1,67.9833333 870.566667,69.3 874.9,69.3 C878.9,69.3 882.383333,68.3666667 885.35,66.5 C888.316667,64.6333333 890.6,61.9666667 892.2,58.5 L902,58.5 C899.6,64.1 895.9,68.5833333 890.9,71.95 C885.9,75.3166667 880.1,77 873.5,77 C866.633333,77 861.183333,75.0333333 857.15,71.1 C853.116667,67.1666667 851.1,61.8 851.1,55 C851.1,52.9333333 851.3,50.8 851.7,48.6 Z" id="Path"></path>
                    <polygon id="Path" points="941 48.7 962 76.1 949.6 76.1 932.4 52 928.1 76.1 919 76.1 932.1 2.1 941.2 2.1 933.5 45.5 958.9 21.3 971.5 21.3"></polygon>
                    <path d="M981.3,76.7 C979.766667,76.7 978.516667,76.2333333 977.55,75.3 C976.583333,74.3666667 976.1,73.1333333 976.1,71.6 C976.1,69.6 976.833333,67.8833333 978.3,66.45 C979.766667,65.0166667 981.5,64.3 983.5,64.3 C985.033333,64.3 986.266667,64.7666667 987.2,65.7 C988.133333,66.6333333 988.6,67.8666667 988.6,69.4 C988.6,71.4 987.866667,73.1166667 986.4,74.55 C984.933333,75.9833333 983.233333,76.7 981.3,76.7 Z" id="Path"></path>
                </g>
            </svg>
        </div>
    )
    
}

export default Slogan;