import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { renderTime, secsToTime, intToRoundsReps } from 'misc/helpers.js';
import { get, isEmpty, isNull } from 'lodash';
import { 
    Row, 
    Col, 
    Input,
} from 'framework7-react';
import TimeInput from 'ui/comps/global/TimeInput';

const ScoreInput = (props) => {
    
    const [scoreTime, setScoreTime] = useState(null);
    const [scoreRounds, setScoreRounds] = useState(null);
    const [scoreReps, setScoreReps] = useState(null);
    const [scoreCompleted, setScoreCompleted] = useState(null);
    
    //receive
    useEffect(() => {
        
        if(props.workoutType === 'for_time') {
            
            setScoreTime(parseInt(get(props, 'value', 0)));
            
        }
        else if(props.workoutType === 'amrap') {
            
            const roundsRepsObj = intToRoundsReps(get(props, 'value', 0), get(props, 'repsPerRound', 0));
            setScoreRounds(roundsRepsObj.rounds);
            setScoreReps(roundsRepsObj.reps);
            
        }
        else if(props.workoutType === 'emom') {
            
            setScoreCompleted(get(props, 'value', null));
            
        }
        
    }, [props.value]);
    
    //send
    useEffect(() => {
        
        let scoreVal;
        switch (props.workoutType) {
            case 'for_time':
                scoreVal =  parseInt(scoreTime);
                scoreVal = scoreVal === 0 ? null : scoreVal;
                break;
            case 'amrap':
                const repsPerRound = parseInt(get(props, 'repsPerRound', 0));
                scoreVal = (repsPerRound * parseInt(scoreRounds | 0)) + parseInt(scoreReps | 0);
                scoreVal = scoreVal < 0 ? 0 : scoreVal;
                scoreVal = scoreVal === 0 ? null : scoreVal;
                break;
            case 'emom':
                scoreVal =  scoreCompleted;
                break;
        }
        
        if(props.scoreChange && props.workoutId)
            props.scoreChange(props.workoutId, scoreVal);
            
    }, [scoreTime, scoreRounds, scoreReps, scoreCompleted]);
    
    const focusIn = (evt) => {
                
        if(evt.target.value == 0 || evt.target.value === '00') {

            switch (evt.target.name) {
                case 'rounds':
                    setScoreRounds('');
                    break;
                case 'reps':
                    setScoreReps('');
                    break;
            }
                            
        }
        
    }
    
    const focusOut = (evt) => {
        
        if(evt.target.value == '') {
        
            switch (evt.target.name) {
                case 'rounds':
                    setScoreRounds(evt.target.min);
                    break;
                case 'reps':
                    setScoreReps(evt.target.min);
                    break;
            }
                            
        }
        
    }
    
    if(props.workoutType === 'for_time') {
        
        return (
            <div className="score-input score-for-time">
                <span className="score-label">Enter your time</span>
                <TimeInput 
                    value={scoreTime}
                    onChange={setScoreTime}
                />
            </div>
        )
        
    }
    else if(props.workoutType === 'amrap') {
        
        return (
            <div className="score-input score-amrap">
                <span className="score-label">Enter your completed rounds & reps</span>
                <Row>
                    <Col width="50">
                        <Input
                            type="number"
                            placeholder="Rounds"
                            name="rounds"
                            min={0}
                            step={1}
                            value={isNull(scoreRounds) ? 0 : scoreRounds}
                            onFocus={focusIn}
                            onBlur={focusOut}
                            onChange={(evt) => 
                                setScoreRounds(isEmpty(evt.target.value) ? '': parseInt(evt.target.value))
                            }
                        />
                    </Col>
                    <Col width="50">
                        <Input
                            type="number"
                            placeholder="Reps"
                            name="reps"
                            min={0}
                            step={1}
                            value={isNull(scoreReps) ? 0 : scoreReps}
                            onFocus={focusIn}
                            onBlur={focusOut}
                            onChange={(evt) => 
                                setScoreReps(isEmpty(evt.target.value) ? '': parseInt(evt.target.value))
                            }
                        />
                    </Col>
                </Row>
            </div>
        )
        
    }
    if(props.workoutType === 'emom') {

        return (
            <div className="score-input score-emom">
                <Input
                    type="checkbox"
                    value={1}
                    checked={isNull(scoreCompleted) ? false : scoreCompleted}
                    wrap={true}
                    onChange={(evt) => 
                        setScoreCompleted(evt.target.checked)
                    }
                />
                <span className="score-label">Completed as described</span>
            </div>
        )
        
    }
    else return null;
        
}

ScoreInput.propTypes = {
    workoutId: PropTypes.string,
    workoutType: PropTypes.string,
    scoreChange: PropTypes.func,
    repsPerRound: PropTypes.number
}

ScoreInput.defaultProps = {
    repsPerRound: 0
}

export default ScoreInput;