import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { renderTime, intToRoundsReps } from 'misc/helpers.js';
import { get, isEmpty, isNull } from 'lodash';
import { 
    Row, 
    Col, 
    Input,
} from 'framework7-react';
import TimeInput from 'ui/comps/global/TimeInput';

const RecordInput = (props) => {
    
    const [scoreTime, setScoreTime] = useState(0);
    const [recordValue, setRecordValue] = useState(null);
    
    //receive
    useEffect(() => {
        
        if(props.log === 'time')
            setScoreTime(parseInt(get(props, 'value', 0)));            
        else
            setRecordValue(get(props, 'value', ''));
        
    }, [props]);
    
    //send
    useEffect(() => {
        
        let scoreVal = recordValue;
        
        if(props.log === 'time') {
            scoreVal =  parseInt(scoreTime);
        }
        else if(['reps', 'cals', 'distance'].includes(props.log)) {
            scoreVal = parseInt(scoreVal);
        }

        if(props.onChange && props.movId)
            props.onChange(props.movId, scoreVal);
            
    }, [scoreTime, recordValue]);
    
    const focusIn = (evt) => {
                
        if(evt.target.value == 0 || evt.target.value === '00')
            setRecordValue('');
        
    }
    
    const focusOut = (evt) => {
        
        if(evt.target.value == '')
            setRecordValue(evt.target.min);
        
    }
    
    const onChange = (evt) => {
        
        let value = evt.target.value;
        
        if(props.log === 'reps_weight') {
            //max. 2 decimal digits
            value = value.indexOf(".") >= 0 ? 
                        (value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 3)) 
                    : 
                        value;
        }
            
        
        value = isEmpty(value) ? '' : Number(value);
        setRecordValue(value);
        
    }
    
    if(props.log === 'time') {
        
        return (
            <div className="record-input score-time">
                <span className="record-label">Enter your time</span>
                <TimeInput 
                    value={scoreTime}
                    onChange={setScoreTime}
                />
            </div>
        )
        
    }
    else if(['reps', 'reps_weight', 'cals', 'distance'].includes(props.log)) {
        
        let label,
            placeholder;
                    
        switch (props.log) {
            case 'reps':
                label = 'Enter your reps';
                placeholder = '0';
            break;
            case 'reps_weight':
                label = 'Enter your weight';
                placeholder = get(global, 'config._userMeta.units.weight', 'metric') === 'metric' ? 'kg' : 'lb';
            break;
            case 'cals':
                label = 'Enter your calories';
                placeholder = '0';
            break;
            case 'distance':
                label = 'Enter your distance';
                placeholder = get(global, 'config._userMeta.units.distance', 'meters') === 'meters' ? 'meters' : 'inches';
            break;
        }
        
        return (
            <div className={"record-input record-"+props.log}>
                <span className="record-label">{label}</span>
                <Input
                    type = "number"
                    placeholder = {placeholder}
                    name = {props.log}
                    min = {0}
                    step = {1}
                    value = {isNull(recordValue) ? 0 : recordValue}
                    onFocus = {focusIn}
                    onBlur = {focusOut}
                    onChange = {onChange}
                />
            </div>
        )
        
    }
    else return null;
        
}

RecordInput.propTypes = {
    movId: PropTypes.string,
    log: PropTypes.string,
    onChange: PropTypes.func,
}

RecordInput.defaultProps = {
    log: ''
}

export default RecordInput;