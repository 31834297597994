import React, { useState, useEffect, useRef } from 'react';
import { 
    Panel, 
    Page, 
    Block, 
    BlockTitle, 
    Row, 
    Col, 
    Button,
    BlockHeader,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Input,
    Preloader,
    Popup,
    Icon
} from 'framework7-react';
import { readWorkouts } from 'fb-api/workouts';
import { writeWorkoutResult, getWorkoutResults, deleteWorkoutResult } from 'fb-api/workout-results';
import { writePersonalRecord } from 'fb-api/personal-records';
import { isEmpty, get, now, map, find, forEach, isNull, isUndefined, reverse, sortBy } from 'lodash';
import moment from 'moment';
import { indexToAlpha, secsToTime } from 'misc/helpers.js';
import workoutTypes from 'json/workout-types.json';

import SessionWorkout from 'ui/comps/planner/SessionWorkoutExec';
import ModalActions from 'ui/comps/global/ModalActions';
import ScoreList from 'ui/comps/workout/ScoreList';

const ViewSession = (props) =>  {
    
    const [loading, setLoading] = useState(true);
    const [workouts, setWorkouts] = useState([]);
    const [workoutHistoryData, setWorkoutHistoryData] = useState([]);
    const [workoutHistoryType, setWorkoutHistoryType] = useState('');
    const [newRecordContent, setNewRecordContent] = useState('');
    
    const resultsData = useRef({});
    const completed = useRef(false);
    const newRecordData = useRef(null);
    
    useEffect(() => {
        
        setWorkouts([]);        
        setLoading(true);
        resultsData.current = {};
        
        if(!isEmpty(props.workouts)) {
            
            (async function() {
                
                const workoutIds = map(props.workouts, 'id');
                
                //get workout details in session
                const sessionWorkouts = await readWorkouts({includeIds: workoutIds});
                
                let workouts = [];
                sessionWorkouts.forEach((docRef) => {
                    workouts.push({id: docRef.id, ...docRef.data()});
                });
                
                //sort by correct order from workout IDs
                workouts = map(workouts, (w, i) => {
                    return find(workouts, {id: workoutIds[i]});
                });
                
                //retrieve workout results for session
                const workoutResults = await getWorkoutResults({sessionId: props.id, workoutIds: workoutIds});
                
                workoutResults.forEach((docRef) => {
                    let targetWorkout = find(workouts, {id: docRef.get('workout_id')});
                    targetWorkout.score = docRef.get('score');
                    targetWorkout.note = docRef.get('note');
                    targetWorkout.completed = docRef.get('completed');
                    targetWorkout.result_id = docRef.id;
                });
                
                setWorkouts(workouts);
                setLoading(false);
                
            })();
            
        }
        else
            setLoading(false);
                        
    }, [props.workouts]);
    
    const workoutComplete = (workoutId, completed) => {
                        
        if(resultsData.current[workoutId])
            resultsData.current[workoutId].completed = completed;
        else
            resultsData.current[workoutId] = {score: null, note: '', completed: completed};
                
    }
    
    const saveSession = () => {
        
        let currentWorkouts = {...workouts};
        
        forEach(resultsData.current, async (workoutResult, workoutId) => {
            
            let data = {...workoutResult};
            
            //only save workouts results with a score, note or marked as completed
            if(!isNull(data.score) || !isEmpty(data.note) || data.completed) {
                
                let payload = {};
                
                data.workout_id = workoutId;
                data.session_id = props.id;
                
                //get result id to update current workout result
                let targetWorkout = find(currentWorkouts, {id: workoutId});
                if(targetWorkout && targetWorkout.result_id) {
                    payload.workout_result_id = targetWorkout.result_id;
                }
                
                payload.data = data;
                const docRef = await writeWorkoutResult(payload);
                
            }
            
        })
        
        props.saveExecSession(props.id, completed.current);
        
    }
    
    const scoreChange = (workoutId, score) => {
        
        if(!isUndefined(score)) {
            if(resultsData.current[workoutId])
                resultsData.current[workoutId].score = score;
            else
                resultsData.current[workoutId] = {score: score, note: ''};
        }
                
    }
    
    const noteChange = (workoutId, note) => {
        
        if(resultsData.current[workoutId])
            resultsData.current[workoutId].note = note;
        else
            resultsData.current[workoutId] = {score: null, note: note};
                    
    }
    
    const panelClosed = () => {
        
        saveSession();        
        setWorkouts([]);
        
    }
    
    const renderTypeInfo = () => {
        
        let typeInfo = props.type ? workoutTypes[props.type].name : '';
        
        if(props.type === 'sets') {
            typeInfo = props.configs.number_of_sets + ' ' + typeInfo;
        } 
        
        return (
            <span className="type-info">
                {typeInfo}
            </span>
            
        )
        
    }
    
    const openWorkoutHistory = async (workoutId, workoutType) => {
        
        let _historyData = [];
        
        const workoutResults = await getWorkoutResults({
            workoutId: workoutId, 
            onlyScored: workoutType !== 'custom',
            onlyNoted: workoutType === 'custom',
            limit: 5
        });
                
        const scoreKey = workoutType === 'sets' ? 'total_load' : null;
        workoutResults.forEach((docRef) => {
            
            let score = docRef.get('score');
            score = scoreKey ? score[scoreKey] : score;
            
            
            const momentDate = moment(docRef.get('created_at').toDate());
            
            _historyData.push({
                id: docRef.id,
                date: momentDate.format(global.config.app.display_date_year_format),
                score: score,
                note: docRef.get('note')
            });
            
            _historyData = reverse(sortBy(_historyData, function(dateObj) {
                return new Date(dateObj.date);
            }));
                                            
        });
        
        setWorkoutHistoryType(workoutType);
        setWorkoutHistoryData(_historyData);
        window.f7App.popup.get('#workout-history-popup').open();
        
    }
    
    const onNewRecord = (_newRecordData) => {
        
        let content;
        
        if(_newRecordData.log_parameter === 'reps_weight') {
            
            const weightUnit = get(global, 'config._userMeta.units.weight', 'metric') === 'metric' ? 'kg' : 'lb';
            const repsLabel = _newRecordData.reps == 1 ? 'rep' : 'reps';
            content = `${_newRecordData.reps} ${repsLabel} of ${_newRecordData.weight} ${weightUnit}`;
                
        }
        else if(_newRecordData.log_parameter === 'reps') {
            
            const repsLabel = _newRecordData.reps == 1 ? 'rep' : 'reps';
            content = `${_newRecordData.reps} ${repsLabel}`;
                    
        }
        else if(_newRecordData.log_parameter === 'time') {
            
            const timeObj = secsToTime(_newRecordData.time);
            content = `${timeObj.mins} : ${timeObj.secs}`;
            
        }
        
        if(content) {
            
            setNewRecordContent(content);
            window.f7App.popup.open('#new-record-popup');
            newRecordData.current = _newRecordData;
            
        }
        
    }
    
    const logNewRecord = async () => {
        
        if(newRecordData.current) {
            
            const logParamter = newRecordData.current.log_parameter;
            const data = {
                movement_id: newRecordData.current.id,
            };
            
            if(logParamter === 'reps_weight') {
                data.record = newRecordData.current.weight;
                data.benchmark = newRecordData.current.reps;
            }
            else {
                data.record = logParamter === 'reps' ? newRecordData.current.reps : newRecordData.current.time;
            }
            
            const docRef = await writePersonalRecord({data: data});
            
            if(docRef.id) {
                window.f7App.toast.create(
                    {text: 'New record logged!'}
                ).open();
            }            
            
        }
                
    }
    
    const deleteScore = async (workoutResultId, index) => {
        
        let _historyData = [...workoutHistoryData];       
        
        await deleteWorkoutResult(workoutResultId);
        
        const removedWorkoutResult = _historyData.splice(index, 1);
        setWorkoutHistoryData(_historyData);
         
    }
    
    const renderWorkouts = () => {
                                
        return (        
            map(workouts, (workout, i) => {
                
                return (
                    <SessionWorkout
                        {...workout}
                        key={workout.id}
                        workoutComplete={workoutComplete}
                        scoreChange={scoreChange}
                        noteChange={noteChange}
                        openWorkoutHistory = {openWorkoutHistory}
                        onNewRecord = {onNewRecord}
                    />
                )
                
            })
        )
      
    }
    
    return (
        
        <Panel
            right 
            cover 
            swipe
            swipeOnlyClose
            closeByBackdropClick={false}
            id="planner-view-session"
            onPanelClosed={panelClosed}
        >
            <Page>
                {loading && 
                    <Preloader className="panel-preloader" />
                }
                <Block>
                    <Row>
                        <Col width="80">
                            <BlockTitle large className="no-margin">
                                {props.name}  
                            </BlockTitle>
                            <BlockHeader>
                            {
                                moment(get(props, 'execution_date', ''), global.config.app.date_format)
                                .format(global.config.app.display_date_format)
                            }
                            </BlockHeader>
                        </Col>
                        <ModalActions 
                            id = "panel-actions-view-session" 
                            modalType = "panel"
                        />
                    </Row>
                </Block>
                {!isEmpty(props.note) &&
                    <Block className="session-note">{props.note}</Block>
                }
                <Block className="session-workouts">
                    {renderWorkouts()}
                </Block>
                <Block>
                    {!isEmpty(workouts) &&
                    <Button
                        text="Complete Session"
                        fill
                        onClick={() => {
                            completed.current = true;
                            window.f7App.panel.get('#planner-view-session').close();
                        }}
                    />
                    }
                </Block>
                <Popup 
                    id="workout-history-popup" 
                    onPopupClosed={() => setWorkoutHistoryData([])} 
                    swipeToClose
                >
                    <Page>
                        <Block>
                            {workoutHistoryData.length === 0 &&
                                <div className="notice">No scores logged yet.</div>
                            }
                            {workoutHistoryData.length !== 0 &&
                                <ScoreList 
                                    data = {workoutHistoryData} 
                                    workoutType = {workoutHistoryType} 
                                    id = "workout-history-score-list"
                                    caption = {"Your last 5 scores"}
                                    scoreDelete = {deleteScore}
                                />
                            }                        
                        </Block>
                    </Page>   
                </Popup>
                <Popup
                    id = "new-record-popup"
                    animate = {false}
                >
                    <Page>
                        <Block>
                            <Icon 
                                material = "emoji_events"
                            />
                            <BlockTitle large>Congratulations</BlockTitle>
                            <BlockHeader>You hit a new record</BlockHeader>
                            <div id="new-record-placeholder">{newRecordContent}</div>
                            <Row>
                                <Col>
                                    <Button
                                        text = "Log Record"
                                        fill
                                        small
                                        popupClose
                                        onClick = {logNewRecord}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        text = "Cancel"
                                        fill
                                        small
                                        color = "red"
                                        popupClose
                                    />
                                </Col>
                            </Row>
                            
                        </Block>
                    </Page>
                </Popup>
            </Page>
        </Panel>
        
    )
    
}

export default ViewSession;