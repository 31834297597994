import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types';
import { 
    writePersonalRecord, 
    getPersonalRecords, 
    getBestPersonalRecord, 
    deletePersonalRecord 
} from 'fb-api/personal-records';
import { isEmpty, get, map, sortBy, reverse, size, findIndex } from 'lodash';
import moment from 'moment';
import $ from 'jquery'; 

import { 
    Popup, 
    Block, 
    BlockTitle, 
    Row, 
    Col, 
    Button,
    BlockHeader,
    Input, 
    TextEditor,
    Toolbar,
    Tabs,
    Tab, 
    Link,
    Sheet,
    PageContent,
    MenuItem,
    MenuDropdown,
    MenuDropdownItem
} from 'framework7-react';
import TrackingChart from 'ui/comps/global/TrackingChart';
import RecordInput from './RecordInput';
import RecordList from './RecordList';
import Record from './Record';

let recordDateInstance = null;

function MovementTracking (props) {
    
    const [trackingLoading, setTrackingLoad] = useState(null);
    const [bestRecord, setBestRecord] = useState('');
    const [chartData, setChartData] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [recordVal, setRecord] = useState('');
    const [benchmark, setBenchmark] = useState(1);
    
    const newRecordData = useRef({});
    const sheetOpened = useRef(false);
    
    useEffect(() => {
        
        loadTrackingData();
                
    }, [props, benchmark]);
    
    useEffect(() => {
        
        newRecordData.current.record = recordVal;
        
    }, [recordVal]);
    
    const loadTrackingData = async () => {
        
        if(props.movId && props.logParameter && !trackingLoading) {
            
            setTrackingLoad(true);
                            
            let _historyData = [];
            let _chartData = [];
            
            let queryOpts = {movId: props.movId};
            
            if(props.logParameter === 'reps_weight')
                queryOpts.benchmark = benchmark;
            
            const personalRecords = await getPersonalRecords(queryOpts);
            
            personalRecords.forEach((docRef) => {
                
                let record = docRef.get('record');
                
                const momentDate = moment(docRef.get('created_at').toDate())
                                    
                _chartData.push({
                    id: docRef.id,
                    x: momentDate.format('DD. MMM'),
                    y: record
                });
                
                _chartData = sortBy(_chartData, function(dateObj) {
                  return new Date(dateObj.x);
                });
                
                _historyData.push({
                    id: docRef.id,
                    date: momentDate.format(global.config.app.display_date_year_format),
                    record: record,
                });
                                
                _historyData = reverse(sortBy(_historyData, function(dateObj) {
                    return new Date(dateObj.date);
                }));
                
            });
            
            setHistoryData(_historyData);
            
            if(_historyData.length > 0) {
                
                setChartData(_chartData);
                loadBestRecord();
                
            }
            
            setTrackingLoad(false);
             
        }
        
    }
    
    const loadBestRecord = async () => {
                      
        const bestPersonalRecord = await getBestPersonalRecord({
            movId: props.movId, 
            dir: props.logParameter === 'time' ? 'asc' : 'desc',
            benchmark: props.logParameter === 'reps_weight' ? benchmark : null
        });
        
        if( size(get(bestPersonalRecord, 'docs', [])) )
            setBestRecord(bestPersonalRecord.docs[0].get('record'));
        else
            setBestRecord('');
            
        
    }
    
    const sheetOpen = () => {
        
        sheetOpened.current = true;
        newRecordData.current = {};
        setRecord('');
        
        if(recordDateInstance) {
            recordDateInstance.destroy();
            $('#add-record-date-wrapper').empty();
        }
        
        recordDateInstance = window.f7App.calendar.create({
            containerEl: '#add-record-date-wrapper',
            on: {
                change: (cal, val) => {
                    newRecordData.current.created_at = val[0];
                }
            }
        });
                
    }
    
    const addNewRecord = async () => {
        
        if(!newRecordData.current.created_at) {
            window.f7App.toast.create(
                {text: 'Please select a date!'}
            ).open();
            return;
        }
        
        if(!recordVal) {
            window.f7App.toast.create(
                {text: 'Please enter a value for your record!'}
            ).open();
            return;
        }
        
        let data = {...newRecordData.current};
        data.movement_id = props.movId;
        
        if(props.logParameter === 'reps_weight')
            data.benchmark = benchmark;
                
        const docRef = await writePersonalRecord({data: data});
        
        loadTrackingData();
        
        window.f7App.sheet.get('#add-record-sheet').close();
        
    }
    
    const deleteRecord = async (recordId, index) => {
        
        let _chartData = [...chartData];
        let _historyData = [...historyData];
                
        const removedRecord = _historyData.splice(index, 1);
        setHistoryData(_historyData);
        
        if(removedRecord.length) {
            
            const indexInChartData = findIndex(_chartData, {id: removedRecord[0].id});
        
            if(indexInChartData !== -1) {
                _chartData.splice(indexInChartData, 1);
                setChartData(_chartData);
            }
                                        
        }        
        
        await deletePersonalRecord(recordId);
        loadBestRecord();
         
    }
    
    const hasData = trackingLoading === false && !isEmpty(historyData);
    
    return (
        <React.Fragment>
            {props.logParameter === 'reps_weight' &&
                <React.Fragment>
                    <BlockHeader className="no-padding no-margin">Benchmark</BlockHeader>
 
                    <Toolbar tabbar scrollable>
                        {map([1, 2, 3, 5, 10, 20], (i) => {
                              
                            return (
                                <Link 
                                    key={i} 
                                    text={i+' RM'}
                                    tabLinkActive = {benchmark === i}
                                    data-benchmark={i}
                                    onClick={(evt) => setBenchmark(parseInt(evt.currentTarget.dataset.benchmark))} 
                                />
                            )
                            
                        })} 
                    </Toolbar>
                </React.Fragment>
            }
            {!hasData &&
                <p className="notice notice-info">No records logged yet.</p>
            }
            {hasData && 
                <React.Fragment>
                
                    <Block className="best-record-wrapper">
                        <div>
                            <span className="info">All-time Best Record</span>
                            <Record
                                value={bestRecord}
                                log={props.logParameter}
                            /> 
                        </div>
                    </Block>
                    <Block id="chart-wrapper">
                        <TrackingChart 
                            id = "movement-chart" 
                            data = {chartData} 
                            yType = "int"    
                        />
                    </Block>
                    <RecordList 
                        data = {historyData} 
                        log = {props.logParameter} 
                        id = "workout-popup-record-list"
                        caption = "History" 
                        subCaption = "Shows the last 10 records"
                        recordDelete = {deleteRecord}
                    />
                    
                </React.Fragment>
            }
           
            <Button 
               text="Log New Record" 
               fill
               className="margin-top"
               sheetOpen="#add-record-sheet"
            />
            <Sheet
                id="add-record-sheet"
                bottom
                push
                onSheetOpen={sheetOpen}
                onSheetClose={() => sheetOpened.current = false}
              >
                <PageContent>
                
                    <div id="add-record-date-wrapper"></div>
                    
                    <RecordInput
                        movId = {props.movId}
                        log = {props.logParameter}
                        value = {recordVal}
                        onChange = {(movId, value) => {
                            if(sheetOpened.current)
                                setRecord(value);
                        }}
                    />
                    
                    <Row>
                        <Col>
                            <Button 
                                text = "Save Record"
                                fill
                                small
                                onClick = {addNewRecord}
                            />
                        </Col>
                        <Col>
                            <Button 
                                text = "Cancel"
                                fill
                                small
                                color = "red"
                                sheetClose
                            />
                        </Col>
                    </Row>
                    
                </PageContent>
              </Sheet>   
        </React.Fragment>            
    )
}

MovementTracking.propTypes = {
    movId: PropTypes.string,
    logParameter: PropTypes.string
}

MovementTracking.defaultProps = {
    movId: null,
    logParameter: ''
}

export default MovementTracking;