import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getDaySessions,
    updateDaySessions,
    writeSession, 
    getSessionDetails, 
    deleteSession
} from 'redux-store/slices/sessions';
import $ from 'jquery'; 
import { 
    PageContent,
    Block,
    Row,
    Col,
    BlockTitle,
    Toolbar, 
    Link,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Button,
    Icon,
    MenuItem,
    MenuDropdown,
    MenuDropdownItem
} from 'framework7-react';
import moment from 'moment';
import { times, map, filter, isEmpty, find, each, get } from 'lodash';
import { swapArrayLoc } from 'misc/helpers';

import WriteSession from 'ui/comps/planner/WriteSession.js';
import TimelineSession from 'ui/comps/planner/TimelineSession.js';
import ViewSession from 'ui/comps/planner/ViewSession.js';

const Planner = ({f7router}) => {
            
    const dispatch = useDispatch();
    
    const loadingSession = useSelector((state) => state.sessions.loading);
    const allDaySessions = useSelector((state) => state.sessions.allDaySessions);
    const singleSession = useSelector((state) => state.sessions.single);
    
    const [currentYear, setCurrentYear] = useState(moment().year());
    const [currentMonth, setCurrentMonth] = useState(moment().month());
    const [currentSessionData, setCurrentSessionData] = useState(null);
    const [selectedDay, selectDay] = useState(1); 
    const [sessionMode, setSessionMode] = useState('');
    const [daySessionId, setDaySessionId] = useState(null);
    
    //only run once
    useEffect(() => {
                        
        let $timelineScrollView = $('#timeline-sessions > .row:first');
        
        $(window).resize(() => {
            
            let listHeight = window.innerWidth < 768
                ? $('#main-toolbar').offset().top - $timelineScrollView.offset().top
                : window.innerHeight - $timelineScrollView.offset().top;
            
            $timelineScrollView.height(listHeight - (parseInt($timelineScrollView.css('paddingTop')) * 2));
            
        }).resize();
        
        //connect date selector with timeline sessions to check when day is completed
        $('#timeline-sessions').on('session-completed-toggle', (evt, day) => {
            
            let $daySessions = $('.day-col[data-date="'+day+'"]').find('.session');
            $('#date-selector').find('a[data-date="'+day+'"]').toggleClass(
                'completed',
                $daySessions.length === $daySessions.filter('.completed').length
            )
            
        });
                
    }, []);
    
    useEffect(() => {
                
        f7router.app.dialog.close();
        if(loadingSession) {
            f7router.app.dialog.preloader(loadingSession)
        }
        
    }, [loadingSession]);
    
    //year or month changed
    useEffect(() => {
                
        const today = moment();
        
        focusOnDay( currentMonth == today.month() && currentYear == today.year() ? today.date() : 1);
        selectDay(today.date());
        loadSessions();
        
    }, [currentMonth, currentYear]); //all needs to listen to select date
    
    //edit session in side panel
    useEffect(() => {
                
        if(singleSession) {
            
            let cloneSingle = {...singleSession};
            
            //convert ff timestamp to date and format
            const exec_date = moment(cloneSingle.execution_date.toDate()).format(global.config.app.date_format);
            cloneSingle.execution_date = exec_date;
            
            setCurrentSessionData(cloneSingle);
            
            if(sessionMode === 'edit')
                window.f7App.panel.get('#planner-write-session').open();
            else if(sessionMode === 'execute')
                window.f7App.panel.get('#planner-view-session').open();
            
        }
                                
    }, [singleSession]);
    
    const loadSessions = () => {
        
        const minDate = moment({y: currentYear, M: currentMonth}).toDate();
        const maxDate = moment({y: currentYear, M: currentMonth}).add(1, 'month').toDate();
                
        dispatch( getDaySessions({minDate: minDate, maxDate: maxDate}) );
        
    };
    
    const focusOnDay = (targetDay, scrollDateSel=true) => {
                        
        //date selector
        let scrollOffsetLeft = 0;
        const $targetDateSelect = $('#date-selector .link[data-date="'+targetDay+'"]');
                                            
        if(scrollDateSel) {
            
            if($targetDateSelect.length) {
                scrollOffsetLeft = $targetDateSelect.position().left;
            }
            $('#date-selector > .toolbar-inner').scrollLeft(scrollOffsetLeft);
            
        }                          
        
        //timeline
        let scrollOffsetTop = 0;
        const $targetItem = $('#timeline-sessions > .row').find('.day-col[data-date="'+targetDay+'"]');
        
        if($targetItem.length) {
            scrollOffsetTop = $('#timeline-sessions > .row').scrollTop() + $targetItem.position().top;
        }
                
        $('#timeline-sessions > .row').scrollTop(scrollOffsetTop);
        
    }
    
    const saveSession = (data, sessionId=null) => {
                                
        //convert formatted date to real date object
        if(data.execution_date)   
            data.execution_date = moment(data.execution_date, global.config.app.date_format).toDate();
                    
        dispatch( writeSession({
            data: {...data},
            session_id: sessionId, 
            day_session_id: daySessionId
        }) );
                
    }
    
    const openSessionPanel = (evt) => {
        
        setDaySessionId(evt.currentTarget.dataset.daysessionid);
        setCurrentSessionData({execution_date: evt.currentTarget.dataset.date});
        window.f7App.panel.get('#planner-write-session').open();
    }
    
    const moveSession = (dir, sessionId, daySessionId) => {
                
        let allSessions = [...allDaySessions];
        let daySessions = [...find(allSessions, {id: daySessionId}).sessions];
        const currentIndex = daySessions.indexOf(sessionId);
        
        let newIndex;
        if(dir === 'up')
            newIndex = currentIndex - 1;
        else
            newIndex = currentIndex + 1;
        
        if(newIndex < 0)
            newIndex = 0;
        else if(newIndex > daySessions.length)
            newIndex = daySessions.length;
        
        daySessions = swapArrayLoc(daySessions, currentIndex, newIndex);
                                
        dispatch( updateDaySessions({data: {sessions: daySessions}, day_session_id: daySessionId}) );
        
    }
    
    const editSession = (sessionId, daySessionId) => {
        
        setDaySessionId(daySessionId);
        setSessionMode('edit');        
        dispatch( getSessionDetails({id: sessionId}) );
        
    }
    
    const viewSession = (sessionId) => {
        
        setSessionMode('execute');
        dispatch( getSessionDetails({id: sessionId}) );
        
    }
    
    const saveExecSession = (sessionId, completed) => {
                
        dispatch( writeSession({
            data: {completed: completed}, 
            session_id: sessionId
        }) );       
                
    }
    
    const getSingleDaySessions = (dayDate) => {
        
        return find(
            allDaySessions, 
            {date: dayDate.format(global.config.app.date_format)}
        );
        
    }
    
    const skipMonth = (dir='next') => {
        
        let targetDate;
        if(dir === 'next')
            targetDate = moment({y: currentYear, M: currentMonth}).add(1, 'M');
        else
            targetDate = moment({y: currentYear, M: currentMonth}).subtract(1, 'M');
        
        setCurrentMonth(targetDate.month());
        setCurrentYear(targetDate.year());
        
    }
    
    const renderMain = () => {
        
        const currentMonthDays = moment().set('year', currentYear).set('month', currentMonth).daysInMonth();
        
        return (
            <React.Fragment>
                <Toolbar tabbar scrollable id="date-selector">
                {times(currentMonthDays, (i) => {
                    
                    const dayDate = moment()
                                    .set('year', currentYear)
                                    .set('month', currentMonth)
                                    .set('date', i+1);
                    
                    const daySessions = getSingleDaySessions(dayDate);
                    
                    let cssClasses = '';
                    if( !isEmpty(get(daySessions, 'sessions', [])) )
                        cssClasses += ' has-sessions';
                         
                    if(moment().format(global.config.app.date_format) === dayDate.format(global.config.app.date_format))
                        cssClasses += ' active';
                        
                    if(dayDate.day()%6 === 0)
                        cssClasses += ' is-weekend';
                    
                    return (
                        <Link 
                            data-date={dayDate.format('D')} 
                            onClick={(evt) => focusOnDay(evt.currentTarget.dataset.date, false)}
                            className={cssClasses}
                            key={i}    
                        >
                            <span className="weekday">{dayDate.format('ddd')}</span>
                            <span className="day">{i+1}</span>                    
                        </Link>
                    )
                })}
                </Toolbar>
                
                <Block id="timeline-sessions">
                    <Row>
                    {times(currentMonthDays, (i) => {
                        
                        const dayDate = moment()
                                        .set('year', currentYear)
                                        .set('month', currentMonth)
                                        .set('date', i+1);
                                        
                        const daySessions = getSingleDaySessions(dayDate);
                        const isToday = moment().format(global.config.app.date_format) === dayDate.format(global.config.app.date_format);
                        let sessionIds = get(daySessions, 'sessions', []);
                        
                        let cssClasses = 'day-col';
                        if(isToday)
                            cssClasses += ' active';
                                                                        
                        return (
                            <Col 
                                className={cssClasses} 
                                width="100"  
                                data-date={dayDate.format('D')} 
                                key={i}
                            >
                                <Block className="segment" strong>
                                    <Row>
                                        <Col width="80">
                                            <BlockTitle className="no-margin">
                                                {isToday ? 
                                                    'Today, ' + dayDate.format('MMM D')
                                                    : 
                                                    dayDate.format(global.config.app.display_date_format)
                                                }
                                            </BlockTitle>
                                        </Col>
                                        <Col width="20" className="text-align-right">
                                            {sessionIds.length >= global.config.app.max_sessions_per_day &&
                                                <Icon
                                                    material="dangerous"
                                                    color="yellow"
                                                    size="24"
                                                    tooltip={`Maximum ${global.config.app.max_sessions_per_day} sessions per day are allowed`}
                                                />
                                            }
                                            {sessionIds.length < global.config.app.max_sessions_per_day &&
                                               <Button 
                                                   iconMaterial="add"
                                                   tooltip="Add Session"
                                                   small 
                                                   iconSize="30"
                                                   data-date={dayDate.format(global.config.app.date_format)}
                                                   data-daysessionid={daySessions ? daySessions.id : null} 
                                                   onClick={openSessionPanel}
                                                   className="display-inline-block no-padding"
                                                   disabled={sessionIds.length >= global.config.app.max_sessions_per_day}
                                               /> 
                                            }
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="sessions-wrapper">
                                            {map(sessionIds, (sessionId, i) => {
                                                
                                                return <TimelineSession 
                                                    id = {sessionId}
                                                    daySessionId = {daySessions.id}
                                                    moveSession = {moveSession}
                                                    editSession = {editSession}
                                                    deleteSession = { 
                                                        ( sessionId) => dispatch(deleteSession({
                                                            id: sessionId, day_session_id: daySessions.id
                                                        }) ) 
                                                    }
                                                    viewSession = {viewSession}
                                                    key = {dayDate.format(global.config.app.date_format+'_'+i)}
                                                />
                                                
                                            })}                                        
                                            {isEmpty(sessionIds) &&
                                                <p>No Sessions. Chill or click the + symbol to add a new session.</p>
                                            }
                                        </Col>
                                    </Row>  
                                </Block>            
                            </Col>
                        )
                    })}
                    </Row>
                </Block>
            </React.Fragment>
        )
        
    }

    return (
        <PageContent>
        
            <Block id="month-year-wrapper">
                <Row>
                    <Col>
                        <Button 
                           iconMaterial="arrow_back_ios"
                           tooltip="Previous Month"
                           small 
                           className="display-inline-block"
                           onClick={() => skipMonth('prev')}
                        />
                        <MenuItem text={moment().set('month', currentMonth).format('MMM')} dropdown className="display-inline-block">
                            <MenuDropdown left contentHeight="auto">
                            {map(moment.months(), (month, i) => {
                                
                                const monthN = moment().set('month', month).format('M');
                                
                                return (
                                    <MenuDropdownItem 
                                        key={monthN} 
                                        text={month}
                                        data-month={i}
                                        onClick={(evt) => setCurrentMonth(evt.currentTarget.dataset.month)} 
                                    />
                                )
                                
                            })}                                
                            </MenuDropdown>
                        </MenuItem>
                        <MenuItem text={currentYear} dropdown className="display-inline-block">
                            <MenuDropdown left contentHeight="auto">
                            {times(3, (i) => {
                                
                                const year = moment().add(i, 'years').format('Y');
                                
                                return (
                                    <MenuDropdownItem 
                                        key={year} 
                                        text={year}
                                        data-year={year}
                                        onClick={(evt) => setCurrentYear(evt.currentTarget.dataset.year)} 
                                    />
                                )
                            })}                                
                            </MenuDropdown>
                        </MenuItem>
                        <Button 
                           iconMaterial="arrow_forward_ios"
                           tooltip="Next Month"
                           small 
                           className="display-inline-block"
                           onClick={() => skipMonth('next')}
                        />
                    </Col>
                </Row>
            </Block>
            
            {renderMain()}
            
            <WriteSession
                {...currentSessionData}
                save={saveSession}
            />
            
            <ViewSession
                {...currentSessionData}
                saveExecSession={saveExecSession}
            />
            
        </PageContent>
    )
    
}

export default Planner;