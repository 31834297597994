import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import $ from 'jquery'; 
import { secsToTime, timeToSecs } from 'misc/helpers';

const TimeInput = (props) =>  {
    
    const [mins, setMins] = useState(0);
    const [secs, setSecs] = useState(0);
    
    useEffect(() => {
        
        //cleanup
        return () => {
            
            if(window._timerInterval) {
                clearInterval(window._timerInterval);
                window._timerInterval = null;
            }
            
        };
        
    },[]);
    
    useEffect(() => {
        
        resetTimmer();  
          
    }, [props.value]);
    
    useEffect(() => {
        
        if(props.start) {
            startTimer();
        }
        else {
            resetTimmer();
        }
                
    }, [props.start]);
    
    useEffect(() => {
        
        if(props.reset) {
            resetTimmer();
        }
                
    }, [props.reset]);
    
    const startTimer = () => {
                
        let duration;
        if(window._timerInterval) {            
            clearInterval(window._timerInterval);
            window._timerInterval = null;
            
            let rTime = resetTimmer();
            duration = (60 * parseInt(rTime.mins)) + parseInt(rTime.secs);
        }
        else {
            duration = (60 * parseInt(mins)) + parseInt(secs);
        }
        
        let timer = duration, minutes, seconds;
        
        window._timerInterval = setInterval(function() {
            
            minutes = parseValue(parseInt(timer / 60, 10));
            seconds = parseValue(parseInt(timer % 60, 10));
                        
            setMins(minutes);
            setSecs(seconds);
            
            //end
            if (--timer < 0) {
                
                timer = duration;
                
                if(window._timerInterval) {
                    clearInterval(window._timerInterval);
                    window._timerInterval = null;
                    
                    if(props.timerEnded)
                        props.timerEnded();
                    
                }
            }
                        
        }, 1000);
     
    }
    
    const resetTimmer = () => {
        
        const time = secsToTime(get(props, 'value', 0));
        let defaultMins = parseValue(time.mins);
        let defaultSecs = parseValue(time.secs);
                
        setMins(defaultMins);
        setSecs(defaultSecs);
        
        return {mins: defaultMins, secs: defaultSecs};
        
    }
    
    const updateInput = (inputEl, focusOut=false) => {
        
        let value = parseInt(inputEl.value | ''),
            min = parseInt(inputEl.min),
            max = parseInt(inputEl.max);
                    
        if(value < min)
            value = min;
        
        if(value > max)
            value = max;
                        
        if(focusOut) {
            value = parseValue(value);
        }
        
        if(inputEl.dataset.unit === 'mins') {

            setMins(value.toString());   
            return timeToSecs(value, secs);
            
        }
        else {
            
            setSecs(value.toString());
            return timeToSecs(mins, value);
                        
        }
        
    }
    
    const focusIn = (evt) => {
        
        if(evt.target.value === 0 || evt.target.value === '00') {
            
            evt.target.dataset.unit === 'mins' ? setMins('') : setSecs('');
                
        }
        
    }
    
    const changeHandler = (evt) => {
                                
        const totalSecs = updateInput(evt.target);
        
        if(props.onChange)
            props.onChange(totalSecs);
 
    }
    
    const blurHandler = (evt) => {
                     
        const totalSecs = updateInput(evt.target, true);
        
        if(props.onBlur)
            props.onBlur(totalSecs);           
             
    }
    
    const parseValue = (value) => {
                
        if(String(value).length === 1) {
            value = "0"+String(value);
        }
                
        return value;
        
    }
    
    return (
       
        <div className={props.disabled ? 'input time-input disabled' : 'input time-input'}>
            <div>
                <input 
                    type="number" 
                    className="time-mins input-with-value" 
                    placeholder="mins"
                    min={0}
                    max={120}
                    step={1}
                    data-unit="mins"
                    value={mins}
                    onFocus={focusIn}
                    onChange={changeHandler}
                    onBlur={blurHandler} 
                    disabled={props.disabled}
                />
                <span>
                    min
                </span>
            </div>
            <div>
                <input 
                    type="number" 
                    className="time-secs input-with-value" 
                    placeholder="secs"
                    min={0}
                    max={59}
                    step={1}
                    data-unit="secs"
                    value={secs}
                    onFocus={focusIn}
                    onChange={changeHandler}
                    onBlur={blurHandler} 
                    disabled={props.disabled} 
                />
                <span>
                    sec
                </span>
            </div>
        </div>
    )
    
}

TimeInput.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    start: PropTypes.bool,
    reset: PropTypes.bool,
    timerEnded: PropTypes.func
}

export default TimeInput;