import { initializeApp, getApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider }  from "firebase/app-check";
import { Capacitor } from '@capacitor/core';
import { 
    getAuth,
    indexedDBLocalPersistence, 
    initializeAuth
} from 'firebase/auth';

let fbConfig = process.env.REACT_APP_IS_STAGE ? require('fb_config_stage.json') : require('fb_config_prod.json');

const createFirebaseApp = (config) => {
    
    try {
        return getApp();
    }
    catch {
        return initializeApp(config);
    }
    
};

const firebaseApp = createFirebaseApp(fbConfig);

let _auth;
if (Capacitor.isNativePlatform()) {
    
    _auth = initializeAuth(firebaseApp, {
        persistence: indexedDBLocalPersistence
    });
    
}
else {
    _auth = getAuth(firebaseApp);
}

//only for production and web
if( process.env.NODE_ENV !== 'development' && !process.env.REACT_APP_IS_STAGE && !Capacitor.isNativePlatform()) {
        
    const appCheck = initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaV3Provider('6LfrjFUhAAAAAByVHkVcBchpl4_DJL0FgZTBsu7G'),
        isTokenAutoRefreshEnabled: true
    });
        
}

const auth = _auth;
export {auth, firebaseApp};
export default firebaseApp;