import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    getMovements, 
} from 'redux-store/slices/movements';
import { 
    Popup, 
    Page, 
    Block, 
    Row, 
    Col, 
    Button,
    Searchbar,
    List,
    ListItem
} from 'framework7-react';
import { map, isEmpty } from 'lodash';
import logParameters from 'json/log-parameters.json';
import $ from 'jquery'; 

import ModalActions from 'ui/comps/global/ModalActions';

const MovementsPopup = (props) =>  {
    
    const dispatch = useDispatch();
    
    const loading = useSelector((state) => state.movements.loading);
    const allMovements = useSelector((state) => state.movements.all);
    
    useEffect(() => {
                
        dispatch( getMovements() );
                    
    }, []);
    
    const itemSelected = (evt) => {
        
        const $listItem = $(evt.currentTarget).parents('li:first');
        props.onSelect($listItem.data('movdata'));
        window.f7App.popup.get('#movements-popup').close();
        
    }
    
    return (
        <Popup
            id="movements-popup"
        >
            <Page>
                <Block>
                    <Row>
                        <ModalActions 
                            id = "modal-actions-movements" 
                            width="100"
                        />
                    </Row>
                </Block>
                <Block>
                    {!loading && isEmpty(allMovements) &&
                        <div className="notice notice-info">
                            You have not added any movements to your personal library. Please create a movement first.
                        </div>
                    }
                    {!isEmpty(allMovements) &&
                        <React.Fragment>
                        <Searchbar
                            inline
                            placeholder="Search for a movement"
                            searchContainer="#movements-search-results"
                            searchIn=".item-title"
                            disableButton={false}
                        />
                        <List className="search-list" id="movements-search-results">
                            {map(allMovements, (entry, i) => {
                                let {name, log_parameter, id} = entry;
                                let movData = {name, log_parameter, id};
                                return (
                                    <ListItem 
                                        title={entry.name} 
                                        footer={logParameters[entry.log_parameter]}
                                        link="#"
                                        after="+"
                                        noChevron
                                        data-movdata={JSON.stringify(movData)}
                                        onClick={itemSelected}
                                        key={entry.id}
                                    />
                                )
                            })}
                        </List>
                        </React.Fragment>
                    }
                   
                </Block>
            </Page>
        </Popup>
        
    )
    
}

export default MovementsPopup;