import React, { useEffect, useState } from 'react';
import { 
    PageContent, 
    Block,
    List,
    ListInput,
    Button
} from 'framework7-react';
import $ from 'jquery'; 
import { 
    updateUserPassword
} from 'fb-api/users';
import { isEmpty, get } from 'lodash';


const AccountPassword = ({f7router}) =>  {
    
    const [password, setPassword] = useState('');
    const [passwordRe, setPasswordRe] = useState('');
    
    useEffect(() => {
        
        //settings does not update main nav active state, do that with jquery 
        $('#main-toolbar .link[data-route-tab-id="account"]').addClass('tab-link-active')
        .siblings().removeClass('tab-link-active');
    
              
    }, []);
    
    const updatePassword = async () => {
        
        if(isEmpty(password) || isEmpty(passwordRe)) {
            return f7router.app.toast.create({text: 'Password can not be empty!'}).open();
        }
        else if(password !== passwordRe) {
            return f7router.app.toast.create({text: 'Re-entered password is not equal!'}).open();
        }
    
        const res = await updateUserPassword(password);
        f7router.app.toast.create({text: res.message ? res.message : res.error}).open();
        
        setPassword('');
        setPasswordRe('');
        
    }
    
    
    return (
        <PageContent>
        
            <Block>
            
                <List>
                    <ListInput
                        label="New Password"
                        type="password"
                        placeholder="Enter a new password"
                        clearButton
                        value={password}
                        onChange={(evt) => setPassword(evt.target.value)}
                    />
                    <ListInput
                        label="Re-enter New Password"
                        type="password"
                        placeholder="Re-enter the new password"
                        clearButton
                        value={passwordRe}
                        onChange={(evt) => setPasswordRe(evt.target.value)}
                    />
                </List>
                <Button 
                    text="Update Password"
                    className="display-inline-block"
                    fill
                    small
                    onClick={updatePassword}
                />
            
            </Block>
            
        </PageContent>
    )
    
}

export default AccountPassword;