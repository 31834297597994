import firebaseApp from './init';
import {
    getFirestore,
    collection,
    doc,
    addDoc,
    getDocs,
    query,
    where,
    getDoc,
    setDoc,
    deleteDoc,
    serverTimestamp,
    orderBy,
    limit
} from 'firebase/firestore';
import { isEmpty, isArray, get } from 'lodash';

const db = getFirestore(firebaseApp);
const personalRecordsCol = collection(db, 'personal_records');

export const writePersonalRecord = (payload) => {
        
    try {
        
        let data = payload.data;
                         
        //update
        if(payload.personal_record_id) {
            return setDoc(doc(db, personalRecordsCol.path, payload.personal_record_id), data, {merge: true});
        }
        //create
        else {
                        
            data.user_id = global._currentUser.uid;
            data.created_at = data.created_at ? data.created_at : serverTimestamp();
            return addDoc(personalRecordsCol, data);
        }
    }
    catch(err) {
        console.error(err.message);
    }
     
};

export const getPersonalRecords = (opts={limit: 10}) => {
                
    let constraintsWhere = [];
    constraintsWhere.push(where('user_id', '==',  get(global, '_currentUser.uid', null)));
    constraintsWhere.push(where('movement_id', '==',  opts.movId));
    
    if(opts.benchmark) {
        constraintsWhere.push(where('benchmark', '==',  opts.benchmark));
    }
        
    let constraintsOrder = [];
    constraintsOrder.push(orderBy('created_at', 'asc'));
        
    let q = query(personalRecordsCol, ...constraintsWhere, ...constraintsOrder, limit(opts.limit));

    return getDocs(q);
    
};

export const getBestPersonalRecord = (opts={}) => {
        
    let constraintsWhere = [];
    constraintsWhere.push(where('user_id', '==',  get(global, '_currentUser.uid', null)));
    constraintsWhere.push(where('movement_id', '==',  opts.movId));
        
    if(opts.benchmark) {
        constraintsWhere.push(where('benchmark', '==',  opts.benchmark));
    }
        
    let q = query(personalRecordsCol, ...constraintsWhere, orderBy('record', get(opts, 'dir', 'desc')), limit(1));

    return getDocs(q);
    
};

export const deletePersonalRecord = (id) => {
    
    return deleteDoc( doc(db, personalRecordsCol.path, id) );
    
};