export const errorMap = {
    "auth/invalid-email": "The email address is not valid.",
    "auth/internal-error": "Internal error with authentication.",
    "auth/email-already-in-use": "An account with this email address is already registered. Please use a different email address!",
    "auth/weak-password": "The password is too short.",
    "auth/user-not-found": "The account does not exist.",
    "auth/wrong-password": "The password is not correct.",
    "auth/missing-email": "The email address is missing."
};

export const fbError = (code) => {
    return errorMap[code] ? errorMap[code] : code;
}

