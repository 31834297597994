import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { save, getMulti, getSingle, deleteSingle } from 'fb-api/sessions';
import { readDaySessions, writeDaySession } from 'fb-api/day-sessions';
import moment from 'moment';
import { findIndex, merge, pick, without, find, cloneDeep, get } from 'lodash';

const initialState = {
    loading: false,
    all: [],
    allDaySessions: [],
    single: null
};

const defaultMultiValues = [
    'id',
    'name',
    'execution_date',
    'order',
    'workouts',
    'completed'
];

const _getDaySessions = async (payload) => {
    
    try {
        
        let result = [];
        const querySnapshot = await readDaySessions(payload);
        querySnapshot.forEach((doc) => {
            
            let docObj = {id: doc.id, date: doc.get('date'), sessions: doc.get('sessions')};
            docObj.date = moment(docObj.date.toDate()).format(global.config.app.date_format);
            result.push(docObj);
            
        });
        
        return result;
        
    }
    catch(err) {
        
        console.error(err.message);
        return [];
        
    }
    
}

export const getDaySessions = createAsyncThunk('session/getDaySessions', async (payload, { rejectWithValue }) => {
    
    return _getDaySessions(payload);
   
});

export const updateDaySessions = createAsyncThunk('session/updateDaySessions', async (payload, { rejectWithValue }) => {
    
    writeDaySession(payload);
    return payload;
   
});

const _getMulti = async (payload, { rejectWithValue }) => {
    
    try {
        
        let result = [];
        const querySnapshot = await getMulti(payload);
        querySnapshot.forEach((doc) => {
            
            let docObj = {...pick(doc.data(), defaultMultiValues), id: doc.id};
            docObj.execution_date = moment(docObj.execution_date.toDate()).format(global.config.app.date_format);
            result.push(docObj);
            
        });
        
        return result;
        
    }
    catch(err) {
        
        return rejectWithValue(err.message);
        
    }
    
}

export const writeSession = createAsyncThunk('session/writeSession', async (payload, { rejectWithValue }) => {
    
    try {
        
        const docRef = await save(payload);
        
        let reducerObj = {...payload.data};
        if(payload.session_id)
            reducerObj.session_id = payload.session_id;
        else 
            reducerObj.id = docRef.id;
            
        //add session id to day_sessions collection if new one is created
        if(!payload.session_id) {
            
            const daySessionId = await writeDaySession({
                day_session_id: payload.day_session_id,
                add: docRef.id,
                data: {date: payload.data.execution_date} 
            });
            
            reducerObj.daySessionId = daySessionId;
            
        }
        //date of session has changed, rewrite day sessions
        else if(reducerObj._new_date) {
                        
            const newDaySessionId = await writeDaySession({
                day_session_id: payload.day_session_id,
                session_id: payload.session_id,
                new_date: payload.data.execution_date,
            });
            
            reducerObj.new_date = payload.data.execution_date;
            reducerObj.daySessionId = payload.day_session_id;
            reducerObj.newDaySessionId = newDaySessionId;
            reducerObj.sessionId = payload.session_id;
            
        }
                            
        return reducerObj;
        
    }
    catch(err) {
        return rejectWithValue(err.message);
    }
       
});

export const getSessions = createAsyncThunk('session/getSessions', async (payload, { rejectWithValue }) => {
    
    return _getMulti(payload);
   
});

export const getSessionDetails = createAsyncThunk('session/getSessionDetails', async (payload, { rejectWithValue }) => {
        
    try {
        const docSnap = await getSingle(payload.id);
        return {id: docSnap.id, ...docSnap.data()};
    }
    catch(err) {
        return rejectWithValue(err.message);
    }
    
});

export const deleteSession = createAsyncThunk('session/deleteSession', async (payload) => {
    
    try {

        deleteSingle(payload.id);
        
        writeDaySession({
            day_session_id: payload.day_session_id,
            remove: payload.id,
        });
        
        return {sessionId: payload.id, daySessionId: payload.day_session_id};   
    }
    catch(err) {
        console.error(err.message);
    }
          
});

export const sessionsSlice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getDaySessions.pending]: (state, action) => {
            state.loading = 'Loading Sessions...';
        },
        [getDaySessions.fulfilled]: (state, action) => {
            state.loading = false;
            state.allDaySessions = action.payload;
        },
        [getDaySessions.rejected]: (state, action) => {
            state.loading = false;
            state.allDaySessions = [];
        },
        [updateDaySessions.pending]: (state, action) => {
            state.loading = 'Saving Session...';
        },
        [updateDaySessions.fulfilled]: (state, action) => {
            
            //order of session inside has changed
            state.loading = false;
            
            const payload = action.payload;
            
            let allSessions = cloneDeep(state.allDaySessions);
            let docIndex = findIndex(allSessions, {id: payload.day_session_id});
            
            allSessions[docIndex].sessions = payload.data.sessions;
                        
            state.allDaySessions = allSessions;
        },
        [updateDaySessions.rejected]: (state, action) => {
            state.loading = false;
        },
        [writeSession.pending]: (state, action) => {
            state.loading = 'Saving Session...';
        },
        [writeSession.fulfilled]: (state, action) => {
            
            state.loading = false;
            
            const payload = action.payload;
            let stateAllDaySessions = [...state.allDaySessions];
            
            //date of session is changed
            if(payload.new_date) {
                
                //remove from current day
                let docIndex = findIndex(stateAllDaySessions, {id: payload.daySessionId});
                stateAllDaySessions[docIndex].sessions = without(
                    stateAllDaySessions[docIndex].sessions, payload.sessionId
                );
                
                //add to new day
                let newDocIndex = findIndex(stateAllDaySessions, {id: payload.newDaySessionId});
                
                //day has no sessions
                if(newDocIndex === -1) {
                    
                    stateAllDaySessions.push({
                        date: moment(payload.new_date).format(global.config.app.date_format),
                        sessions: [payload.sessionId],
                        id: payload.newDaySessionId
                    });
                
                }
                //day has session, add session id to it
                else {
                    stateAllDaySessions[newDocIndex].sessions.push(payload.sessionId);
                }
                                
            }
            //new session is created
            else if(payload.daySessionId) {
                
                let docIndex = findIndex(stateAllDaySessions, {id: payload.daySessionId});
                
                //no sessions found day, create a new day session object
                if(docIndex === -1) {
                    
                    stateAllDaySessions.push({
                        date: moment(payload.execution_date).format(global.config.app.date_format),
                        sessions: [payload.id],
                        id: payload.daySessionId
                    });
                
                }
                //day has session, add session id to it
                else {
                    stateAllDaySessions[docIndex].sessions.push(payload.id);
                }
                
            }
            //update existing session
            else {
                
            }
            
            state.allDaySessions = stateAllDaySessions;
            

        },
        [writeSession.rejected]: (state, action) => {
            console.log("rejected", action.payload);
            state.loading = false;
        },
        [getSessionDetails.pending]: (state, action) => {
            state.loading = 'Retrieving Session Details...';
        },
        [getSessionDetails.fulfilled]: (state, action) => {
            state.loading = false;
            state.single = action.payload;
        },
        [getSessionDetails.rejected]: (state, action) => {
            state.loading = false;
            state.single = null;
        },
        [deleteSession.pending]: (state, action) => {
            state.loading = 'Deleting Session...';
        },
        [deleteSession.fulfilled]: (state, action) => {
            state.loading = false;
            
            //remove session id from day sessions
            let stateAllDaySessions = [...state.allDaySessions];
            let docIndex = findIndex(stateAllDaySessions, {id: action.payload.daySessionId});
            
            stateAllDaySessions[docIndex].sessions = without(
                stateAllDaySessions[docIndex].sessions, action.payload.sessionId
            );
            
            state.allDaySessions = stateAllDaySessions;
            
        },
        [deleteSession.rejected]: (state, action) => {
            state.loading = false;
        }
    }
});

export default sessionsSlice.reducer;