import firebaseApp from './init';
import {
    getFirestore,
    collection,
    doc,
    addDoc,
    updateDoc,
    getDocs,
    query,
    where,
    getDoc,
    deleteDoc,
    serverTimestamp,
    onSnapshot,
    orderBy,
    arrayUnion,
    arrayRemove
} from 'firebase/firestore';
import { get } from 'lodash';

const db = getFirestore(firebaseApp);
const daySessionsCol = collection(db, 'day_sessions');

export const writeDaySession = (payload) => {
        
    return new Promise(async (resolve, reject) => {
        
        let data = payload.data ? payload.data : {};
        let daySessionId = payload.day_session_id;
        
        if(payload.add) //add a new session
            data.sessions = arrayUnion(payload.add);
        else if(payload.remove) // remove session
            data.sessions = arrayRemove(payload.remove);
        else if(payload.new_date) { //session date has changed
                        
            //remove session from current day_sessions
            await updateDoc(
                doc(db, daySessionsCol.path, daySessionId), 
                {sessions: arrayRemove(payload.session_id)}
            );
            
            //find existing day_sessions doc to add session            
            const querySnapshot = await getDocs(query(
                    daySessionsCol, 
                    where('user_id', '==',  global._currentUser.uid), 
                    where('date', '==',  payload.new_date)
            ));
            
            if(!querySnapshot.empty) //existing doc with sessions found, add to it later
                daySessionId = querySnapshot.docs[0].id; 
            else //no existing day with sessions found, create new one later
                daySessionId = null;
            
            data.date = payload.new_date;   
            data.sessions = arrayUnion(payload.session_id);
                        
        }
 
        //create
        if(!daySessionId) {
                        
            data.user_id = global._currentUser.uid;
            const docRef = await addDoc(daySessionsCol, data, {merge: true});
            
            resolve(docRef.id);
        }
        //update existing day_sessions
        else {
            
            await updateDoc(
                doc(db, daySessionsCol.path, daySessionId), 
                data
            );
            
            resolve(daySessionId);
            
        }
        
    })    

};

export const readDaySessions = (opts={}) => {
        
    let constraints = [];
    
    constraints.push(where('user_id', '==',  get(global, '_currentUser.uid', null)));
    
    if(opts.minDate) {
        constraints.push(where('date', '>=',  opts.minDate));
    }
    
    if(opts.maxDate) {
        constraints.push(where('date', '<',  opts.maxDate));
    }
    
    const q = query(daySessionsCol, ...constraints, orderBy('date'));
    
    return getDocs(q);
    
};