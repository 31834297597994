import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
    List,
    ListItem,
    BlockHeader,
    SwipeoutActions,
    SwipeoutButton
} from 'framework7-react';
import Record from './Record';
import InfoIcon from 'ui/comps/global/InfoIcon';
import { map, findIndex } from 'lodash';

const RecordList = (props) => {
    
    const [data, setData] = useState([]);
    
    useEffect(() => {
        
        setData(props.data);
         
    }, [props.data]);
    
    const deleteRecord = (recordId, index) => {
        
        window.f7App.dialog.confirm(
            `Are you sure you want to delete this record?`,
            '',
            () => {
                props.recordDelete(recordId, index)
            }
        )
        
    }
    
    return (
        <div className="record-list-wrapper" id={props.id}>
            {props.caption &&
                <div className="caption">
                    <BlockHeader className="no-padding">{props.caption}</BlockHeader>
                    {props.subCaption && 
                        <InfoIcon id={props.id+'-help'}>
                            {props.subCaption}
                        </InfoIcon>
                    }
                </div>
            }
            <List mediaList className="history-list">
                {map(data, (entry, i) => {
                                        
                    return (
                        <ListItem
                           title={entry.date}
                           after=<Record value={entry.record} log={props.log} />
                           swipeout
                           key={entry.id}
                       >
                           <SwipeoutActions right>
                               <SwipeoutButton color="red" onClick={() => deleteRecord(entry.id, i)}>
                                   Delete
                               </SwipeoutButton>
                           </SwipeoutActions>
                       </ListItem>
                    )
                   
                })}
            </List>
        </div>
    )
    
}

RecordList.propTypes = {
    id: PropTypes.string,
    data: PropTypes.array,
    caption: PropTypes.string,
    log: PropTypes.string,
    recordDelete: PropTypes.func
}

RecordList.defaultProps = {
    id: '',
    data: [],
    caption: '',
    subCaption: ''
}

export default RecordList;