import React, {useState, useEffect} from 'react';
import { 
    Panel, 
    Page, 
    Block, 
    List, 
    ListItem,
    Button,
    Input,
    Card,
    CardHeader,
    CardContent,
    Icon,
    Row,
    Col,
    BlockTitle,
    Segmented,
    Preloader
} from 'framework7-react';
import { map, isEmpty, get, now, findIndex, find, merge, pick } from 'lodash';
import moment from 'moment';
import { readWorkout, writeWorkout, readWorkouts, deleteSingleWorkout } from 'fb-api/workouts';
import { swapArrayLoc } from 'misc/helpers';
import $ from 'jquery';

import WorkoutsPopup from 'ui/comps/workout/WorkoutsPopup.js';
import SelectWorkoutTypePopup from 'ui/comps/workout/SelectWorkoutTypePopup.js';
import WriteWorkoutPopup from 'ui/comps/workout/WriteWorkoutPopup.js';
import SessionWorkout from 'ui/comps/planner/SessionWorkoutWrite';
import ModalActions from 'ui/comps/global/ModalActions';

let changeDateCal = null;

const WriteSession = (props) =>  {
    
    const [loading, setLoading] = useState(true);        
    const [executionDate, setExecutionDate] = useState('');
    const [name, setName] = useState('');
    const [note, setNote] = useState('');
    const [workouts, setWorkouts] = useState([]);
    
    //creating or editing a new workout
    const [currentWorkoutType, setWorkoutType] = useState('sets');
    const [currentWorkoutId, setWorkoutId] = useState(null);
    const [currentSingleWorkout, setSingleWorkout] = useState({});
    
    useEffect(() => {
                
        setWorkouts([]);
        setLoading(true);
        
        if(!isEmpty(props.workouts)) {
            
            (async function() {
                
                const workoutIds = map(props.workouts, 'id');
                const sessionWorkouts = await readWorkouts({includeIds: workoutIds});
                
                let workoutsData = [];
                sessionWorkouts.forEach((docRef) => {
                    workoutsData.push({id: docRef.id, ...docRef.data()});
                });
                
                //sort by correct order from workout IDs
                workoutsData = map(workoutsData, (w, i) => {
                    return find(workoutsData, {id: workoutIds[i]});
                });
                
                setWorkouts(workoutsData);
                setLoading(false);
                
            })();
            
        }
        else
            setLoading(false);
        
                        
    }, [props.workouts]);
    
    useEffect(() => {
        
        if(name.length > global.config.app.session_name_max_length) {
            setName(name.slice(0, global.config.app.session_name_max_length));
        }
                
    }, [name]);
    
    const panelOpened = () => {
        
        setName(get(props, 'name', ''));
        setNote(get(props, 'note', ''));
        setExecutionDate(get(props, 'execution_date', ''));
        
        changeDateCal = window.f7App.calendar.create({
            inputEl: '#change-date-input',
            on: {
                change: (cal, val) => {
                                        
                    setExecutionDate(
                        moment(val[0]).format(global.config.app.date_format)
                    )
                    
                    cal.close();
                    
                }
            }
        });
        
    }
    
    //add a workout from athletes library
    const addLibraryWorkout = async (workoutId) => {
                
        try {
            
            const docRef = await readWorkout(workoutId);
                        
            let currentWorkouts = [...workouts];
            currentWorkouts.push({id: docRef.id, ...docRef.data()});
            setWorkouts(currentWorkouts);
            
        }
        catch(err) {
            window.f7App.dialog.alert('The workout does not exist.', 'Error')
        }
         
    }
    
    const workoutTypeSelect = (type) => {
        
        setWorkoutId(null);
        setSingleWorkout({});
        setWorkoutType(type);

        window.f7App.popup.get('#workout-type-popup').close();
        window.f7App.popup.get('#add-edit-workout-popup').open();
       
    }
    
    //add a new workout
    const saveWorkout = async (data) => {
                        
        try {
            
            const docRef = await writeWorkout({data: {...data}, workout_id: currentWorkoutId});
            let currentWorkouts = [...workouts];
            
            //updating workout
            if(currentWorkoutId) {
                let targetWorkout = find(currentWorkouts, {id: currentWorkoutId});
                merge(targetWorkout, data);
                targetWorkout.key = now(); //set timestamp to force a view update
            }
            //creating workout
            else {
               currentWorkouts.push({id: docRef.id, ...data}); 
            }
            
            setWorkouts(currentWorkouts);
            
        }
        catch(err) {
            console.error(err.message);
        }
                
        window.f7App.popup.get('#add-edit-workout-popup').close();
        
        setWorkoutId(null);
        
    }
    
    //edit session workout (not library)
    const editWorkout = async (workoutId) => {
        
        const docRef = await readWorkout(workoutId);
        const workoutData = {id: docRef.id, ...docRef.data()};
        
        setWorkoutType(workoutData.type);
        setSingleWorkout(workoutData);
        setWorkoutId(workoutId);
        
        window.f7App.popup.get('#add-edit-workout-popup').open();
        
    }
    
    const moveWorkout = (dir, workoutId) => {
        
        let currentWorkouts = [...workouts];
        
        let workoutIndex = findIndex(currentWorkouts, {id: workoutId});
        let newIndex = dir == 'up' ? (workoutIndex - 1) : (workoutIndex + 1);
        
        if(newIndex < 0)
            newIndex = 0;
        else if (newIndex > currentWorkouts.length-1)
            newIndex = currentWorkouts.length-1;
                
        currentWorkouts = swapArrayLoc(currentWorkouts, workoutIndex, newIndex);
        
        setWorkouts(currentWorkouts);
        
    }
    
    const removeWorkout = (id) => {
        
        window.f7App.dialog.confirm(
            'Are you sure to delete the workout?',
            '',
            () => {
                
                let currentWorkouts = [...workouts],
                    workoutIndex = findIndex(currentWorkouts, {id: id});
                
                if(workoutIndex !== -1) {
                    
                    //delete workout if its not stored in library                    
                    if(!currentWorkouts[workoutIndex].is_library)
                        deleteSingleWorkout(id);
                    
                    currentWorkouts.splice(workoutIndex, 1); 
                    setWorkouts(currentWorkouts);
                    
                } 
                
                
            }
        )
        
    }
    
    const saveClick = () => {
        
        //name
        if(name.length < 2 ) {
            
            window.f7App.toast.create(
                {text: 'The name must contain at least 2 characters!'}
            ).open();
            
        }
        else 
            window.f7App.panel.get('#planner-write-session').close();
                
    }
    
    const saveSession = () => {
        
        if(name.length < 2 )
            return;
                
        const workoutIds = map(workouts, (obj) => {
            return pick(obj, ['id']);
        });
        
        let data = {
            name: name,
            note: note,
            execution_date: executionDate,
            workouts: workoutIds
        };
        
        if(props.execution_date !== executionDate)
            data._new_date = true;
        
        props.save(data, props.id)      
        
    }
    
    const panelClosed = () => {
        
        saveSession();        
        setWorkouts([]);
        
    }
    
    const renderWorkouts = () => {
        
        return (        
            map(workouts, (workout, i) => {
                                
                return (
                    <SessionWorkout
                        key={i}
                        {...workout}
                        moveWorkout={moveWorkout}
                        editWorkout={editWorkout}
                        removeWorkout={removeWorkout}
                    />
                )
                
            })
        )
      
    }
    
    return (
        
        <React.Fragment>
            <Panel 
                right 
                cover
                swipe
                swipeOnlyClose 
                closeByBackdropClick={false}
                id="planner-write-session"
                onPanelOpened={panelOpened}
                onPanelClosed={panelClosed}
            >
                <Page>
                    {loading && 
                        <Preloader className="panel-preloader" />
                    }
                    <Block id="write-session-header">
                        <Row>
                            <Col width="80">
                                <BlockTitle 
                                    large
                                    className="no-margin"
                                >
                                    {
                                        moment(executionDate, global.config.app.date_format)
                                        .format(global.config.app.display_date_format)
                                    }
                                    <Button 
                                        iconMaterial="edit_calendar"
                                        tooltip="Change Date"
                                        className="display-inline-block no-padding"
                                        onClick={() => changeDateCal.open()}
                                     />
                                     <input id="change-date-input" />
                                </BlockTitle>
                            </Col>
                            <ModalActions 
                                id = "panel-actions-write-session" 
                                modalType = "panel"
                            />
                        </Row>                    
                    </Block>
                    
                    <Block>
                        <Row>
                            <Col>
                                <Input
                                    type="text"
                                    placeholder="Enter session name"
                                    autocapitalize="words"
                                    value={name}
                                    onChange={(evt) => setName(evt.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className="margin-top">
                            <Col>
                                <Input
                                    type="textarea"
                                    placeholder="Add session notes, tips, reminders or techniques here..."
                                    value={note}
                                    onChange={(evt) => setNote(evt.target.value)}
                                />
                            </Col>
                        </Row>  
                    </Block>
                    <Block>
                        <BlockTitle medium>
                            Workouts
                        </BlockTitle>
                        <Segmented>
                            <Button
                                text="Create New"
                                outline
                                small
                                popupOpen="#workout-type-popup"
                            />
                            <Button
                                text="Add From Library"
                                outline
                                small
                                popupOpen="#workouts-popup"
                            />
                        </Segmented>
                        
                        <div className="workouts">
                            {renderWorkouts()}
                        </div>      
                    </Block>
                    <Block>
                        <Button
                            text="Save Session"
                            fill
                            onClick={() => {saveClick()}}
                        />
                    </Block>
                </Page>
            </Panel>
            
            <WorkoutsPopup
                onSelect={addLibraryWorkout}
            />
            
            <SelectWorkoutTypePopup 
                workoutTypeSelect={workoutTypeSelect}
            />
            
            <WriteWorkoutPopup
                type={currentWorkoutType}
                {...currentSingleWorkout}
                add={saveWorkout}
            />
            
        </React.Fragment>
    )
    
}

export default WriteSession;