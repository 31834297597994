import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import algoliasearch from 'algoliasearch';
import { InstantSearch, Hits, RefinementList, Pagination, Menu } from 'react-instantsearch-hooks-web';
import { 
    Popup,
    Page,
    Block,
    ListItem
} from 'framework7-react';
import SearchBox from 'ui/comps/algolia/SearchBox';
import PublicLibraryHits from 'ui/comps/algolia/PublicLibraryHits';

function PublicLibrary(props) {
    
    const searchClient = algoliasearch(
            global.config._algoliaCreds.appId, 
            global.config._algoliaCreds.apiKey
    );
        
    useEffect(() => {
        
        const index = searchClient.initIndex('public_library');

        
    }, [])

    const Hit = ({ hit }) => {
        
        return (
            
            <ListItem
                title={hit.name}
            />
            
        )
        
    }
    
    return (
        <Popup
            id="public-library-popup"
        >
            <Page>
                <Block>
                    <InstantSearch searchClient={searchClient} indexName="public_library">
                        <SearchBox />
                        <Menu 
                            attribute="category"
                            classNames = {{
                                item: 'chip chip-outline',
                            }}
                        />
                        <PublicLibraryHits />
                        <Pagination
                            classNames = {{
                                root: 'pagination-menu',
                                list: 'segmented',
                                item: 'button button-small button-outlin',
                                disabledItem: 'disabled'
                            }}

                        />
                    </InstantSearch>
                </Block>
                
            </Page>
            
        </Popup>
        
    )
}

PublicLibrary.propTypes = {

}

export default PublicLibrary

