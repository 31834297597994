import React from 'react';

const Logo = () =>  {
    
    return (
        <div id="logo">
            <svg width="762px" height="113px" viewBox="0 0 762 113" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <g id="X" className="logo-letter">
                        <path d="M112.189,37.6530247 L167.71,37.7959247 L207.909,0.105252508 L169.174,0.105252508 C162.23,0.105252508 153.966,0.10525256 147.654,2.72087469 C144.227,4.18940469 141.119,6.30568469 138.501,8.95262469 L129.52,18.7290247 L99.6076,3.96436469 C96.4501,2.37130469 93.0614,1.28133469 89.565,0.734144688 C83.5744,0.0598553878 77.5403,-0.150406312 71.5171,0.105252508 L18.4351,0.105252508 L22.9399,2.96385469 C24.2167,3.70709469 70.9863,29.6346247 72.2488,30.3778247 C80.1025,34.9702247 89.0377,37.4065247 98.1442,37.4386247" id="Path"></path>
                        <path d="M167.854,44.4135945 L100.555,44.4135945 C95.9131,44.4059247 91.3901,45.8729247 87.6428,48.6014247 L0,111.876225 L70.2978,111.876225 L126.35,70.4269247 L126.507,70.3268247 C128.358,71.5846247 129.893,72.6137247 131.127,73.4713247 C131.784,73.9284247 132.321,74.5367247 132.691,75.2447247 C133.062,75.9526247 133.255,76.7392247 133.255,77.5376247 C133.255,78.3360247 133.062,79.1226247 132.691,79.8306247 C132.321,80.5385247 131.784,81.1469247 131.127,81.6040247 C116.68,91.7663247 102.692,101.585225 88.0301,111.833225 L150.739,111.833225 L190.823,84.6770247 C196.561,80.3891247 195.701,72.8281247 191.425,68.3401247 L167.854,44.4135945 Z" id="Path"></path>
                    </g>
                    <path d="M223.174,86.5350247 C226.962,64.8382247 231.237,43.0842247 235.025,21.3303247 C236.459,12.2542247 233.59,4.30732469 225.542,0.162353588 L385.792,0.162353588 C385.652,3.13951469 385.012,6.07222469 383.898,8.83820469 C381.029,15.9847247 372.995,25.7325247 363.512,25.7325247 L284.333,25.7325247 C284.408,26.6260247 284.244,27.5232247 283.86,28.3338247 C283.306,32.9543247 282.516,37.5435247 281.493,42.0837247 L281.493,43.3558247 L363.512,43.3558247 C363.113,46.2934247 362.48,49.1946247 361.618,52.0317247 C360.806,56.7219247 358.368,60.9799247 354.73,64.0642247 C351.091,67.1485247 346.482,68.8639247 341.705,68.9117247 L276.744,68.9117247 L276.744,70.1409247 L275.309,77.9449247 C274.836,80.8035247 274.363,83.4906247 273.875,86.2205247 L273.875,86.4921247 L370.14,86.4921247 C369.666,89.4364247 369.193,92.2950247 368.705,95.1679247 C366.811,104.444225 357.802,112.062225 348.319,112.062225 L235.971,112.062225 C226.488,112.062225 219.86,104.444225 221.754,95.1679247 C222.227,92.3379247 222.701,89.4793247 223.174,86.5350247 Z" id="H" className="logo-letter"></path>
                    <path d="M506.934,86.5350247 C508.237,86.5761247 509.515,86.1738247 510.558,85.3944247 C511.601,84.6150247 512.347,83.5050247 512.672,82.2471247 C513.189,79.3885247 513.562,76.2869247 514.107,73.2997247 C514.221,72.7957247 514.222,72.2728247 514.111,71.7682247 C513.999,71.2636247 513.777,70.7898247 513.461,70.3804247 C513.144,69.9710247 512.741,69.6361247 512.28,69.3996247 C511.819,69.1630247 511.311,69.0306247 510.793,69.0118247 L421.127,69.0118247 C418.999,69.0952247 416.879,68.6931247 414.93,67.8363247 C412.981,66.9795247 411.255,65.6906247 409.882,64.0678247 C408.509,62.4450247 407.526,60.5313247 407.009,58.4727247 C406.491,56.4140247 406.453,54.2648247 406.896,52.1889247 C408.187,44.6279247 409.765,37.0383247 411.199,29.4345247 L412.634,21.4304247 C414.069,12.3543247 412.161,4.93626469 403.151,0.262451688 L576.729,0.262451688 C576.801,0.719827688 576.729,2.46357469 574.836,8.93830469 C572.741,15.6560247 564.879,25.8326247 555.396,25.8326247 L466.161,25.8326247 C465.008,25.9278247 463.919,26.3998247 463.063,27.1752247 C462.206,27.9506247 461.631,28.9860247 461.427,30.1205247 C460.623,33.1792247 460.064,36.1379247 459.533,39.0679247 C459.462,39.5670247 459.493,40.0751247 459.622,40.5624247 C459.751,41.0497247 459.977,41.5064247 460.285,41.9057247 C460.594,42.3051247 460.98,42.6390247 461.419,42.8880247 C461.859,43.1370247 462.345,43.2961247 462.847,43.3558247 L552.929,43.3558247 C561.938,43.3558247 568.365,50.9168247 566.687,60.2359247 C564.578,71.8847247 562.612,83.6335247 560.518,95.2109247 C558.825,104.530225 549.615,112.105225 540.605,112.105225 L400.727,112.105225 C401.214,109.246225 401.688,106.388225 402.161,103.429225 C404.055,94.1532247 413.065,86.5350247 422.548,86.5350247 L506.934,86.5350247 Z" id="S" className="logo-letter"></path>
                    <path d="M585.624,112.105225 C590.832,81.8612247 596.527,51.6315247 601.749,21.3303247 C603.184,12.2543247 600.315,4.30736469 593.213,0.162396688 L655.793,0.162396688 C652.923,14.5840247 650.571,29.0057247 648.203,43.3558247 L704.155,43.3558247 C705.589,34.6085247 707.024,25.8612247 708.903,17.0567247 C710.324,7.78056469 719.333,0.105224688 728.343,0.105224688 L762,0.105224688 C756.261,31.8071247 750.57,63.4899247 744.928,95.1537247 C744.047,99.8132247 741.59,104.031225 737.967,107.105225 C734.343,110.179225 729.772,111.924225 725.015,112.048225 L691.831,112.048225 C694.198,97.7550247 697.039,83.2762247 699.42,68.8546247 L643.469,68.8546247 C642.034,77.6733247 640.6,86.4064247 638.72,95.1537247 C637.909,99.8454247 635.472,104.105225 631.833,107.192225 C628.195,110.278225 623.586,111.997225 618.807,112.048225 L585.624,112.105225 Z" id="E" className="logo-letter"></path>
                </g>
            </svg>
        </div>
    )
    
}

export default Logo;