import React from 'react';
import { 
    Panel, 
    Page, 
    Block, 
    List, 
    ListItem,
    Button 
} from 'framework7-react';
import { logout } from 'fb-api/users';
import { Capacitor } from '@capacitor/core';

const MenuPanel = ({f7router}) =>  {
    
    return (
        
        <Panel 
            right 
            cover 
            swipe
            swipeOnlyClose
            id="account-menu-panel"
        >
            <Page>
                <Block>
                    <List menuList noChevron={true}>
                        <ListItem 
                            title="Personal Info"
                            link="/athlete/account/personal/"
                            panelClose
                        />
                        <ListItem 
                            title="Password"
                            link="/athlete/account/password/"
                            panelClose
                        />
                        <ListItem 
                            title="Units"
                            link="/athlete/account/units/"
                            panelClose
                        />
                        <ListItem 
                            title="Knowledge Base"
                            external
                            link="https://support.xesh.fit/support/solutions"
                            target="_blank"
                            panelClose
                        />
                        <ListItem 
                            title="Report a bug"
                            link="#"
                            panelClose
                            onClick={() => {
                                if(global.config._usersnapApi) global.config._usersnapApi.logEvent('open_bug_tracking');
                            } }
                        />
                        <ListItem 
                            title="Send an idea"
                            link="#"
                            panelClose
                            onClick={() => {
                                if(global.config._usersnapApi) global.config._usersnapApi.logEvent('open_feature_request');
                            } }
                        />
                        <ListItem 
                            title="Contact Support"
                            link="#"
                            panelClose
                            onClick={() => {
                                if(global.config._usersnapApi) global.config._usersnapApi.logEvent('open_user_feedback');
                            } }
                        />
                        <ListItem 
                            title="Info"
                            link="/athlete/account/info/"
                            panelClose
                        />
                        {Capacitor.getPlatform() === 'web' &&
                            <ListItem 
                                title="Cookie Preferences"
                                className="cky-banner-element"
                                link="#"
                                panelClose
                            />
                        }
                        {Capacitor.isNativePlatform() &&
                            <ListItem 
                                title="Visit Web Version"
                                external
                                link="https://app.xesh.fit"
                                target="_blank"
                                panelClose
                            />
                        }
                    </List>
                </Block>
                <Block>
                    <Button
                        text="Sign Out"
                        color="red"
                        outline
                        panelClose
                        onClick={() => {
                            logout()
                            .then(() => {
                                window.open('/signin', '_self');
                            })                                
                        }}
                    />
                </Block>
            </Page>
        </Panel>
        
    )
    
}

export default MenuPanel;