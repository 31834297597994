import { get, each, has, isObject } from 'lodash';

export const indexToAlpha = (num = 1) => {
    
   const A = 'A'.charCodeAt(0);
   let numberToCharacter = number => {
      return String.fromCharCode(A + number);
   };
   
   return numberToCharacter(num);
   
};

export const swapArrayLoc = (arr, from, to) => {
    
    var element = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, element);
    
    return arr;
    
}

export const renderTime = (timeObj) => {
    
    //is integer, convert to object
    if(!isObject(timeObj)) {
        timeObj = secsToTime(timeObj);
    }
        
    let mins = timeObj.mins,
        secs = timeObj.secs;
                
    if(mins === 0)
        mins = '00';
    
    if(secs === 0)
        secs = '00';
          
    return (
        <span className="time">{mins}:{secs}</span>
    )
    
}

export const renderTimecap = (totalSecs=0, type='sets') => {
    
    const timeObj = secsToTime(totalSecs);
        
    let mins = timeObj.mins,
        secs = timeObj.secs;
        
    if(mins === 0)
        mins = '00';
    
    if(secs === 0)
        secs = '00';
    
    if(type === 'sets') {
        return null;
    }            
    else if(mins === '00' && secs === '00') {
        return (
            <span className="timecap">No time cap</span>
        )
    }
    else {
        return (
            <span className="timecap">{mins}:{secs}</span>
        )
    } 
    
}



export const sanitizeDesc = (desc) => {
    
    const parser = new DOMParser();
    const doc = parser.parseFromString(desc, "text/html");
    
    each(doc.firstChild.getElementsByTagName('a'), (l) => {
        l.setAttribute('target', '_blank');
        l.classList.add('external')
    });
    
    desc = doc.documentElement.innerHTML;
    
    return desc;
    
}

export const intToRoundsReps  = (totalReps, repsPerRound) => {
    
    if(totalReps) {
        
        totalReps = parseInt(totalReps);
        
        let rounds = Math.floor(totalReps / repsPerRound);
        let reps = totalReps - (rounds * repsPerRound);

        return {rounds: rounds, reps: reps};
        
    }
    
    return {rounds: 0, reps: 0};
    
}

export const timeToSecs  = (mins=0, secs=0) => {
    
    return (60 * parseInt(mins)) + parseInt(secs);
    
}

export const secsToTime  = (totalSecs=0, leadingZero=true) => {
    
    if(totalSecs) {
        
        totalSecs = parseInt(totalSecs);
        
        let mins = Math.floor(totalSecs / 60);
        let secs = totalSecs - (mins * 60);
        
        if(leadingZero) {
            
            if(String(mins).length === 1)
                mins = '0'+mins;
                
            if(String(secs).length === 1)
                secs = '0'+secs;
            
        }

        return {mins: mins, secs: secs};
        
    }
    
    return {mins: 0, secs: 0};
    
}
