import React, { useEffect, useState } from 'react';
import { Page, Tabs, Tab, Toolbar, Link } from 'framework7-react';
import { get, isUndefined } from 'lodash';
import { getUserMeta, sendUserEmailVerification } from 'fb-api/users';

import Logo from 'ui/comps/global/Logo';
import AccountMenuPanel from 'ui/comps/account/MenuPanel';
import MovementPopup from 'ui/comps/movement/MovementPopup.js';
import TempPopup from 'ui/comps/global/TempPopup.js';

const AthleteLayout = ({f7router}) => {
    
    const [userMeta, setUserMeta] = useState(null);
    
    useEffect(() => {
        
        let usersnapApi = null;
                
        (async function() {
            
            const userMeta = await getUserMeta();
            
            global.config._userMeta = {
                _messages: get(userMeta, '_messages', null),
                units: {
                    weight: get(userMeta, 'units.weight', 'metric'),
                    distance: get(userMeta, 'units.distance', 'meters')
                }
            };
            setUserMeta(userMeta);
            
            if(window._honeybadger && global._currentUser) {
                                
                window._honeybadger.setContext({
                  user_id: global._currentUser.uid,
                  user_email: global._currentUser.email
                });
                
                window.onUsersnapCXLoad = async (api) => {
                    
                    api.init({
                        user: {
                            userId: global._currentUser.uid,
                            email: global._currentUser.email
                        },
                        useSystemFonts: true
                    });
                    
                    usersnapApi = api;
                    global.config._usersnapApi = api;                
                    
                };
                
                const script = document.createElement('script');
                script.defer = 1;
                script.src = `https://widget.usersnap.com/global/load/${global.config.app.usersnap_api_key}?onload=onUsersnapCXLoad`;
                document.head.appendChild(script);
                
            }
            
        })();
        
        return () => {
            if (usersnapApi) {
                usersnapApi.destroy();
            }
        }
                 
    }, []);
    
    const sendVerificationEmail = () => {
        
        sendUserEmailVerification();
        f7router.app.toast.create({text: 'Verification Email Sent!'}).open();
        
    }
        
    return (
        <Page pageContent={false}>
            {!get(global, '_currentUser.emailVerified', null) &&
                <div className="notice notice-error"><strong>You need to verify your email first in order to create content. Please check your mail box for the verification email.<br /><a href="#" onClick={sendVerificationEmail}>Resend Verification Email</a></strong></div>
                
            }
            <Tabs routable animated={false} id="main-tabs">
                <Tab id="planner"></Tab>
                <Tab id="library"></Tab>
                <Tab id="account"></Tab>
                <Tab id="account-personal" className="account-settings"></Tab>
                <Tab id="account-password" className="account-settings"></Tab>
                <Tab id="account-units" className="account-settings"></Tab>
                <Tab id="account-info" className="account-settings"></Tab>
            </Tabs>          
            <Toolbar tabbar bottom id="main-toolbar">
                <Logo id="logo" />
                <Link 
                  iconMaterial="edit_calendar"
                  tabLink
                  iconOnly
                  routeTabId="planner"
                  href="/athlete/planner/"
                  text="Planner"
                />
                <Link 
                  iconMaterial="library_books" 
                  tabLink
                  iconOnly
                  routeTabId="library"
                  href="/athlete/library/"
                  text="Library"
                />
                <Link 
                  iconMaterial="person" 
                  iconOnly
                  panelOpen="#account-menu-panel"
                  routeTabId="account"
                  preventRouter
                  text="Account"
                />
            </Toolbar> 
            <AccountMenuPanel
                f7router={f7router}
            />
            <MovementPopup />
            {userMeta && 
                <TempPopup id="welcome-feedback" btnText="Got It">
                    <h3>Welcome to XESH</h3>
                    <p>This is just the beginning and in order to make the app better, we really appreciate your feedback. Just click the feedback button at the right side.</p>
                </TempPopup> 
            }           
        </Page>
    )
}

export default AthleteLayout;