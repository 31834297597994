import React, { useState } from 'react';
import {
    LoginScreenTitle,
    List,
    ListInput,
    BlockFooter,
    ListItem,
    Button,
    LoginScreen,
} from 'framework7-react';

const SignUp = (props) => {
        
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const formSubmit = (evt) => {        
        evt.preventDefault();
        props.signHandler(email, password);
    }
    
    return (
        <LoginScreen
            className="signin-screen"
            opened
        >
            <LoginScreenTitle>
                Create an account
            </LoginScreenTitle>
            <List form onSubmit={formSubmit}>
                <ListInput
                    label="Email"
                    type="email"
                    placeholder="example@mail.com"
                    value={email}
                    onInput={(e) => {
                        setEmail(e.target.value);
                    }}
                />
                <ListInput
                    label="Password"
                    type="password"
                    placeholder="6+ strong character"
                    value={password}
                    onInput={(e) => {
                        setPassword(e.target.value);
                    }}
                />
                <ListItem>
                    <Button
                        text="Sign Up"
                        fill 
                        type="submit" 
                        className="button-gradient"
                    />
                </ListItem>
                <ListItem>
                    By clicking Sign Up you are agreeing to the <a href="https://xesh.fit/privacy-policy/" target="_blank" className="external">Privacy Policy</a> and <a href="https://xesh.fit/terms-of-use/" target="_blank" className="external">Terms of Use</a>.
                </ListItem>
            </List>
                
            <BlockFooter>
                Do you already have an account? <a href="/signin">Sign In</a>
            </BlockFooter>
        </LoginScreen>
    )
       
}

export default SignUp;