import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
    List,
    ListItem,
    BlockHeader,
    SwipeoutActions,
    SwipeoutButton
} from 'framework7-react';
import Score from './Score';
import InfoIcon from 'ui/comps/global/InfoIcon';
import { map, findIndex } from 'lodash';
import { deleteWorkoutResult } from 'fb-api/workout-results';

const ScoreList = (props) => {
    
    const [data, setData] = useState([]);
    
    useEffect(() => {
        
        setData(props.data);
         
    }, [props.data]);
    
    const noteElem = (noteStr) => {
        
        noteStr = noteStr.replace(/(?:\r\n|\r|\n)/g, '<br>');
        
        return (
            <span dangerouslySetInnerHTML={{ __html: noteStr }}></span>
        ) 
        
    }
    
    const deleteScore = (scoreId, index) => {
        
        window.f7App.dialog.confirm(
            `Are you sure you want to delete this score?`,
            '',
            () => {
                props.scoreDelete(scoreId, index)
            }
        )
        
    }
    
    return (
        <div className="score-list-wrapper" id={props.id}>
            {props.caption &&
                <div className="caption">
                    <BlockHeader className="no-padding">{props.caption}</BlockHeader>
                    {props.subCaption && 
                        <InfoIcon id={props.id+'-help'}>
                            {props.subCaption}
                        </InfoIcon>
                    }
                </div>
            }
            <List mediaList className="history-list">
                {map(data, (entry, i) => {
                    
                    return (
                        <ListItem
                           title={entry.date}
                           after=<Score value={entry.score} workoutType={props.workoutType} />
                           text={noteElem(entry.note)}
                           swipeout
                           key={entry.id}
                       >
                           <SwipeoutActions right>
                               <SwipeoutButton color="red" onClick={() => deleteScore(entry.id, i)}>
                                   Delete
                               </SwipeoutButton>
                           </SwipeoutActions>
                       </ListItem>
                    )
                   
                })}
            </List>
        </div>
    )
    
}

ScoreList.propTypes = {
    id: PropTypes.string,
    data: PropTypes.array,
    caption: PropTypes.string,
    workoutType: PropTypes.string,
}

ScoreList.defaultProps = {
    id: '',
    data: [],
    caption: '',
    subCaption: ''
}

export default ScoreList;

// onClick={() => deleteEntry(entry.id)}