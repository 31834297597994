import {firebaseApp, auth} from './init';
import {
    getFirestore,
    collection,
    doc,
    addDoc,
    getDocs,
    query,
    where,
    getDoc,
    setDoc,
    deleteDoc,
    serverTimestamp,
    orderBy
} from 'firebase/firestore';
import sanitizeHtml from 'sanitize-html';
import { get } from 'lodash';

const db = getFirestore(firebaseApp);
const movementsCol = collection(db, 'movements');

export const writeMovement = (payload) => {
    
    try {
        
        let data = payload.data;
        
        if(data.description) {
            data.description = sanitizeHtml(data.description, {
                allowedTags: ['div', 'p', 'br', 'b', 'i', 'h1', 'h2', 'h3', 'li', 'ol', 'a', 'img', 'u']
            });
        }
         
        //update  
        if(payload.mov_id) {
            return setDoc(doc(db, movementsCol.path, payload.mov_id), data, {merge: true});
        }
        //create
        else {
            data.user_id = auth.currentUser.uid;
            data.created_at = serverTimestamp();
            return addDoc(movementsCol, data);
        }
    }
    catch(err) {
        console.error(err.message);
    }
     
};

export const readMovements = (opts={}) => {
    
    const q = query(
        movementsCol, 
        where("user_id", "==",  auth.currentUser.uid) , 
        orderBy("name")
    );
    
    return getDocs(q);
    
};

export const readMovement = (id) => {
      
    const docRef = doc(db, movementsCol.path, id);
    return getDoc(docRef);  
    
};

export const deleteSingleMovement = (id) => {
    
    return deleteDoc( doc(db, movementsCol.path, id) );

};