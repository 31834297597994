import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { 
    Popup,
    Button,
    Block,
    Page
} from 'framework7-react';
import { get } from 'lodash';
import { 
    updateUserMeta
} from 'fb-api/users';

function TempPopup(props) {
    
    useEffect(() => {
                
        if(!get(global.config, '_userMeta._messages['+props.id+']', false)) {
            window.f7App.popup.open('#welcome-feedback')
        }

    }, [])
    
    const popupClosed = () => {
        
        updateUserMeta({
            _messages: {
                [props.id]: true
            }
        });
        
    }
    
    return (
        <Popup backdrop onPopupClosed={popupClosed} id={props.id}>
            <Page>
            <Block>
                {props.children}
            </Block>
            <Block>
                <Button fill popupClose small className="display-inline-block">
                    {props.btnText}
                </Button>
            </Block>
            </Page> 
        </Popup>
    )
    
}

TempPopup.propTypes = {
    id: PropTypes.string,
    btnText: PropTypes.string
}

export default TempPopup

