import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';
import { secsToTime, intToRoundsReps } from 'misc/helpers.js';

let chartInstance = null;
const TrackingChart = (props) => {
    
    useEffect(() => {
        
        if(!props.id) return;
        
        const chart = document.getElementById(props.id);          
        const options = {
            responsive: true,
            maintainAspectRatio: true,
            animation: {
                easing: 'easeInOutQuad',
                duration: 520
            },
            scales: {
                x: {
                    grid: {
                        borderColor: '#EBEFF8',
                        display: false
                    },
                    ticks: {
                        color: '#8B93A4',
                        align: 'end'
                    }
                },
                y: {
                    grid: {
                        borderColor: '#EBEFF8',
                        display: false
                    },
                    ticks: {
                        color: '#8B93A4',
                        callback: yTick
                    }
                }
            },
            elements: {
                line: {
                    tension: 0.4
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    xAlign: 'center',
                    yAlign: 'bottom',
                    position: 'nearest',
                    displayColors: false,
                    backgroundColor: 'rgba(235, 239, 248, 1)',
                    titleColor: 'rgba(139, 147, 164, 1)',
                    titleFont: {
                        family: 'Poppins',
                        style: 'normal',
                        size: 12,
                        weight: 'normal'
                    },
                    titleAlign: 'center',
                    bodyColor: 'rgba(25, 41, 75, 1)',
                    bodyFont: {
                        family: 'Poppins',
                        style: 'normal',
                        size: 16,
                        weight: 'bold'
                    },
                    bodyAlign: 'center',
                    cornerRadius: 4,
                    padding: {
                        left: 10,
                        right: 10,
                        top: 5,
                        bottom: 5
                    },
                    caretSize: 0,
                    callbacks: {
                        label: tooltipLabel
                    }
                }
            },
            point: {
                backgroundColor: 'white'
            },
        };
    
        chartInstance = new Chart(chart, {
            type: 'line',
            data: [],
            options: options
        });
            
         
    }, []);
    
    useEffect(() => {
                
        const gradient = chartInstance.canvas.getContext('2d').createLinearGradient(0, 0, 0, 320);    
        gradient.addColorStop(0, "rgba(46, 204, 113, 0.8)");
        gradient.addColorStop(1, "rgba(255, 255, 255, 0.1)");
        
        chartInstance.data.datasets = [{
            label: '',
            backgroundColor: gradient,
            fill: true,
            pointBackgroundColor: '#2ECC71',
            borderWidth: 2,
            borderColor: '#2ECC71',
            data: props.data
        }];
                
        chartInstance.update();
        
    }, [props.data]);
    
    const yTick = (value, index, ticks) => {
        
        if(props.yType === 'int') {
            value = Math.floor(value);
        }
        if(props.yType === 'decimal') {
            value = Number(value).toFixed(2);
        }
        else if(props.yType === 'time') {
            
            const timeObj = secsToTime(value);
            value = timeObj.mins + ':' + timeObj.secs;
        }
        
        return value;
        
    }
    
    const tooltipLabel = (context) => {
        
        const label = context.dataset.label || '';
        
        if(props.yType === 'time') {
            
            const timeObj = secsToTime(parseInt(context.parsed.y));   
            return label + '\n' + timeObj.mins + ':' + timeObj.secs;
        }
        
        return label + '\n' + context.parsed.y;  
        
    }
    
    return (
        
        <div className="chartjs-wrapper">
            <canvas id={props.id}></canvas>
        </div>
        
    )
    
}


TrackingChart.propTypes = {
    yType: PropTypes.string
}

TrackingChart.defaultProps = {
    yType: 'int'
}

export default TrackingChart;