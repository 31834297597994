import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    addMovement, 
    getMovements, 
    getMovementDetails, 
    deleteMovement
} from 'redux-store/slices/movements';
import { 
    Popup, 
    Page, 
    Block, 
    BlockTitle, 
    Row, 
    Col, 
    Button,
    BlockHeader,
    Input, 
    TextEditor,
    Toolbar,
    Tabs,
    Tab, 
    Link,
    Sheet,
    PageContent
} from 'framework7-react';
import isUrl from 'is-url';
import $ from 'jquery'; 
import { sanitizeDesc } from 'misc/helpers.js';
import { isEmpty, get, map } from 'lodash';
import logParameters from 'json/log-parameters.json';

import EmbeddedVideo from 'ui/comps/global/EmbeddedVideo';
import ModalActions from 'ui/comps/global/ModalActions';
import MovementTracking from './MovementTracking';

const MovementPopup = (props) =>  {
    
    const dispatch = useDispatch();
    
    const loadingMov = useSelector((state) => state.movements.loading);
    const singleMovement = useSelector((state) => state.movements.single);
    const allMovements = useSelector((state) => state.movements.all);
    
    const [id, setId] = useState(null);
    const [writeMode, enableWriteMode] = useState(false);
    const [movementDetails, setMovementDetails] = useState({});
    const [name, setName] = useState('');
    const [logParameter, setLog] = useState('');
    const [desc, setDesc] = useState('');
    const [videoUrls, setVideoUrls] = useState([]);
    
    useEffect(() => {
        
        setMovementDetails(singleMovement ? singleMovement : {});
        
    }, [singleMovement]);
    
    useEffect(() => {
        
        setName(get(movementDetails, 'name', ''));
        setLog(get(movementDetails, 'log_parameter', ''));
        setDesc(get(movementDetails, 'description', ''));
        setVideoUrls(get(movementDetails, 'video_urls', []));
        setId(get(movementDetails, 'id', null));
                
    }, [movementDetails]);
    
    useEffect(() => {
        
        if(name.length > global.config.app.movement_name_max_length) {
            setName(name.slice(0, global.config.app.movement_name_max_length));
        }
                
    }, [name]);
    
    const popupOpen = async () => {
        
        const movId = $('#movement-popup').data('movid');
        
        const $toolbar = $('#movement-popup .toolbar');
        $toolbar.find('.tab-link').removeClass('tab-link-active').first().addClass('tab-link-active'); 
        $toolbar.next('.tabs').children('.tab').removeClass('tab-active').first().addClass('tab-active'); 
        
        if(!isEmpty(movId)) {
            dispatch( getMovementDetails({id: movId}) );     
        }
        else {
            enableWriteMode(true);
            setId(null);
        }
                
    }
    
    const popupClosed = () => {
        
        $('#movement-popup').data('movid', null);
        setId(null);
        setMovementDetails({});
        enableWriteMode(false);
        
    }
    
    const writeMov = () => {
                                                        
        let movdata = {
            name: name,
            log_parameter: logParameter ? logParameter : 'reps'
        }
        
        //name
        if(movdata.name.length < 3 ) {
            window.f7App.toast.create(
                {text: 'The name must contain at least 3 characters!'}
            ).open();
            return;
        }
        
        //description
        movdata.description = desc;
        if(movdata.description.length > global.config.app.workout_mov_desc_max_length ) {
            
            window.f7App.toast.create(
                {text: `Only maximum ${global.config.app.workout_desc_max_length} characters are allowed for the description!`}
            ).open();
            return;
            
        }
        
        //video urls
        movdata.video_urls = videoUrls;
        
        if(allMovements.length >= global.config.app.max_movements_per_user) {
            window.f7App.toast.create(
                {text: `Right now only ${global.config.app.max_movements_per_user} movements per user are allowed!`}
            ).open();
            return;
        }
        
        dispatch( addMovement({data: {...movdata}, mov_id: id}) );        
        window.f7App.popup.get('#movement-popup').close();
        
    }
    
    const deleteMov = () => {
        
        window.f7App.dialog.confirm(
            'Are you sure to delete this movement?',
            '',
            () => {
                dispatch( deleteMovement({id: id}) );
                window.f7App.popup.get('#movement-popup').close();
            }
        )
        
    }
    
    const addVideo = () => {
        
        let $videoUrlInput = $('input[name="video_url"]');
                
        if(!isUrl($videoUrlInput.val())) {
            
            window.f7App.toast.create(
                {text: 'The entered video URL is not a valid URL!'}
            ).open();
            return;
            
        }
        
        let _videoUrls = [...videoUrls];
        
        if(_videoUrls.length < global.config.app.max_movement_videos) {
            
            _videoUrls.push($videoUrlInput.val());
            setVideoUrls(_videoUrls);
            
        }
        else {
           
           window.f7App.toast.create(
               {text: `Only ${global.config.app.max_movement_videos} videos per movement are allowed!`}
           ).open();
            
        }
        
        $videoUrlInput.val('');
        
    }
    
    const removeVideo = (evt) => {
        
        let _videoUrls = [...videoUrls];
        _videoUrls.splice(evt.currentTarget.dataset.index, 1);
        setVideoUrls(_videoUrls);
        
    }
    
    const renderWriteVideos = () => {
                
        return (
            <Block>
                <BlockTitle medium>Video(s)</BlockTitle>
                <Row>
                    <div className="input-action">
                        <Input 
                            type="text" 
                            placeholder="Enter a video link from YouTube or Vimeo ."
                            name="video_url"
                        />
                        <Button 
                            iconMaterial="add"
                            fill
                            small
                            onClick={addVideo}
                        />
                    </div>
                </Row>
                <Row>
                    <div className="margin-top horizontal-scroll">
                        {map(videoUrls, (videoUrl, i) => {
                                                        
                            return (
                                <div key={videoUrl+i}>
                                    <EmbeddedVideo url={videoUrl} />
                                    <Button 
                                        text="Delete"
                                        color="red"
                                        small
                                        onClick={removeVideo}
                                        className="no-padding"
                                        data-index={i}
                                    />
                                </div>
                            )
                            
                        })}
                    </div>
                </Row>
            </Block>
        )
        
    }
    
    const renderWrite = () => {
        
        return (
            <Page>
                <Block>
                    <Row>
                        <Col width="80">
                            <BlockTitle 
                                large
                                className="no-margin"
                            >
                                {movementDetails.name ? 'Edit' : 'Add'} Movement
                            </BlockTitle>
                        </Col>
                        <ModalActions 
                            id = "modal-actions-workout" 
                        />
                    </Row>
                </Block>
                {!movementDetails.name &&
                    <Block>
                        <BlockTitle medium>Log Parameter</BlockTitle>
                        <Input 
                            outline 
                            type="select" 
                            name="log_parameter" 
                            value={logParameter}
                            onChange={(evt) => setLog(evt.target.value)}
                        >
                            {map(logParameters, (lp, i) => {
                                return (
                                    <option value={i} key={i}>{lp}</option>
                                )
                            })}
                        </Input>
                        <i className="info">What would you like to log for this movement? Can not be changed later.</i>
                    </Block>
                }
                <Block>
                    <BlockTitle medium>Name</BlockTitle>
                    <Input 
                        type="text" 
                        placeholder="Enter a movement name"
                        name="name"
                        autocapitalize="words"
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                        data-count={name.length+`/${global.config.app.movement_name_max_length}`}
                    />
                </Block>
                <Block>
                    <BlockTitle medium>Description</BlockTitle>
                    <TextEditor
                        id="mov-desc"
                        className="no-margin"
                        value={desc}
                        placeholder="Describe the movement"
                        buttons={[
                            ['bold', 'italic', 'underline'],
                            ['orderedList', 'unorderedList'],
                            ['link', 'image'],
                        ]}
                        onTextEditorChange={(value) => setDesc(value)}
                        data-count={desc.length+`/${global.config.app.workout_mov_desc_max_length}`}
                     />
                </Block>
                {renderWriteVideos()}
                <Block>
                    <Row>
                        <Col width="100">
                            <Button 
                                text="Save"
                                fill
                                medium
                                onClick={writeMov}
                            />
                        </Col>
                    </Row>
                </Block>
            </Page>    
        )
        
    }
    
    const renderViewVideos = () => {
        
        return (
            <Block>
                <div className="margin-top horizontal-scroll">
                    {map(videoUrls, (videoUrl, i) => {
                                                    
                        return (
                            <div key={videoUrl+i}>
                                <EmbeddedVideo url={videoUrl} />
                            </div>
                        )
                        
                    })}
                </div>
            </Block>
        )
        
    }
    
    const renderView = () => {
        
        return (
            <Page>
            
                <Block>
                    <Row>
                        <Col width="80">
                            <BlockTitle large>
                                {movementDetails.name}
                            </BlockTitle>
                            <BlockHeader>{logParameters[movementDetails.log_parameter]}</BlockHeader>
                        </Col>
                        {!isEmpty(movementDetails) &&
                            <ModalActions 
                                id = "modal-actions-workout" 
                                onEdit={() => enableWriteMode(true)} 
                                onDelete={deleteMov} 
                            />
                        }
                    </Row>
                </Block>
                
                <div>
                    <Toolbar tabbar scrollable className="tabular" id="movement-toolbar">
                        <Link 
                            text="Tracking"
                            tabLink="#movement-tracking"
                            tabLinkActive
                        />
                        <Link 
                            text="Details"
                            tabLink="#movement-details"
                        />
                    </Toolbar>
                    
                    <Tabs className="margin">
                    
                        <Tab id="movement-tracking" tabActive>
                            <MovementTracking
                                movId = {id}
                                logParameter = {logParameter}
                            />
                        </Tab>
                        
                        <Tab id="movement-details">
                            {!isEmpty(get(movementDetails, 'description', '').replace(/<\/?[^>]+(>|$)/g, "")) &&
                                <Block inset className="description no-padding">
                                    <p dangerouslySetInnerHTML={{__html: sanitizeDesc(movementDetails.description)}}></p>
                                </Block> 
                            }
                            {!isEmpty(videoUrls) &&
                                renderViewVideos()
                            }
                            {!loadingMov && isEmpty(movementDetails) &&
                                <Block>
                                    <div className="notice notice-error">
                                        Movement details could not be loaded. Either you have a connection issue or you deleted the movement in the past.
                                    </div>
                                </Block>
                            }
                        </Tab>
                        
                    </Tabs>
                </div>
                
            </Page>
        )
    }
    
    return (
        
        <Popup
            id="movement-popup"
            data-movid=""
            onPopupOpen={popupOpen}
            onPopupClosed={popupClosed}
        >
            {writeMode ? renderWrite() : renderView()} 
        </Popup>
        
    )
    
}

export default MovementPopup;