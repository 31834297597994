import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { 
    Popup, 
    Page, 
    Block, 
    BlockTitle, 
    Row, 
    Col, 
    Button, 
    Input, 
    TextEditor,
    Link
} from 'framework7-react';
import { get, cloneDeep, map, isEmpty } from 'lodash';
import isUrl from 'is-url';
import $ from 'jquery'; 
import workoutTypes from 'json/workout-types.json';

import TimeInput from 'ui/comps/global/TimeInput';
import SetsBuilder from 'ui/comps/workout/SetsBuilder';
import InfoIcon from 'ui/comps/global/InfoIcon';
import ModalActions from 'ui/comps/global/ModalActions';
import EmbeddedVideo from 'ui/comps/global/EmbeddedVideo';

 const WriteWorkoutPopup = (props) =>  {
            
    const [name, setName] = useState('');
    const [time, setTime] = useState(0);
    const [videoUrls, setVideoUrls] = useState([]);
    const [desc, setDesc] = useState('');
    
    //sets
    const setsConfigs = useRef(null);
    const setsData = useRef(null);
    
    //amrap
    const [amountRepsPerRound, setAmountRepsPerRound] = useState(0);
    
    useEffect(() => {
                        
        //show first tab when popup opens
        $('body').on('popup:opened', '#add-edit-workout-popup',  () => {
            window.f7App.tab.show($('#add-edit-workout-popup .tabs .tab:first').get(0));
        });
                
    }, []);
    
    //reset when props are changing
    useEffect(() => {
                   
        setName(get(props, 'name', ''));
        setTime(get(props, 'time', 0));
        setVideoUrls(get(props, 'video_urls', []));
        
        if(window.f7App.textEditor.get('#workout-desc')) {
            setDesc(get(props, 'description', ''));
            window.f7App.textEditor.get('#workout-desc').setValue(get(props, 'description', ''));
        }
            
        setsConfigs.current = get(props, 'configs', {equal_sets: true, number_of_sets: 1});
        setsData.current = cloneDeep(get(props, 'setsData', [[]]));
        
        setAmountRepsPerRound(get(props, 'reps_per_round', 0));
                        
    }, [props]);
    
    //process name input
    useEffect(() => {
        
        if(name.length > global.config.app.workout_name_max_length) {
            setName(name.slice(0, global.config.app.workout_name_max_length));
        }
                
    }, [name]);
    
    //save workout
    const save = () => {

        let data = {
            type: props.type,
            name: name,
        }
        
        //name
        if(data.name.length < 2 ) {
            window.f7App.toast.create(
                {text: 'The name must contain at least 2 characters!'}
            ).open();
            return;
        }
        
        //time | time cap ( EMOM, AMRAP, FOR TIME)
        if (props.type !== 'sets') {
            data.time = time;
        }
        
        //description
        if(window.f7App.textEditor.get('#workout-desc')) {
            
            data.description = window.f7App.textEditor.get('#workout-desc').getValue();
            if(data.description.length > global.config.app.workout_mov_desc_max_length ) {
                
                window.f7App.toast.create(
                    {text: `Only maximum ${global.config.app.workout_mov_desc_max_length} characters are allowed for the description!`}
                ).open();
                return;
                
            }
            
        }
        
        //video urls
        data.video_urls = videoUrls;
        
        
        //sets
        if(props.type === 'sets' && setsData.current) {
            data.configs = setsConfigs.current;
            data.setsData = setsData.current;
        }
        
        //amrap
        if (props.type === 'amrap') {
            
            if(parseInt(amountRepsPerRound | 0) <= 0) {
                window.f7App.toast.create(
                    {text: 'Please enter the reps per round!'}
                ).open();
                return;
            }
            
            data.reps_per_round = parseInt(amountRepsPerRound);
            
        }
        
        props.add(data); 
        
    }
    
    const setSetsData = (data) => {
        
        setsData.current = data;
        
    }
    
    const setsConfigsChange = (configs) => {
    
        setsConfigs.current = configs;
        
    }
    
    const focusIn = (evt) => {
        
        if(evt.target.value == 0) {
            
            setAmountRepsPerRound('');
                
        }
        
    }
    
    const focusOut = (evt) => {
        
        if(evt.target.value == '') {
            
            setAmountRepsPerRound(0);
                
        }
        
    }
    
    const addVideo = () => {
        
        let $videoUrlInput = $('input[name="video_url"]');
                
        if(!isUrl($videoUrlInput.val())) {
            
            window.f7App.toast.create(
                {text: 'The entered video URL is not a valid URL!'}
            ).open();
            return;
            
        }
        
        let _videoUrls = [...videoUrls];
        
        if(_videoUrls.length < global.config.app.max_movement_videos) {
            
            _videoUrls.push($videoUrlInput.val());
            setVideoUrls(_videoUrls);
            
        }
        else {
           
           window.f7App.toast.create(
               {text: `Only ${global.config.app.max_movement_videos} videos per movement are allowed!`}
           ).open();
            
        }
        
        $videoUrlInput.val('');
        
    }
    
    const removeVideo = (evt) => {
        
        let _videoUrls = [...videoUrls];
        _videoUrls.splice(evt.currentTarget.dataset.index, 1);
        setVideoUrls(_videoUrls);
        
    }
    
    const renderVideos = () => {
                
        return (
            <Block>
                <BlockTitle medium>Video(s)</BlockTitle>
                <Row>
                    <div className="input-action">
                        <Input 
                            type="text" 
                            placeholder="Enter a video link from YouTube or Vimeo ."
                            name="video_url"
                        />
                        <Button 
                            iconMaterial="add"
                            fill
                            small
                            onClick={addVideo}
                        />
                    </div>
                </Row>
                <Row>
                    <div className="margin-top horizontal-scroll">
                        {map(videoUrls, (videoUrl, i) => {
                                                        
                            return (
                                <div key={videoUrl+i}>
                                    <EmbeddedVideo url={videoUrl} />
                                    <Button 
                                        text="Delete"
                                        color="red"
                                        small
                                        onClick={removeVideo}
                                        className="no-padding"
                                        data-index={i}
                                    />
                                </div>
                            )
                            
                        })}
                    </div>
                </Row>
            </Block>
        )
        
    }
    
    const renderTypeRelated = () => {
                
        if(props.type !== 'sets') {
            
            let timeLabel = ['for_time', 'amrap'].includes(props.type)  ? 'Time Cap' : 'Time';
            
            return (
                <React.Fragment>
                    <Block>
                        <Row>
                            <Col>
                                <BlockTitle medium className="no-margin">{timeLabel}</BlockTitle>
                                <TimeInput 
                                    value={time}
                                    onChange={setTime}
                                />
                            </Col>
                            {props.type === 'amrap' &&
                                <Col className="text-align-right">
                                    <BlockTitle medium className="no-margin">
                                        Reps per round
                                        <InfoIcon id="info-amrap-rpr">
                                            <div>
                                            For example your workout is:<br />
                                            60 cal row<br />
                                            25 Burpess<br />
                                            15 Snatch<br />
                                            Reps per round: 100
                                            </div>
                                        </InfoIcon>
                                    </BlockTitle>
                                    <Input 
                                        type="number" 
                                        className="float-right"
                                        value={amountRepsPerRound}
                                        onFocus={focusIn}
                                        onBlur={focusOut}
                                        onChange={(evt) => 
                                            setAmountRepsPerRound(evt.target.value)
                                        }
                                    />
                                </Col>
                            }
                            
                        </Row>
                    </Block>
                    <Block>
                        <BlockTitle medium className="no-margin">Description</BlockTitle>
                        <TextEditor
                            id="workout-desc"
                            className="no-margin"
                            value={get(props, 'description', '')}
                            placeholder="Describe the workout."
                            buttons={[
                                ['bold', 'italic', 'underline'],
                                ['orderedList', 'unorderedList'],
                                ['link', 'image'],
                            ]}
                            onTextEditorChange={(value) => setDesc(value)}
                            data-count={desc.length+`/${global.config.app.workout_mov_desc_max_length}`}
                         />
                    </Block>
                    {renderVideos()}
                </React.Fragment>
            )
            
        }
        else if (props.type === 'sets') {
                        
            return (
                <SetsBuilder
                    configs={setsConfigs.current}
                    setsData={setsData.current}
                    onConfigsChange={setsConfigsChange}
                    onDataset={setSetsData}
                />
            )
         
        }
        
        return null;
            
    }
    
    return (
        
        <Popup
            id="add-edit-workout-popup"
            data-type={props.type}
        >
            <Page>
                <Block>
                    <Row>
                        <Col width="80">
                            <BlockTitle 
                                large
                                className="no-margin"
                            >
                                {workoutTypes[props.type].name}
                            </BlockTitle>
                        </Col>
                        <ModalActions 
                            id = "modal-actions-write-workout" 
                        />
                    </Row>
                </Block>
                <Block>
                    <BlockTitle medium>Name</BlockTitle>
                    <Input 
                        type="text" 
                        placeholder="Enter workout name"
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                        autocapitalize="words"
                        data-count={name.length+`/${global.config.app.workout_name_max_length}`}
                    />
                </Block>
                {renderTypeRelated()}
                <Block>
                    <Row>
                        <Col width="100">
                            <Button 
                                text="Save"
                                fill
                                medium
                                onClick={save}
                            />
                        </Col>
                    </Row>
                </Block>
            </Page>
        </Popup>
        
    )
    
}

WriteWorkoutPopup.propTypes = {
    type: PropTypes.string,
}

WriteWorkoutPopup.defaultProps = {
    type: 'sets' //view, execute
}

export default WriteWorkoutPopup;