import React, { useState, useEffect } from 'react';
import {
   Block,
   Row,
   Col,
   List,
   ListItem,
   Toolbar,
   Tabs, 
   Tab,
   Link,
   Stepper,
   Toggle,
   Button,
   Icon
} from 'framework7-react';
import { map, times, isInteger, values, now, get } from 'lodash';
import TimeInput from 'ui/comps/global/TimeInput';
import MovementsPopup from 'ui/comps/movement/MovementsPopup.js';
import MovementInput from 'ui/comps/workout/MovementInput.js';
import InfoIcon from 'ui/comps/global/InfoIcon';
import $ from 'jquery'; 

const SetsBuilder = (props) => {
            
    const [numberOfSets, setNumberOfSets] = useState(1);
    const [equalSets, setEqualSets] = useState(true);
    const [setsData, setSetsData] = useState([[]]);
    const [currentIndex, setCurrentIndex] = useState(0);
        
    useEffect(() => {
                
        if(props.configs) {
            setNumberOfSets(get(props, 'configs.number_of_sets', 1));
            setEqualSets(get(props, 'configs.equal_sets', true))
        }
        
        if(props.setsData) {
            //firebase converts indexed array to map, convert to array
            setSetsData(props.setsData instanceof Array ? props.setsData : values(props.setsData));
        }
        
        
    }, [props.setsData, props.configs]);
    
    useEffect(() => {
        
        props.onDataset(setsData); 
              
    }, [setsData]);
    
    useEffect(() => {
            
        props.onConfigsChange({
            number_of_sets: numberOfSets, 
            equal_sets: equalSets
        });
              
    }, [numberOfSets, equalSets]);
    
    useEffect(() => {
        
        let oldData = [...setsData],
            newData = [];
        
        times(!equalSets ? numberOfSets : 1, (i) => {
                        
            if(oldData[i])
                newData[i] = oldData[i];
            else
                newData[i] = [];
                            
        })
        
        setSetsData(newData);  
               
    }, [numberOfSets, equalSets]);
    
    const addMovToSet = () => {
            
        window.f7App.popup.get('#movements-popup').open();
        
    }
    
    const movementSelect = (movData) => {
        
        let data = [...setsData];
        data[currentIndex].push({movement: movData, rest_time: 0});
        setSetsData(data);
        
    }
    
    const movementRestTimeSet = (movIndex, restTime) => {
        
        let data = [...setsData];
        data[currentIndex][movIndex]['rest_time'] = restTime;
        setSetsData(data);
        
    }
    
    const movementLogSet = (movIndex, log, value) => {
        
        let data = [...setsData];
        data[currentIndex][movIndex][log] = value;
        setSetsData(data);
        
    }
    
    const removeMovement= (index) => {
        
        let data = [...setsData];
        data[currentIndex].splice(index, 1); 
        setSetsData(data);
        
    }
    
    const duplicateMovement = (index) => {
        
        let data = [...setsData],
            cloneItem = {...data[currentIndex][index]};
                    
        data[currentIndex].splice(index+1, 0, cloneItem);
        setSetsData(data);
        
    } 
    
    const movementsSorted = ({from, to}) => {
        
        let data = [...setsData];
        data[currentIndex] = swapArrayLocs(data[currentIndex], from, to);
        setSetsData(data);
        
    }
    
    const swapArrayLocs = (arr, from, to) => {
        
        arr.splice(to, 0, arr.splice(from, 1)[0]);
        return arr;
        
    }
    
    const renderSet = (index=0) => {
        
        let setData = setsData[index] ? setsData[index] : {};
        
        return (
            <React.Fragment>
                <Block className="no-padding">
                    <Row>
                        <Col>
                            <Button
                                text="Add Movement"
                                outline
                                small
                                className="display-inline-block"
                                onClick={addMovToSet}
                            />
                        </Col>
                        <Col className="text-align-right">
                            <Button
                                iconMaterial="sort"
                                small
                                className="display-inline-block"
                                sortableToggle="#add-edit-workout-popup .movements-list"
                            />
                        </Col>
                    </Row>
                </Block>
                <List sortable className="movements-list" onSortableSort={movementsSorted}>
                    <ul>
                        {map(setData ? setData : [], (data, i) => {
                            return (
                               <MovementInput
                                    {...data}
                                    index={i}
                                    key={data.movement.id+'_'+i}
                                    logSet={movementLogSet}
                                    restTimeSet={movementRestTimeSet}  
                                    remove={removeMovement} 
                                    duplicate={duplicateMovement}     
                               />
                            )
                            
                        })}
                    </ul>
                </List>
            </React.Fragment>
        )
        
    }
    
    return (
        <React.Fragment>
        
            <MovementsPopup
                onSelect={movementSelect}
            />
            
            <Toolbar tabbar className="tabular">
                <Link 
                    text="Configurations"
                    tabLink="#configs"
                    tabLinkActive
                />
                <Link 
                    text="Build Sets"
                    tabLink="#builder"
                />
            </Toolbar>
            <Block>
                
                <Tabs>
                    <Tab id="configs" tabActive>
                        <List className="builder-configs" form>
                            <ListItem>
                                <Row>
                                    <Col width="50">Number of sets</Col>
                                    <Col width="50" className="text-align-right">
                                        <Stepper
                                            type="number" 
                                            min={1}
                                            max={50}
                                            value={numberOfSets}
                                            onStepperChange={setNumberOfSets}
                                        />
                                    </Col>
                                </Row>
                            </ListItem>
                            <ListItem>
                                <Row>
                                    <Col width="50">
                                        Equal sets
                                        <InfoIcon id="info-equal-sets">
                                            Make movements and reps equal across all sets. 
                                        </InfoIcon>
                                    </Col>
                                    <Col width="50" className="text-align-right">
                                        <Toggle
                                            type="checkbox" 
                                            wrap={false}
                                            checked={equalSets}
                                            onToggleChange={() => setEqualSets(!equalSets)}
                                        />
                                    </Col>
                                </Row>   
                            </ListItem>
                        </List>
                    </Tab>
                    <Tab id="builder">
                        
                        {equalSets && 
                            <div className="sets-builder">
                                {renderSet()}
                            </div>
                        }
                        {!equalSets && 
                            <div className="sets-builder">
                                <Toolbar tabbar scrollable className="secondary">
                                    {times(isInteger(parseInt(numberOfSets)) ? numberOfSets : 1, (i) => {
                                        return (
                                            <Link 
                                                text={"Set "+(i+1)}
                                                tabLink={'#set-'+(i+1)}
                                                tabLinkActive={i===0}
                                                key={i}
                                            />
                                        )
                                    })}
                                </Toolbar>
                                <Tabs>
                                    {times(isInteger(parseInt(numberOfSets)) ? numberOfSets : 1, (i) => {
                                        return (
                                            <Tab 
                                                id={"set-"+(i+1)}
                                                tabActive={i===0}
                                                key={i}
                                                className="page-content"
                                                data-index={i}
                                                onTabShow={(tabEl) => setCurrentIndex(parseInt(tabEl.dataset.index))}
                                            >
                                                {renderSet(i)}
                                            </Tab>
                                        )
                                    })}
                                </Tabs> 
                            </div>
                        }
                        
                    </Tab>
                </Tabs>
            </Block>
            
        </React.Fragment>
        
    ) 
      
}

export default SetsBuilder;