import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { renderTime } from 'misc/helpers.js';
import { get } from 'lodash';

const Score = (props) => {
    
    if(props.workoutType === 'for_time') {
        return renderTime(props.value)
    }
    else if(props.workoutType === 'amrap') {
        return <span className="total-reps">{props.value} reps</span>;
    }
    else if(props.workoutType === 'sets') {
        return(
            <span className="total-load">
                {props.value}
                {get(global, 'config._userMeta.units.weight', 'metric') === 'metric' ? 'kg' : 'lb'}
            </span>   
        )
    }
    else if(props.workoutType === 'emom') {
        return <span>{props.value ? 'RX' : 'Scaled'}</span>
    }
    
    return null;
        
}

Score.propTypes = {
    workoutType: PropTypes.string,
}

Score.defaultProps = {
}

export default Score;