import React from 'react';
import { 
    Link,
    Popover,
    Block
} from 'framework7-react';

const InfoIcon = (props) => {
    
    return (
        <React.Fragment>
            <Link 
                iconMaterial="info_outline" 
                iconOnly 
                popoverOpen={'#'+props.id}
                className="help-popover"
            />
            <Popover id={props.id}>
                <Block>
                    {props.children}
                </Block>
            </Popover>
        </React.Fragment>
    )
    
}

export default InfoIcon;
