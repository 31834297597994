import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import movementsSlice from './slices/movements.js';
import workoutsSlice from './slices/workouts.js';
import sessionsSlice from './slices/sessions.js';

const reduxMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

export const store = configureStore({
	reducer: {
        movements: movementsSlice,
        workouts: workoutsSlice,
        sessions: sessionsSlice,
    },
    middleware: reduxMiddleware,
});