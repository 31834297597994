import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { writeWorkout, readWorkouts, readWorkout, deleteSingleWorkout } from 'fb-api/workouts';
import { findIndex, merge, pick } from 'lodash';

const initialState = {
    loading: false,
    all: [],
    single: null
}

const defaultMultiValues = [
    'id',
    'name',
    'created_at',
    'type',
    'time',
    'description',
    'is_library'
];

const _getMulti = async (payload) => {
    
    try {
        
        let result = [];
        const querySnapshot = await readWorkouts(payload);
        querySnapshot.forEach((doc) => {
            result.push({...pick(doc.data(), defaultMultiValues), id: doc.id});
        });
        
        return result;
        
    }
    catch(err) {
        
        //console.error(err.message);
        return [];
        
    }
    
}

export const addWorkout = createAsyncThunk('workouts/addWorkout', async (payload, { rejectWithValue }) => {
    
    try {
        
        const docRef = await writeWorkout(payload);

        let reducerObj = {...payload.data};
        if(payload.workout_id)
            reducerObj.workout_id = payload.workout_id;
        else 
            reducerObj.id = docRef.id;
            
        return reducerObj;
        
    }
    catch(err) {
        return rejectWithValue(err.message);
    }
   
});

export const getWorkouts = createAsyncThunk('workouts/getWorkouts', async (payload, { rejectWithValue }) => {
    
    return _getMulti(payload);
   
});

export const getWorkoutDetails = createAsyncThunk('workouts/getDetails', async (payload, { rejectWithValue }) => {
    
    try {
        const docSnap = await readWorkout(payload.id)
        return {id: docSnap.id, ...docSnap.data()}; 
    }
    catch(err) {
        return rejectWithValue(err.message);
    }
    
});

export const deleteWorkout = createAsyncThunk('workouts/deleteWorkout', async (payload, { rejectWithValue }) => {
    
    try {
        deleteSingleWorkout(payload.id);
        return payload.id;   
    }
    catch(err) {
        return rejectWithValue(err.message);
    }
       
});

export const workoutsSlice = createSlice({
    name: 'workouts',
    initialState,
    reducers: {
    },
    extraReducers: {
        [addWorkout.pending]: (state, action) => {
            state.loading = 'Saving Workout...';
        },
        [addWorkout.fulfilled]: (state, action) => {
            state.loading = false;
            
            const payload = action.payload;
            let stateAll = [...state.all];
            
            //update
            if(payload.workout_id) {
                
                let docIndex = findIndex(stateAll, {id: payload.workout_id});
                if(docIndex !== -1) {
                    let updateDoc = {...stateAll[docIndex]};
                    stateAll[docIndex] = merge(updateDoc, pick(payload, defaultMultiValues));
                }
                
            }
            //create
            else {
                stateAll.push(pick(payload, defaultMultiValues));
            }
            
            state.all = stateAll;
        },
        [addWorkout.rejected]: (state, action) => {
            state.loading = false;
        },
        [getWorkouts.pending]: (state, action) => {
            state.loading = 'Loading Workouts...';
        },
        [getWorkouts.fulfilled]: (state, action) => {
            state.loading = false;
            state.all = action.payload;
        },
        [getWorkouts.rejected]: (state, action) => {
            state.loading = false;
            state.all = [];
        },
        [getWorkoutDetails.pending]: (state, action) => {
            state.loading = 'Retrieving Workout Details...';
        },
        [getWorkoutDetails.fulfilled]: (state, action) => {
            state.loading = false;
            state.single = action.payload;
        },
        [getWorkoutDetails.rejected]: (state, action) => {
            state.loading = false;
            state.single = null;
        },
        [deleteWorkout.pending]: (state, action) => {
            state.loading = 'Deleting Workout...';
        },
        [deleteWorkout.fulfilled]: (state, action) => {
            state.loading = false;
            
            const docId = action.payload;
            let stateAll = [...state.all];
            
            stateAll.splice(findIndex(stateAll, {id: docId}), 1); 
            state.all = stateAll;
        },
        [deleteWorkout.rejected]: (state, action) => {
            state.loading = false;
        }
    }
});

export default workoutsSlice.reducer;