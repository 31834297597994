import React, { useEffect, useState } from 'react';
import { 
    PageContent, 
    Block,
    List,
    ListInput,
    Button,
    Link,
    Toolbar,
    Tabs,
    Tab
} from 'framework7-react';
import $ from 'jquery'; 
import { isEmpty, get } from 'lodash';

const AccountInfo = ({f7router}) =>  {
    
    useEffect(() => {
        
        //settings does not update main nav active state, do that with jquery 
        $('#main-toolbar .link[data-route-tab-id="account"]').addClass('tab-link-active')
        .siblings().removeClass('tab-link-active');
    
              
    }, []);
    
    return (
        <PageContent>
        
            <Block>
        
                <Toolbar tabbar scrollable className="secondary">
                    <Link 
                        text="Privacy Policy"
                        tabLink="#privacy"
                        tabLinkActive
                    />
                    <Link 
                        text="Terms of Use"
                        tabLink="#terms"
                    />
                </Toolbar>
                <Tabs>
                    
                    <Tab id="privacy" tabActive>
                        <iframe src="https://xesh.fit/privacy-policy/"></iframe>   
                    </Tab>
                    
                    <Tab id="terms">
                        <iframe src="https://xesh.fit/terms-of-use/"></iframe>
                    </Tab>
                    
                </Tabs>
        
            </Block>
            
        </PageContent>
    )
    
}

export default AccountInfo;