import React from 'react'
import PropTypes from 'prop-types';
import $ from 'jquery'; 
import { 
    List,
    ListItem, 
    Icon 
} from 'framework7-react';
import { useHits } from 'react-instantsearch-hooks-web';
import { map, get } from 'lodash';
import workoutTypes from 'json/workout-types.json';
import logParameters from 'json/log-parameters.json';
import { renderTimecap } from 'misc/helpers.js';

function PublicLibraryHits(props) {
    
    const { hits, results, sendEvent } = useHits(props);
    
    
    const renderWorkoutListItemFooter = (entry) => {
                
        const timeEl = renderTimecap(get(entry, 'time'), entry.type);
                              
        return (
            <span>{workoutTypes[entry.type].name} {timeEl ? ' - ' : ''} {timeEl}</span>
        )
        
    }
    
    const addItem = (id) => {
        console.log(id);
    }
    
    return (
        <List>
            <ul>
                {map(hits, (entry, i) => {
                    return (
                        <ListItem 
                            title={entry.name} 
                            footer={entry.type 
                                    ? renderWorkoutListItemFooter(entry) 
                                    : logParameters[entry.log_parameter]
                            }
                            link="#"
                            data-id={entry.objectID}
                            onClick={(evt) => addItem(entry.objectID)}
                            after="+"
                            noChevron
                            key={entry.objectID}
                        >
                            <Icon 
                                slot="media" 
                                material={entry.type ? 'ballot' : 'sports_gymnastics'}
                                tooltip={entry.category.toUpperCase()}
                            />
                        </ListItem>
                    )
                })}
            </ul>
        </List>
    )
}

PublicLibraryHits.propTypes = {

}

export default PublicLibraryHits

