import React from 'react';
import TimeInput from 'ui/comps/global/TimeInput';

const ListRestTime = (props) =>  {
    
    return (
        <div className="list-rest-time">
            <TimeInput
                {...props}
            />
        </div>
    )
    
}

export default ListRestTime;