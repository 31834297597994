import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types';
import { 
    writeWorkoutResult, 
    getWorkoutResults, 
    getWorkoutResultByHighestScore,
    deleteWorkoutResult 
} from 'fb-api/workout-results';
import { isEmpty, get, map, sortBy, reverse, size, findIndex } from 'lodash';
import moment from 'moment';
import $ from 'jquery'; 

import { 
    Popup, 
    Block, 
    BlockTitle, 
    Row, 
    Col, 
    Button,
    BlockHeader,
    Input, 
    Tabs,
    Tab, 
    Link,
    Sheet,
    PageContent
} from 'framework7-react';
import TrackingChart from 'ui/comps/global/TrackingChart';
import Score from './Score';
import ScoreInput from './ScoreInput';
import ScoreList from './ScoreList';

let sheetCalInstance = null;

function WorkoutTracking (props) {
    
    const [trackingLoading, setTrackingLoad] = useState(null);
    const [bestScore, setBestScore] = useState('');
    const [chartData, setChartData] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [newScore, setNewScore] = useState('');
    const [newScoreNote, setNewScoreNote] = useState('');
    
    const sheetOpened = useRef(false);
    const newScoreData = useRef({});
    
    useEffect(() => {
        
        sheetCalInstance = window.f7App.calendar.create({
            containerEl: '#add-score-date-wrapper',
            on: {
                change: (cal, val) => {             
                    newScoreData.current.created_at = val[0];
                }
            }
        });  
         
    }, []);
    
    useEffect(() => {
        
        setTrackingLoad(null);
        loadTrackingData();
        
    }, [props.workoutId]);
    
    useEffect(() => {
        
        newScoreData.current.score = newScore;
        newScoreData.current.note = newScoreNote;
        
    }, [newScore, newScoreNote]);
    
    const loadTrackingData = async () => {
        
        if(props.workoutId) {
            
            setTrackingLoad(true);
                            
            let _historyData = [];
            let _chartData = [];
            const scoreKey = props.workoutType === 'sets' ? 'total_load' : null;
            
            const workoutResults = await getWorkoutResults({
                workoutId: props.workoutId, 
                onlyScored: props.workoutType !== 'custom',
                onlyNoted: props.workoutType === 'custom',
            });
            
            workoutResults.forEach((docRef) => {
                
                let score = docRef.get('score');
                score = scoreKey ? score[scoreKey] : score;
                
                const momentDate = moment(docRef.get('created_at').toDate())
                                    
                _chartData.push({
                    id: docRef.id,
                    x: momentDate.format('DD. MMM'),
                    y: score
                });
                
                _chartData = sortBy(_chartData, function(dateObj) {
                    return new Date(dateObj.x);
                });
                
                _historyData.push({
                    id: docRef.id,
                    date: momentDate.format(global.config.app.display_date_year_format),
                    score: score,
                    note: docRef.get('note')
                });
                
                _historyData = reverse(sortBy(_historyData, function(dateObj) {
                    return new Date(dateObj.date);
                }));
                
            });
            
            setHistoryData(_historyData);
            
            if(_historyData.length > 0 && ['sets', 'for_time', 'amrap'].includes(props.workoutType)) {
                
                setChartData(_chartData);
                loadBestScore();
                
            }
            
            setTrackingLoad(false);
             
        }
        
    }
    
    const loadBestScore = async () => {
        
        const scoreKey = props.workoutType === 'sets' ? 'total_load' : null;
                      
        const workoutBestScore = await getWorkoutResultByHighestScore({
            workoutId: props.workoutId, 
            scoreKey: scoreKey,
            dir: props.workoutType === 'for_time' ? 'asc' : 'desc'
        });
        
        if( size(get(workoutBestScore, 'docs', [])) ) {
            let score = workoutBestScore.docs[0].get('score');
            score = scoreKey ? score[scoreKey] : score;
            setBestScore(score);
        } 
        else
            setBestScore('');   
        
    }
    
    const addNewScore = async () => {
        
        if(!newScoreData.current.created_at) {
            window.f7App.toast.create(
                {text: 'Please select a date!'}
            ).open();
            return;
        }
        
        if(!['custom', 'emom'].includes(props.workoutType) && !newScoreData.current.score) {
            window.f7App.toast.create(
                {text: 'Please enter a score!'}
            ).open();
            return;
        }
        
        if(props.workoutType === 'emom' && newScoreData.current.score === '')
            newScoreData.current.score = false;
        
        let data = {...newScoreData.current};
        data.workout_id = props.workoutId;
        data.completed = true;
        
        const docRef = await writeWorkoutResult({data: data});
        
        loadTrackingData();
        
        window.f7App.sheet.get('#add-score-sheet').close();
        
    }
    
    const sheetOpen = () => {
        
        sheetOpened.current = true;
        newScoreData.current = {};
        sheetCalInstance.setValue([]);
        sheetCalInstance.update();
        setNewScore('');
        setNewScoreNote('');
        
    }
    
    const deleteScore = async (workoutResultId, index) => {
        
        let _chartData = [...chartData];
        let _historyData = [...historyData];
                
        const removedWorkoutResult = _historyData.splice(index, 1);
        setHistoryData(_historyData);
        
        if(removedWorkoutResult.length) {
            
            const indexInChartData = findIndex(_chartData, {id: removedWorkoutResult[0].id});
        
            if(indexInChartData !== -1) {
                _chartData.splice(indexInChartData, 1);
                setChartData(_chartData);
            }
                                        
        }        
        
        await deleteWorkoutResult(workoutResultId);
        loadBestScore();
         
    }
    
    const hasData = trackingLoading === false && !isEmpty(historyData);
    return (
        <React.Fragment>
            {hasData &&
                <React.Fragment>
                    {['sets', 'for_time', 'amrap'].includes(props.workoutType) &&
                        <React.Fragment>
                            <Block className="best-score-wrapper">
                                <div>
                                    <span className="info">All-time Best Score</span>
                                    <Score
                                        value={bestScore}
                                        workoutType={props.workoutType}
                                    /> 
                                    
                                </div>
                            </Block>
                            <Block id="chart-wrapper">
                                <TrackingChart 
                                    id = "workout-chart" 
                                    data = {chartData} 
                                    yType = {props.workoutType === 'for_time' ? 'time' : 'int'}    
                                />
                            </Block>
                        </React.Fragment>
                    }
                    
                    <ScoreList 
                        data = {historyData} 
                        workoutType = {props.workoutType} 
                        id = "workout-popup-score-list"
                        caption = "History" 
                        subCaption = "Shows the last 10 scores"
                        scoreDelete = {deleteScore}
                    />
                </React.Fragment>
           }
           {!hasData &&
               <p className="notice notice-info">No scores logged yet.</p>
           }
           {props.workoutType !== 'sets' &&
               <Button 
                    text="Log New Score" 
                    fill
                    className="margin-top"
                    sheetOpen="#add-score-sheet"
                />
            }
            {props.workoutType === 'sets' &&
                <p className="notice">
                    The scores for "Sets" are automatically detected when performaned in a session.
                </p>
            }
            <Sheet
                id="add-score-sheet"
                bottom
                push
                onSheetOpen={sheetOpen}
                onSheetClose={() => sheetOpened.current = false}
              >
                <PageContent>
                    <div id="add-score-date-wrapper"></div>
                    
                    <ScoreInput
                        workoutId = {props.workoutId}
                        workoutType = {props.workoutType}
                        repsPerRound = {props.repsPerRound}
                        value = {newScore}
                        scoreChange = {(workoutId, scoreVal) => {
                            if(sheetOpened.current)
                                setNewScore(scoreVal);
                        }}
                    />
                    
                    <Row>
                        <Col>
                            <Input
                                type = "textarea"
                                placeholder = "Enter performance note (optional)"
                                value = {newScoreNote}
                                onInput = {(evt) => {
                                    setNewScoreNote(evt.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col><Button 
                            text="Save New Score"
                            fill
                            small
                            onClick={addNewScore}
                        /></Col>
                        <Col><Button 
                            text="Cancel"
                            fill
                            small
                            color="red"
                            sheetClose
                        /></Col>
                    </Row>
                    
                </PageContent>
              </Sheet>   
        </React.Fragment>            
    )
}

WorkoutTracking.propTypes = {
    workoutId: PropTypes.string,
    workoutType: PropTypes.string,
    repsPerRound: PropTypes.number
}

WorkoutTracking.defaultProps = {
    repsPerRound: 0
}

export default WorkoutTracking;