import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
    Col,
    Button,
    MenuItem,
    MenuDropdown,
    MenuDropdownItem
} from 'framework7-react';

const ModalActions = (props) => {
        
    return (
        <Col width={props.width} className="modal-actions" id={props.id}>
            {props.children}
            {props.onEdit && props.onDelete && 
                
                <MenuItem iconMaterial="more_vert" iconOnly dropdown>
                    <MenuDropdown right>
                        <MenuDropdownItem href="#" text="Edit" onClick={props.onEdit} />
                        <MenuDropdownItem href="#" text="Delete" onClick={props.onDelete} />
                    </MenuDropdown>
                </MenuItem>
                
            }
            <Button 
                iconMaterial = "close"
                popupClose = {props.modalType === 'popup'}
                panelClose = {props.modalType === 'panel'}
                className = "no-padding"
            />
        </Col>
    )
    
}

ModalActions.propTypes = {
    id: PropTypes.string,
    modalType: PropTypes.string,
    width: PropTypes.string,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
}

ModalActions.defaultProps = {
    id: '',
    modalType: 'popup',
    width: '20'
}

export default ModalActions;