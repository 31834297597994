import React, { useEffect, useState } from 'react';
import { 
    PageContent, 
    Block,
    List,
    ListInput,
    Button,
    Link,
    BlockTitle
} from 'framework7-react';
import $ from 'jquery'; 
import { 
    getUser, 
    updateUserEmail, 
    deleteUserAccount
} from 'fb-api/users';
import { isEmpty, get } from 'lodash';


const PersonalInfo = ({f7router}) =>  {
    
    const [email, setEmail] = useState('');
    
    useEffect(() => {
        
        //settings does not update main nav active state, do that with jquery 
        $('#main-toolbar .link[data-route-tab-id="account"]').addClass('tab-link-active')
        .siblings().removeClass('tab-link-active');
        
        (async function() {
            const currentUser = await getUser();
            setEmail(currentUser ? currentUser.email : '');
        })();
        
              
    }, []);
    
    const updateEmail = async () => {

        const res = await updateUserEmail(email);
        f7router.app.toast.create({text: res.message ? res.message : res.error}).open();
        
    }
    
    const deleteAccount = () => {
        
        window.f7App.dialog.confirm(
            'All personal data (sessions, workouts, movements..) will be deleted!',
            'Delete account',
            () => {
                
                deleteUserAccount()
                .then(() => {
                    f7router.navigate('/signin');
                }) 
                
            }
        )
        
    }
    
    return (
        <PageContent>
            
            <Block>
                <List>
                    <ListInput
                        label="Your Email"
                        type="email"
                        placeholder="Enter your Email address"
                        clearButton
                        value={email}
                        onChange={(evt) => setEmail(evt.target.value)}
                    />
                </List>
                <Button 
                    text="Update Email"
                    className="display-inline-block"
                    fill
                    small
                    onClick={updateEmail}
                />
            </Block>
            <Block style={{marginTop: 60}}>
                <BlockTitle>Delete Account</BlockTitle>
                <p>If you want to delete your account and all data that is associated with it, please click the button below. After the deletion process you will be signed out.</p>
                <Button 
                    text="Delete my account"
                    color="red"
                    fill
                    small
                    className="display-inline-block"
                    onClick={deleteAccount}
                />
            </Block>
            
        </PageContent>
    )
    
}

export default PersonalInfo;