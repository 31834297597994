import React from 'react';

const EmbeddedVideo = (props) => {
    
    const renderVideo = () => {
            
        //youtube
        const ytRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const ytMatch = props.url.match(ytRegExp);
        if((ytMatch && ytMatch[2].length === 11)) {
            const ytId = ytMatch[2];
            return (
                <iframe width="100%" height="400" src={"https://youtube.com/embed/"+ytId} frameBorder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Youtube"></iframe>
            )
        }
        
        //vimeo
        const vimeoRegExp = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
        var vimeoMatch = props.url.match(vimeoRegExp);
        if(vimeoMatch && vimeoMatch[1]) {
            return <iframe src={"https://player.vimeo.com/video/"+vimeoMatch[1]} width="100%" height="400" frameBorder="0" allow="autoplay; fullscreen;" allowFullScreen title="Vimeo"></iframe>
        }
        
        return null;
            
    }
    
    return (
        <React.Fragment>
            {renderVideo()}
        </React.Fragment>
    );
    
}

export default EmbeddedVideo;