import React from 'react';
import { 
    Popup, 
    Page, 
    Block, 
    BlockTitle, 
    Row, 
    Col, 
    Button, 
    List, 
    ListItem, 
} from 'framework7-react';
import { map } from 'lodash';
import workoutTypes from 'json/workout-types.json';
import $ from 'jquery'; 

import ModalActions from 'ui/comps/global/ModalActions';

const SelectWorkoutTypePopup = (props) =>  {
    
    return (
        
        <Popup
            id="workout-type-popup"
        >
            <Page>
                <Block>
                    <Row>
                        <Col width="80">
                            <BlockTitle 
                                large
                                className="no-margin"
                            >
                                Workout Type
                            </BlockTitle>
                        </Col>
                        <ModalActions 
                            id = "modal-actions-workout-type" 
                        />
                    </Row>
                </Block>
                <Block>
                    <List mediaList>
                    {map(workoutTypes, (wType, i) => {
                        return (
                            <ListItem 
                                title={wType.name}
                                subtitle={wType.desc} 
                                key={i} 
                                link="#"
                                data-type={i}
                                onClick={(evt) => 
                                    props.workoutTypeSelect($(evt.currentTarget).parent().data('type'))
                                }
                            />
                        )
                    })}
                    </List>
                </Block>
            </Page>
        </Popup>
        
    )
    
}

export default SelectWorkoutTypePopup;