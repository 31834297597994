import React, { useState } from 'react';
import {
    LoginScreenTitle,
    List,
    ListInput,
    BlockFooter,
    ListItem,
    Button,
    LoginScreen,
    Sheet,
    PageContent,
    Block,
    Link
} from 'framework7-react';
import { 
    sendUserPasswordReset
} from 'fb-api/users';

const SignIn = (props) => {
                
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forgotPasswordSheetOpened, setForgotPasswordSheetOpened] = useState(false);

    const formSubmit = (evt) => { 
        
        evt.preventDefault();
        props.signHandler(email, password);
        
    }
    
    const sendPasswordReset = async () => {
        
        const res = await sendUserPasswordReset(email);
        props.f7router.app.toast.create({text: res.error ? res.error : res.message}).open();
        
        if(!res.error)
            setForgotPasswordSheetOpened(false);
        
    }
    
    return (
        <LoginScreen
            className="signup-screen"
            opened
        >
            <LoginScreenTitle>
                Sign In
            </LoginScreenTitle>
            <List form onSubmit={formSubmit}>
                <ListInput
                    label="Email"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onInput={(e) => {
                        setEmail(e.target.value);
                    }}
                />
                <ListInput
                    label="Password"
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onInput={(e) => {
                        setPassword(e.target.value);
                    }}
                />
                <ListItem className="text-align-center">
                    <Link
                        text="Forgot Password?"
                        onClick={() => setForgotPasswordSheetOpened(true)}
                    />
                </ListItem>
                <ListItem>
                    <Button 
                        text="Sign In"
                        fill
                        type="submit"
                        className="button-gradient"
                    />
                </ListItem>
            </List>
            <BlockFooter>
                New to this app? <a href="/signup">Create a new account</a>
            </BlockFooter>
            <Sheet
                id="forgot-password-sheet"
                opened={forgotPasswordSheetOpened}
                onSheetClosed={() => setForgotPasswordSheetOpened(false)}
              >
                <PageContent>
                    <List>
                        <ListInput
                            label="Email"
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onInput={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        <ListItem>
                            <Button 
                                text="Reset Password"
                                fill
                                onClick={sendPasswordReset}
                            />
                        </ListItem>
                        <ListItem className="text-align-center">
                            <Link text="Close" sheetClose />
                        </ListItem>
                    </List>
                </PageContent>
              </Sheet>
        </LoginScreen>
    ) 
      
}

export default SignIn;