import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { renderTime } from 'misc/helpers.js';
import { get } from 'lodash';

const Record = (props) => {
    
    let label;                
    switch (props.log) {
        case 'reps':
            label = 'reps';
        break;
        case 'reps_weight':
            label = get(global, 'config._userMeta.units.weight', 'metric') === 'metric' ? 'kg' : 'lb';
        break;
        case 'cals':
            label = 'calories';
        break;
        case 'distance':
            label = get(global, 'config._userMeta.units.distance', 'meters') === 'meters' ? 'meters' : 'inches';
        break;
    }
    
    if(props.log === 'time') {
        return renderTime(props.value)
    }
    else {
        return <span className={"total-"+props.log}>{props.value} {label}</span>;
    }
        
}

Record.propTypes = {
    log: PropTypes.string
}

Record.defaultProps = {
}

export default Record;