import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    getWorkouts,
} from 'redux-store/slices/workouts';
import { 
    Popup, 
    Page, 
    Block, 
    Row, 
    Col, 
    Button,
    Searchbar,
    List,
    ListItem
} from 'framework7-react';
import { map, isEmpty } from 'lodash';
import workoutTypes from 'json/workout-types.json';
import $ from 'jquery'; 

import ModalActions from 'ui/comps/global/ModalActions';

const WorkoutPopups = (props) =>  {
    
    const dispatch = useDispatch();
    
    const loading = useSelector((state) => state.workouts.loading);
    const allWorkouts = useSelector((state) => state.workouts.all);
    
    useEffect(() => {
                
        if(isEmpty(allWorkouts))
            dispatch( getWorkouts({is_library: true}) );
                    
    }, []);
    
    const itemSelected = (evt) => {
        
        window.f7App.popup.get('#workouts-popup').close();
        
        if(props.onSelect)
            props.onSelect($(evt.currentTarget).parents('li:first').data('id'));
        
    }
    
    return (
        <Popup
            id="workouts-popup"
        >
            <Page>
                <Block>
                    <Row>
                        <ModalActions 
                            id = "modal-actions-workouts" 
                            width="100"
                        />
                    </Row>
                </Block>
                <Block>
                    <Searchbar
                        inline
                        placeholder="Search for a workout"
                        searchContainer="#workouts-search-results"
                        searchIn=".item-title"
                        disableButton={false}
                    />
                    <List className="search-list" id="workouts-search-results">
                        {map(allWorkouts, (entry, i) => {
                            return (
                                <ListItem 
                                    title={entry.name} 
                                    footer={workoutTypes[entry.type].name}
                                    link="#"
                                    after="+"
                                    noChevron
                                    data-id={entry.id}
                                    onClick={itemSelected}
                                    key={entry.id}
                                />
                            )
                        })}
                    </List>
                </Block>
            </Page>
        </Popup>
        
    )
    
}

export default WorkoutPopups;