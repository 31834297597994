import React from 'react';
import { App } from 'framework7-react';
import { Network } from '@capacitor/network';
import { Capacitor } from '@capacitor/core';

import AthleteView from './views/AthleteView.js';
import AthleteLayout from './layouts/AthleteLayout.js';
import SignUpIn from './containers/SignUpIn.js';
import Planner from './containers/Planner.js';
import Library from './containers/Library.js';
import PersonalInfo from './containers/account/PersonalInfo.js';
import Password from './containers/account/Password.js';
import Units from './containers/account/Units.js';
import Info from './containers/account/Info.js';
import Page404 from './pages/404.js';
import ViewWorkout from './comps/workout/ViewWorkout.js';


import { getUser } from 'fb-api/users';

let networkToast = null;
Network.addListener('networkStatusChange', async () => {
    
    const status = await Network.getStatus();
    //console.log('Network status:', status);
    
    if(!status.connected && window.f7App) {
        
        networkToast = window.f7App.toast.create({
            text: 'You are offline!',
            position: 'top',
            horizontalPosition: 'center',
            closeButton: true,
            closeTimeout: false,
            closeButtonText: 'Close'
        }).open();
    }
    else if(networkToast) {
        networkToast.close();
    }   
  
});

const signUpInAsync = async ({router, resolve, reject }) => {
            
    const currentUser = await getUser();

    if(!currentUser) {
        resolve({component: SignUpIn})
    }
    else {
        
        reject();
        //if signed-in and path does not include athlete route redirect to athlete route 
        if(!window.location.pathname.includes('/athlete'))
            router.navigate('/athlete/');
    }
    
};

let viewOpts = {
    animate: false
};

if(!Capacitor.isNativePlatform()) {
    viewOpts.browserHistory = true;
    viewOpts.browserHistoryInitialMatch = true;
    viewOpts.browserHistorySeparator = '';
    viewOpts.browserHistoryAnimate = false;
    viewOpts.preloadPreviousPage = true;
}

const f7params = {
    name: 'XESH',
    id: 'me.radykal.xesh',
    theme: 'aurora',
    popup: {
        swipeToClose: false  
    },
    panel: {
        swipe: true,
        swipeOnlyClose: true
    },
    toast: {
        closeTimeout: 4000,
    },
    touch: {
        mdTouchRipple: false,
        tapHold: true
    },
    view: viewOpts,
    // routes
    routes: [
        {
            path: '/',
            async: async ({router, resolve, reject, to, from }) => {
                
                const currentUser = await getUser();
                const currentPath = window.location.pathname;
                
                if(currentUser) {
                    reject();
                   
                    if(!currentPath.includes('/athlete') && !currentPath.includes('/workout/'))
                        router.navigate('/athlete/');
                }
                else {
                    reject();
                    if(!currentPath.includes('/workout/'))
                    router.navigate('/signin/');
                }
                                
            },
        },
        {
            path: '/signin(/.*)?',
            alias: '/login(/.*)?',
            async: signUpInAsync,
        },
        {
            path: '/signup(/.*)?',
            async: signUpInAsync,
        },
        {
            path: '/link/workout/:id',
            component: ViewWorkout
        },
        {
            path: '/athlete/',          
            async: async ({router, resolve, reject, app}) => {
                
                window.f7App = app;
                
                const currentUser = await getUser();
                if(currentUser) {
                    resolve({component: AthleteLayout});
                }
                else {
                    reject();
                    router.navigate('/signin');
                }
                
            },
            tabs: [
                {
                    path: '/',
                    id: 'planner',
                    component: Planner,
                },
                {
                    path: '/planner',
                    id: 'planner',
                    component: Planner
                },
                {
                    path: '/library',
                    id: 'library',
                    component: Library
                },
                {
                    path: '/account/personal',
                    id: 'account-personal',
                    component: PersonalInfo,
                },
                {
                    path: '/account/password',
                    id: 'account-password',
                    component: Password
                },
                {
                    path: '/account/units',
                    id: 'account-units',
                    component: Units
                },
                {
                    path: '/account/info',
                    id: 'account-info',
                    component: Info
                }
            ]    
        },
        {
          path: '/(.*)',
          redirect: '/',
        },
    ],
}
    
const Root = () => (
    <App {...f7params}>
        <AthleteView></AthleteView>
    </App>
);


export default Root;