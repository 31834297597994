import React, { useEffect, useState } from 'react';
import { 
    PageContent, 
    Block,
    List,
    ListInput,
    Button,
} from 'framework7-react';
import $ from 'jquery'; 
import { 
    updateUserMeta, 
    getUserMeta
} from 'fb-api/users';
import { isEmpty, get } from 'lodash';

const AccountUnits = ({f7router}) =>  {
    
    const [weightUnit, setWeightUnit] = useState('metric');
    const [distanceUnit, setDistanceUnit] = useState('meters');
    
    useEffect(() => {
        
        //settings does not update main nav active state, do that with jquery 
        $('#main-toolbar .link[data-route-tab-id="account"]').addClass('tab-link-active')
        .siblings().removeClass('tab-link-active');
        
        (async function() {
            
            const userMeta = await getUserMeta();
            const userWeightUnit = get(userMeta, 'units.weight', 'metric');
            const userDistanceUnit = get(userMeta, 'units.distance', 'meters');
            
            setWeightUnit(userWeightUnit);
            setDistanceUnit(userDistanceUnit);
            
        })();
              
    }, []);
    
    const updateUnits = async () => {
        
        const res = await updateUserMeta({
            units: {
                weight: weightUnit,
                distance: distanceUnit
            }
        });
        
        global.config._userMeta = {
            units: {
                weight: weightUnit,
                distance: distanceUnit
            }
        };
        
        f7router.app.toast.create({text: res.message ? res.message : res.error}).open();
        
    }
    
    return (
        <PageContent>
        
            <Block>
            
                <List>
                    <ListInput
                        label="Weight"
                        type="select"
                        value={weightUnit}
                        onChange={(evt) => setWeightUnit(evt.target.value)}
                    >
                        <option value="metric">Metric (kg)</option>
                        <option value="imperial">Imperial (lb)</option>
                    </ListInput>
                    <ListInput
                        label="Distance"
                        type="select"
                        value={distanceUnit}
                        onChange={(evt) => setDistanceUnit(evt.target.value)}
                    >
                        <option value="meters">Meters</option>
                        <option value="inches">Inches</option>
                    </ListInput>
                </List>
                <Button 
                    text="Update Units"
                    className="display-inline-block"
                    fill
                    small
                    onClick={updateUnits}
                />
            
            </Block>
            
        </PageContent>
    )
    
}

export default AccountUnits;