import React, { useState, useEffect, useRef } from 'react';
import { 
    Block, 
    BlockTitle, 
    Row, 
    Col, 
    Button,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Input,
    Popup
} from 'framework7-react';
import { isEmpty, get, now, map, find, parseInt, isArray, has, size, each, isObject } from 'lodash';
import { renderTimecap, sanitizeDesc } from 'misc/helpers.js';
import workoutTypes from 'json/workout-types.json';
import $ from 'jquery';
import moment from 'moment';
import { getBestPersonalRecord } from 'fb-api/personal-records';

import TimeInput from 'ui/comps/global/TimeInput';
import SetsDisplay from 'ui/comps/workout/SetsDisplay';
import ScoreInput from 'ui/comps/workout/ScoreInput';

const SessionWorkout = (props) => {
        
    const timeEl = renderTimecap(get(props, 'time'), props.type);
                
    const [expanded, setExpanded] = useState(true);
    const [completed, setCompleted] = useState(get(props, 'completed', false));
    const [performanceNote, setPerformanceNote] = useState(get(props, 'note', ''));
    const [newScore, setNewScore] = useState(null);     
    const scoreSets = useRef(get(props, 'score', null));
        
    useEffect(() => {
        
        setNewScore(props.score);
        
    }, [props.score]);
    
    useEffect(() => {
        
        if(completed)
            setExpanded(false);
        
        props.workoutComplete(props.id, completed);
        
    }, [completed]);
    
    useEffect(() => {
        
        props.noteChange(props.id, performanceNote);
        
    }, [performanceNote]);
    
    const setMovementScore = (setIndex, movIndex, props) => {
        
        let _scoreSets = {...scoreSets.current};
        let setsData = get(_scoreSets, 'sets', {});
        
        let scoreData = get(setsData, '['+setIndex+']['+movIndex+']', {});
        
        each(props, (value, key) => {
            scoreData[key] = value;
        });
        
        if(setsData[setIndex]) 
            setsData[setIndex][movIndex] = scoreData;
        else {
            setsData[setIndex] = {};
            setsData[setIndex][movIndex] = scoreData;
        }
               
        return setsData;
        
    }
    
    const movementLogSet = (setIndex, movIndex, log, value) => {
        
        if(props.type === 'sets') {
            
            let setsData = setMovementScore(setIndex, movIndex, {[log]: value});
            setsCalTotalLoad(setsData);
            
        }
                
    }
    
    const movementChecked = async (setIndex, checked, logOneVal, logTwoVal, movementObj, movIndex) => {
        
        let scoreData = {checked: checked};        
        if(movementObj.log_parameter === 'reps_weight' && (logOneVal || logTwoVal)) {
            if(logOneVal)
                scoreData.reps = logOneVal;
            if(logTwoVal)
                scoreData.weight = logTwoVal;
        }
        else if(logOneVal) {
            scoreData[movementObj.log_parameter] = logOneVal;
        }
        
        let setsData = setMovementScore(setIndex, movIndex, scoreData);
        setsCalTotalLoad(setsData);
         
        //check for record
        if(checked) {
            
            //only check for new records when log values are not zero
            if( movementObj.log_parameter === 'reps_weight' && 
                (logOneVal == 0 || logTwoVal == 0 || ![1,2,3,5,10,20].includes(parseInt(logOneVal))) ) return;
            else if( ['reps', 'time'].includes(movementObj.log_parameter) && logOneVal == 0 ) return;
            
            const bestPersonalRecord = await getBestPersonalRecord({
                movId: movementObj.id, 
                dir: movementObj.log_parameter === 'time' ? 'asc' : 'desc',
                benchmark: movementObj.log_parameter === 'reps_weight' ? parseInt(logOneVal) : null
            });
                        
            const newRecordData = {
                ...movementObj
            };
            
            if(movementObj.log_parameter === 'reps_weight') {
                newRecordData.reps = parseInt(logOneVal);
                newRecordData.weight = Number(logTwoVal);
            }
            else if(movementObj.log_parameter === 'reps') {
                newRecordData.reps = parseInt(logOneVal);
            }
            else if(movementObj.log_parameter === 'time') {
                newRecordData.time = parseInt(logOneVal);
            }
            
            if( size(get(bestPersonalRecord, 'docs', [])) ) { //compare oldest record with new one
                
                const currentRecord = bestPersonalRecord.docs[0].get('record');
                
                if(movementObj.log_parameter === 'reps_weight' && logTwoVal > currentRecord) {
                    props.onNewRecord(newRecordData);
                }
                else if(movementObj.log_parameter === 'reps' && logOneVal > currentRecord) {
                    props.onNewRecord(newRecordData);
                }
                else if(movementObj.log_parameter === 'time' && logOneVal < currentRecord) {
                    props.onNewRecord(newRecordData);
                }
                
            }
            else { //no record exist
                props.onNewRecord(newRecordData);
            }
            
        }
            
    }
    
    //calculate total load (sets x reps x weight)
    const setsCalTotalLoad = (setsData=null) => {
        
        if(props.type === 'sets') {
                        
            let _scoreSets = {...scoreSets.current};
            let totalLoad = 0;
            
            each(setsData, (setData) => {
                each(setData, (scoreData) => {
                    if(scoreData.weight && scoreData.reps && scoreData.checked) {
                        totalLoad += parseFloat(scoreData.reps * scoreData.weight);
                    }
                });
            })
            
            _scoreSets.total_load = totalLoad; 
            if(setsData)
                _scoreSets.sets = setsData;
            
            scoreSets.current = _scoreSets;
            
            props.scoreChange(props.id, scoreSets.current);
            
        }
        
    }
        
    const renderWorkout = () => {
                            
        return (
            <React.Fragment>
            {!isEmpty(get(props, 'description', '')) &&
                <div dangerouslySetInnerHTML={{__html: sanitizeDesc(props.description)}} className="workout-description"></div>
            }
            {props.type === 'sets' &&
                <SetsDisplay 
                    mode = "execute"
                    configs = {props.configs}
                    setsData = {props.setsData}
                    scoreData = {get(props, 'score.sets', {})}
                    movementLogSet = {movementLogSet}
                    movementChecked  = {movementChecked}
                />
            }
            </React.Fragment>
        )
        
    }
    
    const renderInfo = () => {
        
        let info = workoutTypes[props.type].name;
        
        if(timeEl) {
            info += ' - ';
        }
        else if(props.type === 'sets') {
            info = get(props, 'configs.number_of_sets', 1) + ' ' + info; 
        }
        
        return (
            <span className="workout-info">{info} {timeEl}</span>
        )
        
    }
    
    return (
        
        <Card id={props.id} className={`${completed ? 'completed ' : ''}${expanded ? '' : 'closed'}`}>
            <CardHeader>
                <div className="float-left">
                    <span className="workout-name">{props.name}</span>
                    {renderInfo()}
                </div>
                <div className="float-right">
                    {props.is_library && 
                        <Button 
                            iconMaterial="timeline"
                            tooltip="Past Scores" 
                            iconSize={26}
                            onClick = { () => props.openWorkoutHistory(props.id, props.type) }
                        />
                    }
                    <Button 
                        iconMaterial={`${expanded ? 'expand_less' : 'expand_more'}`}
                        tooltip="Expand" 
                        iconSize={26}
                        onClick={() => setExpanded(!expanded)}
                    />
                    <Button 
                        iconMaterial="task_alt"
                        tooltip="Mark as completed" 
                        iconSize={26}
                        color={completed ? 'green' : ''}
                        onClick={() => setCompleted(!completed)}
                    />                    
                </div>
            </CardHeader>
            <CardContent>
                {renderWorkout()}
            </CardContent>
            <CardFooter>  
                <Row>
                    <Col width="100">
                        <ScoreInput
                            workoutId={props.id}
                            workoutType={props.type}
                            value={newScore}
                            repsPerRound={get(props, 'reps_per_round', 0)}
                            scoreChange={props.scoreChange}
                        />
                    </Col>
                    <Col width="100">
                        <Input
                            type="textarea"
                            placeholder="Enter performance notes (optional)"
                            outline
                            value={performanceNote}
                            onChange={(evt) => setPerformanceNote(evt.target.value)}
                        />
                    </Col>
                </Row>
            </CardFooter>
        </Card>
        
    )
    
}

export default SessionWorkout;