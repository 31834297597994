import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
    readWorkouts
} from 'fb-api/workouts';
import { 
    subscribeSession
} from 'fb-api/sessions';
import { 
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Button,
    Icon
} from 'framework7-react';
import moment from 'moment';
import { times, map, isEmpty, find, get } from 'lodash';
import { indexToAlpha } from 'misc/helpers.js';
import $ from 'jquery';

const TimelineSession = (props) => {
            
    const [sessionData, setSessionData] = useState({});    
    const [workouts, setWorkouts] = useState([]);
    
    useEffect(() => {
        
        //return to unsubscribe   
        return subscribeSession(props.id, (doc) => {
            setSessionData({...doc.data()})
        });
     
    }, [props.id]);
    
    useEffect(() => {
        
        if(sessionData.execution_date) {
            
            $('#timeline-sessions').trigger(
                'session-completed-toggle', 
                moment(sessionData.execution_date.toDate()).format('D') 
            );
            
        }
             
    }, [sessionData.completed]);
    
    useEffect(() => {
        
        (async function() {
                        
            if(isEmpty(get(sessionData, 'workouts', []))) {
                setWorkouts([]);
                return;
            }
            
            const workoutIds = map(sessionData.workouts, 'id'); 
            const sessionWorkouts = await readWorkouts({includeIds: workoutIds});
            
            let workouts = [];
            sessionWorkouts.forEach((docRef) => {
                workouts.push({id: docRef.id, ...docRef.data()});
            });
            
            //sort by correct order from workout IDs
            workouts = map(workouts, (w, i) => {
                return find(workouts, {id: workoutIds[i]});
            });
            
            setWorkouts(workouts);
            
        })();
    
                
    }, [sessionData]);
    
    const deleteSession = () => {
        
        window.f7App.dialog.confirm(
            `Are you sure to delete the session?`,
            '',
            () => {
                props.deleteSession(props.id);
            }
        )
        
    }

    return (
        <Card outline noShadow noBorder className={sessionData.completed ? 'session completed' : 'session'} data-id={props.id}>
            <CardHeader>
                {sessionData.name}
                <span className="float-right">
                    <Button 
                        className="move-up"
                        iconMaterial="keyboard_double_arrow_up"
                        tooltip="Move Up"
                        onClick={() => props.moveSession('up', props.id, props.daySessionId)}
                     />
                    <Button 
                        className="move-down"
                        iconMaterial="keyboard_double_arrow_down"
                        tooltip="Move Down"
                        onClick={() => props.moveSession('down', props.id, props.daySessionId)}
                     />
                     <Button 
                         iconMaterial="edit" 
                         tooltip="Edit Session"
                         onClick={() => props.editSession(props.id, props.daySessionId)}
                     />
                    <Button 
                        iconMaterial="delete_outline"
                        color="red"
                        tooltip="Delete Session"
                        onClick={deleteSession}
                     />
                </span>
            </CardHeader>
            <CardContent>
                {map(workouts, (workout, i) => {
                    
                    //completed prop
                    const isCompleted = false;
                                        
                    return (
                        <div className="workout" key={i}>
                            <span className={isCompleted ? 'completed' : ''}>{indexToAlpha(i)}</span>
                            <span>{workout.name}</span>
                        </div>
                    )
                    
                })}
            </CardContent>
            <CardFooter>
                <Button 
                    text="View Session"
                    fill
                    small
                    onClick={() => props.viewSession(props.id)}
                 />
            </CardFooter>
        </Card>
    )
    
}

TimelineSession.propTypes = {
    id: PropTypes.string,
    viewSession: PropTypes.func,
    moveSession: PropTypes.func,
    editSession: PropTypes.func,
    deleteSession: PropTypes.func,
}

export default TimelineSession;