import firebaseApp from './init';
import {
    getFirestore,
    collection,
    doc,
    addDoc,
    getDocs,
    query,
    where,
    getDoc,
    setDoc,
    deleteDoc,
    serverTimestamp,
    orderBy,
    documentId,
    limit
} from 'firebase/firestore';
import { isEmpty, isArray, get } from 'lodash';

const db = getFirestore(firebaseApp);
const workoutResultsCol = collection(db, 'workout_results');

export const writeWorkoutResult = (payload) => {
        
    try {
        
        let data = payload.data;
        
        if(isArray(data.score)) {
            data.score = {...data.score};
        }
                         
        //update
        if(payload.workout_result_id) {
            return setDoc(doc(db, workoutResultsCol.path, payload.workout_result_id), data, {merge: true});
        }
        //create
        else {
            data.user_id = global._currentUser.uid;
            data.created_at = data.created_at ? data.created_at : serverTimestamp();
            return addDoc(workoutResultsCol, data);
        }
    }
    catch(err) {
        console.error(err.message);
    }
     
};

export const getWorkoutResults = (opts={limit: 10}) => {
        
    if(opts.workoutIds) {
        
        return getDocs(query(
                workoutResultsCol, 
                where('user_id', '==',  get(global, '_currentUser.uid', null)), 
                where('workout_id', 'in',  opts.workoutIds),
                where('session_id', '==',  opts.sessionId)
        ));
    
    }
            
    let constraintsWhere = [];
    constraintsWhere.push(where('user_id', '==',  get(global, '_currentUser.uid', null)));
    constraintsWhere.push(where('workout_id', '==',  opts.workoutId));
    
    let constraintsOrder = [];
    
    if(opts.onlyScored) {
        constraintsWhere.push(where('completed', '==',  true));
        constraintsWhere.push(where('score', '!=',  null));
        constraintsOrder.push(orderBy('score'));
    }
    else if(opts.onlyNoted) {
        constraintsWhere.push(where('completed', '==',  true));
        constraintsWhere.push(where('note', '!=',  null));
        constraintsOrder.push(orderBy('note'));
    }
    
    constraintsOrder.push(orderBy('created_at', 'asc'));

    let q = query(workoutResultsCol, ...constraintsWhere, ...constraintsOrder, limit(opts.limit));

    return getDocs(q);
    
};

export const getWorkoutResultByHighestScore = (opts={}) => {
        
    let constraints = [];
    constraints.push(where('user_id', '==',  get(global, '_currentUser.uid', null)));
    constraints.push(where('workout_id', '==',  opts.workoutId));
    constraints.push(where('completed', '==',  true));
    
    let fieldPath = 'score'+ (opts.scoreKey ? '.'+opts.scoreKey : '');
    let q = query(workoutResultsCol, ...constraints, orderBy(fieldPath, get(opts, 'dir', 'desc')), limit(1));

    return getDocs(q);
    
};

export const deleteWorkoutResult = (id) => {
    
    return deleteDoc( doc(db, workoutResultsCol.path, id) );
    
};