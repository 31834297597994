import React from 'react';
import { Page } from 'framework7-react';
import Logo from 'ui/comps/global/Logo';
import Slogan from 'ui/comps/global/Slogan';
import SignUp from 'ui/comps/sign/SignUp.js';
import SignIn from 'ui/comps/sign/SignIn.js';

import { createUser, signInUser } from 'fb-api/users';

const SignUpIn = ({f7router, f7route}) => {
    
    const signHandler = async (email, password) => {
        
        document.activeElement.blur();
        f7router.app.preloader.showIn('.signin-signup .login-screen');
        
        const res = f7route.path.includes('signup') 
            ? await createUser(email, password) 
            : await signInUser(email, password);
        
        f7router.app.preloader.hideIn('.signin-signup .login-screen');
        
        res.error
            ? f7router.app.toast.create({text: res.error}).open()
            : f7router.navigate('/athlete/', {reloadAll: true});
        
    }
            
    return (
        <Page className="signin-signup">
            <Logo />
            <Slogan />
            {f7route.path.includes('signup')
                ? <SignUp signHandler={signHandler} f7router={f7router} />
                : <SignIn signHandler={signHandler} f7router={f7router} />
            }
        </Page>
    )
    
}

export default SignUpIn;