import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { writeMovement, readMovements, readMovement, deleteSingleMovement } from 'fb-api/movements';
import { findIndex, merge, pick } from 'lodash';

const initialState = {
    loading: false,
    all: [],
    single: null,
    updated: false
}

const defaultMultiValues = [
    'id',
    'name',
    'created_at',
    'log_parameter',
];

export const addMovement = createAsyncThunk('movements/addMovement', async (payload, { rejectWithValue }) => {
    
    try {
        const docRef = await writeMovement(payload);
        
        let reducerObj = {...payload.data};
        if(payload.mov_id)
            reducerObj.mov_id = payload.mov_id;
        else 
            reducerObj.id = docRef.id;
            
        return reducerObj;  
    }
    catch(err) {
        return rejectWithValue(err.message);
    }
       
});

export const getMovements = createAsyncThunk('movements/getMovements', async (payload, { rejectWithValue }) => {
        
    try {
        
        let result = []
        const querySnapshot = await readMovements();
        querySnapshot.forEach((doc) => {
            result.push({...pick(doc.data(), defaultMultiValues), id: doc.id});
        });
        
        return result;
        
    }
    catch(err) {
        return rejectWithValue(err.message);
    }
   
});

export const getMovementDetails = createAsyncThunk('movements/getDetails', async (payload, { rejectWithValue }) => {
    
    try {
        const docSnap = await readMovement(payload.id)
        return {id: docSnap.id, ...docSnap.data()};  
    }
    catch(err) {
        return rejectWithValue(err.message);
    }
    
});

export const deleteMovement = createAsyncThunk('movements/deleteMovement', async (payload, { rejectWithValue }) => {
    
    try {
        await deleteSingleMovement(payload.id); 
        return payload.id; 
    }
    catch(err) {
        return rejectWithValue(err.message);
    }
       
   
});

export const movementsSlice = createSlice({
    name: 'movements',
    initialState,
    reducers: {
    },
    extraReducers: {
        [addMovement.pending]: (state, action) => {
            state.loading = 'Saving Movement...';
            state.updated = false;
        },
        [addMovement.fulfilled]: (state, action) => {
            state.loading = false;
            
            const payload = action.payload;
            let stateAll = [...state.all];
            
            //update
            if(payload.mov_id) {
                
                let docIndex = findIndex(stateAll, {id: payload.mov_id});
                if(docIndex !== -1) {
                    let updateDoc = {...stateAll[docIndex]};
                    stateAll[docIndex] = merge(updateDoc, pick(payload, defaultMultiValues));
                }
                
            }
            //create
            else {
                stateAll.push(pick(payload, defaultMultiValues));
            }
            
            state.all = stateAll;
            state.updated = payload.mov_id;
            
        },
        [addMovement.rejected]: (state, action) => {
            state.loading = false;
            state.updated = false;
        },
        [getMovements.pending]: (state, action) => {
            state.loading = 'Loading Movements...';
        },
        [getMovements.fulfilled]: (state, action) => {
            state.loading = false;
            state.all = action.payload;
        },
        [getMovements.rejected]: (state, action) => {
            state.loading = false;
            state.all = [];
        },
        [getMovementDetails.pending]: (state, action) => {
            state.loading = 'Retrieving Movement Details...';
        },
        [getMovementDetails.fulfilled]: (state, action) => {
            state.loading = false;
            state.single = action.payload;
        },
        [getMovementDetails.rejected]: (state, action) => {
            state.loading = false;
            state.single = null;
        },
        [deleteMovement.pending]: (state, action) => {
            state.loading = 'Deleting Movement...';
        },
        [deleteMovement.fulfilled]: (state, action) => {
            state.loading = false;
            
            const docId = action.payload;
            let stateAll = [...state.all];
            
            stateAll.splice(findIndex(stateAll, {id: docId}), 1); 
            state.all = stateAll;
            
        },
        [deleteMovement.rejected]: (state, action) => {
            state.loading = false;
        }
    }
});

export default movementsSlice.reducer;