import React from 'react';
import PropTypes from 'prop-types';
import {  
    Block, 
    Toolbar,
    Tabs,
    Tab, 
    Link,
    List
} from 'framework7-react'; 
import { map, times, get, now, merge, cloneDeep } from 'lodash';

import MovementView from './MovementView.js';

const SetsDisplay = (props) => {
                
    const renderSet = (index, setIndex) => {
        
        let setData = props.setsData ? cloneDeep(props.setsData[index]) : [];
                
        //merge score data with set data
        if(props.scoreData && props.scoreData[setIndex]) {
            let setScores = cloneDeep(props.scoreData[setIndex]);
            setData = merge(setData, setScores);
        }
        
        return (
            <List sortable className="movements-list">
                <ul>
                    {map(setData ? setData : [], (data, i) => {
                        
                        return (
                           <MovementView
                                mode={props.mode}
                                {...data}
                                index={i}
                                key={data.movement.id+'_'+i}
                                logSet={(...args) =>  {
                                    if(props.movementLogSet)
                                        props.movementLogSet(setIndex, ...args);
                                }}
                                onCheck = {(...args) =>  {
                                    if(props.movementChecked)
                                        props.movementChecked(setIndex, ...args);
                                }}
                           />
                        )
                        
                    })}
                </ul>
            </List>
        )
        
    }
    
    const renderTabs = () => {
        
        const numberOfSets = get(props, 'configs.number_of_sets', 0);
        const tabIdPrefix = 'sets-'+now()+'-';
        
        return (
            <React.Fragment>
                <Toolbar tabbar scrollable className="secondary">
                    {times(numberOfSets, (i) => {
                        return (
                            <Link 
                                text={"Set "+(i+1)}
                                tabLink={'#'+tabIdPrefix+(i+1)}
                                tabLinkActive={i===0}
                                key={i}
                            />
                        )
                    })}
                </Toolbar>
                <Tabs>
                    {times(numberOfSets, (i) => {
                        
                        let dataIndex = get(props, 'configs.equal_sets', true) ? 0 : i;

                        return (
                            <Tab 
                                id={tabIdPrefix+(i+1)}
                                tabActive={i===0}
                                key={i}
                                className="page-content"
                            >
                                {renderSet(dataIndex, i)}
                            </Tab>
                        )
                    })}
                </Tabs>
            </React.Fragment>
        )   
    }
    
    return (
        <Block className={'sets-display mode-'+props.mode}>
            {renderTabs()}
        </Block>
    )
    
}

SetsDisplay.propTypes = {
    mode: PropTypes.string,
    movementLogSet:  PropTypes.func,
    movementChecked:  PropTypes.func
}

SetsDisplay.defaultProps = {
    mode: 'view' //view, execute
}

export default SetsDisplay