import React from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'; 
import { 
    Input,
    Icon 
} from 'framework7-react';
import { useSearchBox } from 'react-instantsearch-hooks-web';

function SearchBox(props) {
    
    const { query, refine, clear } = useSearchBox(props);
    
    return (
        
        <div className="algolia-searchbox">
            <Icon material="search" />
            <Input
                type="text"
                placeholder="Search"
                clearButton
                onChange={(evt) => refine(evt.target.value)}
                onInputClear = {clear}
            />
        </div>
        
    )
}

SearchBox.propTypes = {

}

export default SearchBox