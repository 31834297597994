import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
   Row,
   Col,
   Input,
   Button,
   ListItem,
   Link,
   Popup,
   Page, 
   Block
} from 'framework7-react';
import { get } from 'lodash';
import TimeInput from 'ui/comps/global/TimeInput';
import $ from 'jquery'; 

function MovementView (props)  {
                
    const [logOne, setLogOne] = useState(null);
    const [logTwo, setLogTwo] = useState(null);
    const [logOneVal, setLogOneVal] = useState('');
    const [logTwoVal, setLogTwoVal] = useState('');
    const [checked, setChecked] = useState(get(props, 'checked', false));
    const [startTimer, setStartTimer] = useState(false);
    const [timerEnd, setTimerEnd] = useState(false);
    
    const noTimer = parseInt(get(props, 'rest_time', 0)) === 0;

    useEffect(() => {
            
        const movement = props.movement;
        const logParams = movement.log_parameter.split('_');
        
        setLogOne(logParams[0]);
        setLogTwo(logParams.length === 2 ? logParams[1] : null);     
        
    }, [props.movement]);
    
    useEffect(() => {
        
        setLogOneVal(get(props, logOne, ''));
        setLogTwoVal(get(props, logTwo, ''));
                
    }, [logOne, logTwo]);
    
    useEffect(() => {
        
        if(!noTimer) {
            
            if(!checked) {
                setStartTimer(false);
            }
            else {
                setTimerEnd(false);
                setStartTimer(true);
            }
            
        }
        
        if(props.onCheck)
            props.onCheck(checked, logOneVal, logTwoVal, props.movement, props.index);        
        
    }, [checked]);
    
    const logSet = (evt) => {
        
        let $target = $(evt.target).parent(),
            value = evt.target.value;
                            
        if(evt.target.type === 'number')
            value = Number(value);
        
        if(props.logSet)              
            props.logSet(props.index, $target.data('log'), value);
        
    }
    
    const logTimeSet = (totalSecs) => {
        
        if(props.logSet)              
            props.logSet(props.index, 'time', totalSecs);
        
    }
    
    const timerEnded = () => {
        
        setTimerEnd(true);
        
        if(props.onTimerEnded)
            props.onTimerEnded();
        
    }
    
    const openDetails = async () => {
                
        $('#movement-popup').data('movid', props.movement.id);
        window.f7App.popup.get('#movement-popup').open();
                
    }
    
    const renderRest = (restTime) => {
                
        if(noTimer)
            return null;
        
        return (
            <div className="rest-timer">
                <div>
                    <div>Rest</div>
                    <TimeInput
                        disabled
                        value={restTime}
                        start={startTimer}
                        reset={startTimer}
                        timerEnded={timerEnded}
                    />
                </div>
                
            </div>
            
        )
        
    }
    
    const renderMovement = () => {
                
        const movement = props.movement;
                                
        let logOnePlaceholder = logOne;
        let logTwoPlaceholder = logTwo ? logTwo : '';
        let logOneInfo = logOnePlaceholder;
        let logTwoInfo = logTwoPlaceholder;
        let nameColWidth = props.mode === 'execute' ? '55' : '70';
        let restTime = get(props, 'rest_time', 0);
                
        if(logOne === 'time') {
            logOnePlaceholder = '00:00';
            logOneInfo = 'Time';
        }
        else if(logOne === 'distance') {
            logOnePlaceholder = get(global, 'config._userMeta.units.distance', 'meters') === 'meters' ? 'm' : 'in';
            logOneInfo = get(global, 'config._userMeta.units.distance', 'meters') === 'meters' ? 'mtr' : 'in';
        }
            
        if(logTwo) {
            
            if(logTwo === 'weight') {
                logTwoPlaceholder = get(global, 'config._userMeta.units.weight', 'metric') === 'metric' ? 'kg' : 'lb';
                logTwoInfo = logTwoPlaceholder;
            }       
            
        } 
        
        if(props.mode === 'execute') {
            
            logOnePlaceholder = get(props, logOne, logOnePlaceholder);
            if(logTwoPlaceholder)
               logTwoPlaceholder = get(props, logTwo, logTwoPlaceholder);; 
            
        }
        
        let listClass = '';
        if(checked) {
            listClass += ' checked';
        }
        
        if(timerEnd) {
            listClass += ' timer-end';
        }
        else if(startTimer) {
            $('.timer-start').removeClass('timer-start');
            listClass += ' timer-start';
        }
        
        return (
            <ListItem className={listClass}>
                <Row className="movement">
                    <Col width={nameColWidth} className="mov-name">
                        {movement.name}
                        <Link 
                          text="View Details"
                          onClick={openDetails}
                        />
                    </Col>
                    <Col width="30" className="log-param">
                        <div>
                            {logOne === 'time' &&
                                <TimeInput 
                                    value={parseInt(logOneVal)}
                                    onChange={setLogOneVal}
                                    onBlur={logTimeSet}
                                    disabled={props.mode === 'view'}
                                />
                            }
                            {logOne !== 'time' &&
                                <React.Fragment>
                                    <Input
                                        type={logOne === 'time' ? 'text': 'number'}
                                        min={1}
                                        step={1}
                                        placeholder={logOnePlaceholder}
                                        data-log={logOne}
                                        value={logOneVal}
                                        onChange={(evt) => setLogOneVal(evt.target.value)}
                                        onBlur={logSet}
                                        disabled={props.mode == 'view'}
                                    />
                                    <span>{logOneInfo}</span>
                                </React.Fragment>
                            }
                        </div>
                        {logTwo &&
                            <div>     
                                <Input
                                    type="number"
                                    min={1}
                                    step={0.5}
                                    placeholder={logTwoPlaceholder}
                                    data-log={logTwo}
                                    value={logTwoVal}
                                    onChange={(evt) => setLogTwoVal(evt.target.value)}
                                    onBlur={logSet}
                                    disabled={props.mode === 'view'}
                                />
                                <span>{logTwoInfo}</span>
                            </div>
                        }
                    </Col>
                    {props.mode === 'execute' &&
                        <Col width="15" className="mov-check">
                            <Input
                                type="checkbox"
                                value={1}
                                wrap={true}
                                onChange={() => setChecked(!checked)}
                                checked={checked}
                            />
                        </Col>
                    }
                </Row>
                {renderRest(restTime)}  
            </ListItem>
        )
        
    }
    
    return (
        renderMovement()
    )
    
}

MovementView.propTypes = {
    mode: PropTypes.string,
    movement: PropTypes.object,
    onTimerEnded: PropTypes.func,
    logSet: PropTypes.func,
    checked: PropTypes.bool
}

MovementView.defaultProps = {
    mode: 'view', //view, execute
    checked: false
}

export default MovementView;