import React, { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener = () => {
    
    useEffect(() => {

        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {

            const route = event.url.split('.fit').pop();
            if (route.includes('/link/workout/')) {
                window.f7App.views.main.router.navigate(route);
            }

        });
        
    }, []);
    
    return null;
}

export default AppUrlListener;
