import firebaseApp from './init';
import {
    getFirestore,
    collection,
    doc,
    addDoc,
    getDocs,
    query,
    where,
    getDoc,
    setDoc,
    deleteDoc,
    serverTimestamp,
    orderBy,
    arrayUnion,
    onSnapshot,
    writeBatch,
    documentId
} from 'firebase/firestore';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
import { pick, each, map, isEmpty, get } from 'lodash';

const db = getFirestore(firebaseApp);
const sessionsCol = collection(db, 'sessions');
const workoutsCol = collection(db, 'workouts');

const allowedKeys = [
    'name',
    'created_at',
    'execution_date',
    'completed',
    'note',
    'user_id',
    'workouts'
];

export const save = (payload) => {
        
    try {
        
        let data = payload.data;
        
        //only allowed keys
        data = pick(data, allowedKeys);
        
        if(data.note) {
            data.note = sanitizeHtml(data.note, {
                allowedTags: ['div', 'p', 'br', 'b', 'i', 'h1', 'h2', 'h3', 'li', 'ol', 'a', 'img', 'u']
            });
        }
         
        //update   
        if(payload.session_id) {
            return setDoc(doc(db, sessionsCol.path, payload.session_id), data, {merge: true});
        }
        //create
        else {
                        
            data.user_id = global._currentUser.uid;
            data.created_at = serverTimestamp();
            
            return addDoc(sessionsCol, data);        

        }
    }
    catch(err) {
        console.error(err.message);
    }
     
};

export const getMulti = (opts={}) => {
        
    let constraints = [];
    
    constraints.push(where('user_id', '==', get(global, '_currentUser.uid', null)));
    
    if(opts.minDate) {
        constraints.push(where('execution_date', '>=',  opts.minDate));
    }
    
    if(opts.maxDate) {
        constraints.push(where('execution_date', '<',  opts.maxDate));
    }
    
    const q = query(sessionsCol, ...constraints, orderBy('execution_date'));
    return getDocs(q);
    
};

export const getSingle = (id) => {
    
    const docRef = doc(db, sessionsCol.path, id);
    return getDoc(docRef);
    
};

export const deleteSingle = async (id) => {
        
    //get workout in session to delete non-library workouts
    const sessionRef = await getSingle(id);
    const workoutIds = map(sessionRef.get('workouts'), 'id');
    
    //delete non-library workouts in session
    if(!isEmpty(workoutIds)) {
        
        const querySnapshot = await getDocs(query(
                workoutsCol, 
                where('user_id', '==',  global._currentUser.uid), 
                where(documentId(), 'in',  workoutIds),
        ));
        
        //loop all workouts and find non-library workouts
        const batch = writeBatch(db);    
        querySnapshot.forEach((docRef) => {
            
            if(!docRef.get('is_library')) {
                batch.delete(doc(db, workoutsCol.path, docRef.id));
            }
            
        });
        
        await batch.commit();
           
    }
    
    return deleteDoc( doc(db, sessionsCol.path, id) );
    
};

export const subscribeSession = (sessionId, callback) => {
    
    return onSnapshot(doc(db, sessionsCol.path, sessionId), callback);
    
}