import React from 'react';
import { Views, View } from 'framework7-react';

const AthleteView = () => {
		return (
		<Views>
			<View name="main" url="/timeline" main={true} className="safe-areas" />
		</Views>
	)
}

export default AthleteView;