import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
   Row,
   Col,
   Input,
   Button,
   ListItem
} from 'framework7-react';
import { get } from 'lodash';
import ListRestTime from 'ui/comps/global/ListRestTime';
import $ from 'jquery'; 

function MovementInput (props)  {
        
    const [logOne, setLogOne] = useState(null);
    const [logTwo, setLogTwo] = useState(null);
    const [logOneVal, setLogOneVal] = useState('');
    const [logTwoVal, setLogTwoVal] = useState('');
        
    useEffect(() => {
            
        const movement = props.movement;
        const logParams = movement.log_parameter.split('_');
        
        setLogOne(logParams[0]);
        setLogTwo(logParams.length === 2 ? logParams[1] : null);     
        
    }, [props.movement]);
    
    useEffect(() => {
        
        setLogOneVal(get(props, logOne, ''));
        setLogTwoVal(get(props, logTwo, ''));
        
    }, [logOne, logTwo]);
    
    const restTimeSet = (restTime) => {
        
        if(props.restTimeSet)
            props.restTimeSet(props.index, restTime);
        
    }
    
    const logSet = (evt) => {
        
        let $target = $(evt.target).parent(),
            value = evt.target.value;
                            
        if(evt.target.type === 'number')
            value = Number(value);
        
        if(props.logSet)              
            props.logSet(props.index, $target.data('log'), value);
        
    }
    
    const renderMovement = () => {
                
        const movement = props.movement;
                                
        let logOnePlaceholder = logOne;
        let logTwoPlaceholder = logTwo ? logTwo : '';
        let logOneInfo = logOnePlaceholder;
        let logTwoInfo = logTwoPlaceholder;
        let restTime = get(props, 'rest_time', 0);
                
        if(logOne === 'time') {
            logOnePlaceholder = '00:00';
            logOneInfo = 'Time';
        }
        else if(logOne === 'distance') {
            logOnePlaceholder = global.config._userMeta.units.distance === 'meters' ? 'm' : 'in';
            logOneInfo = global.config._userMeta.units.distance === 'meters' ? 'mtr' : 'in';
        }
            
        if(logTwo) {
            
            if(logTwo === 'weight') {
                logTwoPlaceholder = global.config._userMeta.units.weight === 'metric' ? 'kg' : 'lb';
                logTwoInfo = logTwoPlaceholder;
            }       
            
        }
                
        return (
            <ListItem>
                <Row className="movement">
                    <Col width="60" medium="80" className="mov-name">
                        {movement.name}
                    </Col>
                    <Col width="40" medium="20" className="log-param">
                        <div>
                            <Input
                                type={logOne === 'time' ? 'text': 'number'}
                                min={1}
                                step={1}
                                placeholder={logOnePlaceholder}
                                data-log={logOne}
                                value={logOneVal}
                                onChange={(evt) => setLogOneVal(evt.target.value)}
                                onBlur={logSet}
                                disabled={props.mode == 'view'}
                            />
                            <span>{logOneInfo}</span>
                        </div>
                        {logTwo &&
                            <div>
                                <Input
                                    type="number"
                                    min={1}
                                    step={0.5}
                                    placeholder={logTwoPlaceholder}
                                    data-log={logTwo}
                                    value={logTwoVal}
                                    onChange={(evt) => setLogTwoVal(evt.target.value)}
                                    onBlur={logSet}
                                    disabled={props.mode === 'view'}
                                />
                                <span>{logTwoInfo}</span>
                            </div>
                        }
                    </Col>
                    
                </Row>
                <Row className="rest">
                    <Col width="60" medium="80">
                        Rest
                    </Col>
                    <Col width="40" medium="20">
                        <ListRestTime
                            disabled={props.mode === 'view'}
                            value={restTime}
                            onBlur={restTimeSet}
                        />
                    </Col>
                </Row>
                <Row className="actions">
                    <Col>
                        <Button 
                            text="Duplicate"
                            fill
                            small  
                            color="gray"
                            className="display-inline-block"
                            onClick={(evt) => props.duplicate(props.index)}
                        />
                        <Button 
                            text="Delete"
                            fill
                            small  
                            color="red"
                            className="display-inline-block"
                            onClick={(evt) => props.remove(props.index)}
                        />
                    </Col>
                </Row>    
            </ListItem>
        )
        
    }
    
    return (
        renderMovement()
    )
    
}

MovementInput.propTypes = {
    movement: PropTypes.object,
    restTimeSet: PropTypes.func,
    logSet: PropTypes.func,
    remove: PropTypes.func,
    duplicate: PropTypes.func,
}

export default MovementInput