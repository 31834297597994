module.exports = global.config = {
    app: {
        usersnap_api_key: "e406d3ff-ba9d-4ca1-b206-8a74b1caf418",
        movement_name_max_length: 100,
        workout_name_max_length: 100,
        workout_mov_desc_max_length: 5000,
        session_name_max_length: 100,
        max_movements_per_user: 100,
        max_workouts_per_user: 100,
        max_sessions_per_day: 5,
        max_movement_videos: 5,
        date_format: 'YYYY-M-D',
        display_date_format: 'ddd, MMM D',
        display_date_year_format: 'MMM D, YYYY'
    }
}