import {firebaseApp, auth} from './init';
import {
    getFirestore,
    collection,
    doc,
    addDoc,
    getDocs,
    query,
    where,
    getDoc,
    setDoc,
    deleteDoc,
    serverTimestamp,
    orderBy,
    documentId
} from 'firebase/firestore';
import sanitizeHtml from 'sanitize-html';
import { isEmpty, get } from 'lodash';

const db = getFirestore(firebaseApp);
const workoutsCol = collection(db, 'workouts');

export const writeWorkout = (payload) => {
        
    try {
        
        let data = payload.data;
        
        if(data.description) {
            data.description = sanitizeHtml(data.description, {
                allowedTags: ['div', 'p', 'br', 'b', 'i', 'h1', 'h2', 'h3', 'li', 'ol', 'a', 'img', 'u']
            });
        }
        
        //sets
        if(data.configs) {
            data.configs = {...data.configs};
        }
        
        if(data.setsData) {
            data.setsData = {...data.setsData};
        }
         
        //update   
        if(payload.workout_id) {
            return setDoc(doc(db, workoutsCol.path, payload.workout_id), data, {merge: true});
        }
        //create
        else {
            data.user_id = auth.currentUser.uid;
            data.created_at = serverTimestamp();
            return addDoc(workoutsCol, data);
        }
    }
    catch(err) {
        console.error(err.message);
    }
     
};

export const readWorkouts = (opts={}) => {
        
    const uid = get(auth, 'currentUser.uid', false);
    
    if(!uid)
        return null;
            
    if(opts.includeIds) {
        
        return getDocs(query(
                workoutsCol, 
                where('user_id', '==',  uid), 
                where(documentId(), 'in',  opts.includeIds)
        ));
    
    }
        
    let constraints = [];
    constraints.push(where('user_id', '==',  uid));
    
    if(opts.is_library) {
        constraints.push(where('is_library', '==',  true));
    }
    
    let q = query(workoutsCol, ...constraints, orderBy('name'));

    return getDocs(q);
    
};

export const readWorkout = (id) => {
    
    const docRef = doc(db, workoutsCol.path, id);
    return getDoc(docRef);
    
};

export const deleteSingleWorkout = (id) => {
    
    return deleteDoc( doc(db, workoutsCol.path, id) );
    
};